<template>
    <MarketingBar v-bind="baseProps" class="marketing-bar-text-and-links">
        <template #after-text>
            <div class="links-wrapper">
                <slot :color="color" />
            </div>
        </template>
    </MarketingBar>
</template>

<script>
import MarketingBar from '@marketing-bar-components/molecules/MarketingBar/MarketingBar';

export default {
    name: 'MarketingBarTextAndLinks',

    components: {
        MarketingBar,
    },

    extends: MarketingBar,
};
</script>

<style lang="scss" scoped>
.marketing-bar-text-and-links {
    @apply flex flex-col justify-center items-center text-s leading-ui-18 font-bold;

    .links-wrapper {
        @apply flex gap-2 items-center h-5;
    }

    @screen lg {
        @apply flex-row;

        .links-wrapper {
            @apply ml-2;
        }
    }
}
</style>
