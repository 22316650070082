<template>
    <component :is="componentTag" :path="targetUrl">
        <BasePicture
            :width="imageLarge.width"
            :height="imageLarge.height"
            :src="imageLarge.url"
            :alt="imageLarge.alt"
            :sources="imageLarge.sources"
            :is-lazy="true"
        />
    </component>
</template>

<script>
import { IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h } from '@types/Image';

import { isHttpLink } from '@assets/link';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketingBannerHOneImageLarge',

    components: {
        BaseLink,
        BaseLinkStatic,
        BasePicture,
    },

    props: {
        targetUrl: {
            type: String,
            required: true,
        },

        imageLargeUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        imageLarge() {
            return this.$imaginator.getImage(
                this.imageLargeUrl,
                'marketing-banner-h-one-left',
                this.targetUrl,
                IMAGE_TYPE_MARKETING_BANNER_HONE_800w_800h
            );
        },

        componentTag() {
            return isHttpLink(this.targetUrl) ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>
