<template>
    <SliderSlide
        class="vertical-products-simple-slider-slide"
        :scroll-snap-on-desktop="false"
        :scroll-snap-on-mobile="false"
    >
        <VerticalSliderProduct :product="product" />
    </SliderSlide>
</template>

<script>
import SliderSlide from '@molecules/SliderSlide/SliderSlide';
import VerticalSliderProduct from '@organisms/VerticalSliderProduct/VerticalSliderProduct';

export default {
    name: 'VerticalProductsSimpleSliderSlide',

    components: {
        SliderSlide,
        VerticalSliderProduct,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
    },
};
</script>
