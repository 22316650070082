<template>
    <component
        :is="componentTag"
        :path="url"
        :target="isExternalLink ? '_blank' : null"
        class="brand-shop-item"
    >
        <BasePicture
            :width="80"
            :height="40"
            :src="imageUrl"
            :is-lazy="true"
            :alt="brandName"
            class="picture"
        />
        <div>
            <BaseHeading class="text heading">{{ heading }}</BaseHeading>
            <BaseParagraph class="text paragraph">
                {{ $t('See more') }}
            </BaseParagraph>
        </div>
    </component>
</template>

<script>
import { isHttpLink } from '@assets/link';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

export default {
    name: 'BrandShopItem',

    components: {
        BaseHeading,
        BaseLinkStatic,
        BasePicture,
        BaseLink,
        BaseParagraph,
    },

    props: {
        brandName: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        isExternalLink() {
            return isHttpLink(this.url);
        },

        componentTag() {
            return this.isExternalLink ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>

<style lang="scss" scoped>
.brand-shop-item {
    @apply flex bg-gray8 p-3 gap-3 relative h-auto;

    &.text-link {
        &:hover {
            @apply no-underline;
        }
    }

    .picture {
        @apply flex justify-center p-2 items-center bg-light;

        width: calc(theme('width.80p') + 2 * theme('spacing.2'));
        height: calc(theme('height.6') + 2 * theme('spacing.2'));

        &:deep(.image) {
            @apply object-contain;
        }
    }

    .text {
        @apply block text-s leading-s text-gray1;
    }

    .heading {
        @apply font-semibold;
    }

    .paragraph {
        @apply underline;
    }

    @screen lg {
        @apply gap-4 p-12p w-full ml-auto;

        .text {
            @apply text-r leading-r;
        }
    }
}
</style>
