<template>
    <div class="grid-columns" :class="classes">
        <div class="grid-row">
            <slot />
        </div>
    </div>
</template>

<script>
import { COLUMNS_COUNT } from '@types/GridColumns';

export default {
    name: 'GridColumns',

    props: {
        columnsTablet: {
            type: Number,
            default: null,
            validator: value => COLUMNS_COUNT.includes(value),
        },

        columnsDesktop: {
            type: Number,
            default: null,
            validator: value => COLUMNS_COUNT.includes(value),
        },

        columnsDesktopLarge: {
            type: Number,
            default: null,
            validator: value => COLUMNS_COUNT.includes(value),
        },
    },

    computed: {
        classes() {
            const breakpointsColumns = {
                md: this.columnsTablet,
                lg: this.columnsDesktop,
                xl: this.columnsDesktopLarge,
            };

            return Object.entries(breakpointsColumns)
                .filter(([, columnsCount]) => !!columnsCount)
                .map(
                    ([breakpoint, columnsCount]) =>
                        `columns-${breakpoint}-${columnsCount}`
                );
        },
    },
};
</script>

<style lang="scss" scoped>
$columns: 1, 2, 3, 4, 6;

@mixin generateColumns($breakpoint) {
    @each $column in $columns {
        &.columns-#{$breakpoint}-#{$column} {
            &:deep() {
                .grid-column {
                    width: calc(100% / #{$column});
                }
            }
        }
    }
}

/*! purgecss start ignore */
.grid-columns {
    @apply overflow-hidden;

    .grid-row {
        @apply flex flex-wrap;

        margin: 0 calc(-1 * theme('customSizes.12p'))
            calc(-1 * theme('spacing.5'));
    }

    &:deep() {
        .grid-column {
            @apply w-full px-12p mb-5;

            flex: 0 0 auto;
        }
    }

    @screen md {
        @include generateColumns('md');
    }

    @screen lg {
        @include generateColumns('lg');
    }

    @screen xl {
        @include generateColumns('xl');
    }
}
/*! purgecss end ignore */
</style>
