<template>
    <div v-if="hasRequiredValues" class="countdown-timer">
        <div v-if="isCounterVisible" v-show="showCountdown" class="countdown">
            <span class="text">{{ countdownText }}</span>
            <div
                ref="timer"
                class="timer-wrapper"
                :data-timestamp="endDateTimerTimestamp"
            >
                <template
                    v-for="{ label, attribute, separator } in countDownElements"
                >
                    <div :key="`value-${label}`" class="column-wrapper">
                        <div class="time-part">
                            <span class="time-count" v-bind="attribute" />
                            <span class="time-description">
                                {{ label }}
                            </span>
                        </div>
                    </div>
                    <span v-if="separator" :key="`separator-${label}`">
                        {{ separator }}
                    </span>
                </template>
            </div>
        </div>

        <div v-if="showBeforeCountdownText" class="before-countdown-text">
            {{ beforeCountdownText }}
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { HOT_DEAL_HOURS_UNTIL_COUNTDOWN_DISPLAY } from '@configs/marketing-actions';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import DateHelper from '@models/DateHelper/DateHelper';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'CountdownTimer',

    props: {
        countdownEndDate: {
            type: String,
            default: '',
        },

        countdownText: {
            type: String,
            default: '',
        },

        beforeCountdownText: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            timerInitialized: false,
        };
    },

    computed: {
        ...mapConfigGetters(['timezone']),

        endDate() {
            const dateHelper = new DateHelper(
                this.timezone,
                FORMAT_YYYYMMDD_HHMM
            );

            return dateHelper.createDateFromStringInTimezone(
                this.countdownEndDate
            );
        },

        startDate() {
            return DateHelper.minusHours(
                this.endDate,
                HOT_DEAL_HOURS_UNTIL_COUNTDOWN_DISPLAY
            );
        },

        endDateTimerTimestamp() {
            return DateHelper.toSeconds(this.endDate);
        },

        isCounterVisible() {
            const dateHelper = new DateHelper(this.timezone);

            const currentDate = dateHelper.createCurrentDateInTimezone();

            return DateHelper.isCurrentDateBetweenPassed(
                this.startDate,
                this.endDate,
                currentDate
            );
        },

        showCountdown() {
            return this.isCounterVisible && this.timerInitialized;
        },

        countDownElements() {
            return [
                {
                    label: this.$t('hour.'),
                    attribute: { 'data-hours': '' },
                    separator: ':',
                },
                {
                    label: this.$t('min.'),
                    attribute: { 'data-minutes': '' },
                    separator: ':',
                },
                {
                    label: this.$t('sec.'),
                    attribute: { 'data-seconds': '' },
                    separator: '',
                },
            ];
        },

        showBeforeCountdownText() {
            return !this.isCounterVisible && this.beforeCountdownText;
        },

        hasRequiredValues() {
            return this.isCounterVisible || this.beforeCountdownText;
        },
    },

    mounted() {
        this.startCountdown();
    },

    methods: {
        startCountdown() {
            const { timer } = this.$refs;

            if (!this.isCounterVisible) {
                return false;
            }

            if (timer instanceof HTMLElement) {
                import(
                    /* webpackChunkName: "countdown-clock" */ '@assets/countdown-clock'
                ).then(({ default: countdownClock }) => {
                    countdownClock.init(timer, true)?.start();

                    this.timerInitialized = true;
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.countdown-timer {
    .time-part {
        @apply relative pb-20p;
    }

    .time-count {
        @apply block w-4 h-4 text-center rounded-4 bg-light bg-opacity-30;
    }

    .time-description {
        @apply flex justify-center text-xs leading-s absolute left-1/2 bottom-0;

        transform: translateX(-50%);
    }

    .column-wrapper {
        @apply flex;
    }

    .column {
        @apply text-center;
    }

    .timer-wrapper {
        @apply flex text-r leading-r mt-1 gap-2;
    }

    .timer-spacer {
        @apply mx-2;
    }

    .text,
    .before-countdown-text {
        @apply text-s leading-s;
    }

    @screen md {
        .countdown {
            @apply flex;
        }

        .text {
            @apply mr-3 whitespace-nowrap self-center;
        }
    }

    @screen lg {
        .time-count {
            @apply block w-5 h-5;
        }

        .time-description {
            @apply text-s;
        }

        .timer-wrapper {
            @apply text-m leading-m mt-0;
        }

        .text,
        .before-countdown-text {
            @apply text-r leading-r;
        }
    }
}
</style>
