<template>
    <BaseTag v-bind="propsToBind">
        <slot v-if="slotDefault">{{ JSON.parse(slotDefault) }}</slot>
    </BaseTag>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import BaseTag from '@atoms/BaseTag/BaseTag';

export default {
    name: 'CmsBaseTag',

    components: {
        BaseTag,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(BaseTag),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: 'example base tag',
                },
            },
        };
    },
};
</script>
