<template>
    <div />
</template>

<script>
export default {
    name: 'CmsAppEobMainBannerPromoCarousel',

    /* eslint-disable vue/no-unused-properties */
    props: {
        priority: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        listingName: {
            type: String,
            required: true,
        },

        women: {
            type: Boolean,
            default: false,
        },

        men: {
            type: Boolean,
            default: false,
        },

        child: {
            type: Boolean,
            default: false,
        },

        firstText: {
            type: String,
            default: '',
        },

        secondText: {
            type: String,
            default: '',
        },

        barText: {
            type: String,
            default: '',
        },

        counterStartDate: {
            type: String,
            default: '',
        },

        counterEndDate: {
            type: String,
            default: '',
        },

        barColor: {
            type: String,
            default: '',
        },

        regulationsText: {
            type: String,
            default: '',
        },

        regulationsPdf: {
            type: String,
            default: '',
        },

        regulationsLink: {
            type: String,
            default: '',
        },

        deepLink: {
            type: String,
            default: '',
        },

        deepLinkSku: {
            type: String,
            default: '',
        },

        defaultValue: {
            type: String,
            default: '',
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        promoCode: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    priority: '',
                    tag: '',
                    imageUrl: '',
                    firstText: '',
                    secondText: '',
                    barText: '',
                    counterStartDate: '',
                    counterEndDate: '',
                    regulationsText: '',
                    regulationsPdf: '',
                    regulationsLink: '',
                    listingName: '',
                    deepLink: '',
                    deepLinkSku: '',
                    defaultValue: '',
                    customCampaignId: '',
                    promoCode: '',
                    itemsCount: '',
                },
            },

            propsCustomFormConfig: {
                firstText: {
                    max: 48,
                },

                secondText: {
                    max: 48,
                },

                largeText: {
                    max: 48,
                },

                barText: {
                    max: 100,
                },

                regulationsLink: {
                    max: 1000,
                },

                listingName: {
                    max: 24,
                },

                promoCode: {
                    max: 48,
                },
            },
        };
    },
};
</script>
