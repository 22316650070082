<template>
    <PageBuilderInterpreter
        :tag="SideMenu"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="componentProps"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { getProps } from '@modules/page-builder/helpers/component';
import SideMenu from '@molecules/SideMenu/SideMenu';
import { CMS_SIDE_MENU_ITEM } from '@modules/page-builder/page-builder.config';

export default {
    name: 'CmsSideMenu',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(SideMenu),
    },

    data() {
        return {
            SideMenu,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    isMobile: false,
                    heading: '',
                    defaultDropdownLabel: 'Select category',
                    dropdownOptions: [],
                    currentLink: {},
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                defaultDropdownLabel: false,
                currentLink: false,
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                isMobile: {
                    disabled: true,
                },

                dropdownOptions: {
                    disabled: true,
                },
            },
        };
    },

    computed: {
        componentProps() {
            return {
                ...this.propsToBind,
                isMobile: this.isMobileProp,
                dropdownOptions: this.dropdownOptionsProp,
            };
        },

        dropdownOptionsProp() {
            return (JSON.parse(this.slotDefault) || [])
                .filter(({ name }) => name === CMS_SIDE_MENU_ITEM)
                .map(({ props }) => props);
        },
    },
};
</script>
