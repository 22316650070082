<template>
    <HelpCenterContainer>
        <template #mobileMessage>
            <PageBuilderInterpreter :components-json="slotMobileMessage" />
        </template>
        <template #message>
            <PageBuilderInterpreter :components-json="slotMessage" />
        </template>
        <template #sidebar>
            <PageBuilderInterpreter :components-json="slotSidebar" />
        </template>
        <template #default>
            <PageBuilderInterpreter :components-json="slotDefault" />
        </template>
    </HelpCenterContainer>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import HelpCenterContainer from '@molecules/HelpCenterContainer/HelpCenterContainer';

export default {
    name: 'CmsHelpCenterContainer',

    components: {
        HelpCenterContainer,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotMobileMessage: {
            type: String,
            default: JSON.stringify(''),
        },

        slotMessage: {
            type: String,
            default: JSON.stringify(''),
        },

        slotSidebar: {
            type: String,
            default: JSON.stringify(''),
        },

        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    mobileMessage: '',
                    message: '',
                    sidebar: '',
                    default: '',
                },
            },
        };
    },
};
</script>
