import { IS_CUT_OFF_THE_CENTS_ENABLED } from '@localeConfig/keys';

export const cutOffTheCents = (price, getLocaleConfigByKey) => {
    const isCutOffTheCentsEnabled = getLocaleConfigByKey(
        IS_CUT_OFF_THE_CENTS_ENABLED
    );

    if (!isCutOffTheCentsEnabled) {
        return price;
    }

    const regex = /,\d{2}/g;

    return price.replace(regex, '');
};
