<template>
    <li class="list-item" :class="type">
        <slot />
    </li>
</template>

<script>
import { TYPES } from '@types/ListItem';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'ListItem',

    props: {
        type: {
            type: String,
            default: TYPES.TYPE_DOT,
            validator: checkIfExistsInValuesMap(TYPES),
        },
    },
};
</script>

<style lang="scss" scoped>
.list-item {
    @apply list-none flex text-gray1 leading-s;

    &::before {
        @apply inline-block w-4 pr-3;
        content: '';
    }

    &.num {
        counter-increment: list;

        &::before {
            @apply text-green2;
            content: counter(list) '.';
        }
    }

    &.dot {
        &::before {
            @apply text-m text-green2;
            content: '•';
        }
    }
}
</style>
