<template>
    <HelpCenterSubpageHeading v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import { ICON_TYPES, ICON_TYPE_ACCOUNT } from '@configs/cms-help-center';

import HelpCenterSubpageHeading from '@molecules/HelpCenterSubpageHeading/HelpCenterSubpageHeading';

export default {
    name: 'CmsHelpCenterSubpageHeading',

    components: {
        HelpCenterSubpageHeading,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(HelpCenterSubpageHeading),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    marginTop: 'mt-7',
                    heading: '',
                    icon: ICON_TYPE_ACCOUNT,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                icon: Object.values(ICON_TYPES),
            },
        };
    },
};
</script>
