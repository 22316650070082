<template>
    <MarketingBrandsSliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingBrandsSliderSlide from '@organisms/MarketingBrandsSlider/MarketingBrandsSliderSlide';

export default {
    name: 'CmsMarketingBrandsSliderSlide',

    components: {
        MarketingBrandsSliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingBrandsSliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    brandLabel: '',
                    brandUrl: '',
                    brandImageUrl: '',
                    brandCode: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
