<template>
    <div
        :class="{ 'is-double-height': isDoubleHeight }"
        class="marketing-promo-categories-slider-item"
    >
        <BaseLink :path="link" class="item-link">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :class="{ 'has-image-cover': hasImageCover }"
                :is-lazy="true"
                class="item-img"
            />

            <span
                :class="{ 'has-light-text': hasLightText }"
                class="item-title"
            >
                {{ title }}
            </span>
        </BaseLink>
    </div>
</template>

<script>
import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';

import {
    IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h,
    IMAGE_TYPE_PROMO_CATEGORY_164w_164h,
    IMAGE_TYPE_MARKETING_CARD_256w_256h,
} from '@types/Image';

export default {
    name: 'MarketingPromoCategoriesSliderItem',

    components: {
        BaseLink,
        BasePicture,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        imageSrc: {
            type: String,
            required: true,
        },

        isDoubleHeight: {
            type: Boolean,
            default: false,
        },

        hasLightText: {
            type: Boolean,
            default: false,
        },

        hasImageCover: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageSrc,
                'promo-categories',
                this.title,
                this.imageFormat
            );
        },

        imageFormat() {
            if (this.hasImageCover) {
                return this.isDoubleHeight
                    ? IMAGE_TYPE_PROMO_CATEGORY_COVER_256w_528h
                    : IMAGE_TYPE_MARKETING_CARD_256w_256h;
            }

            return IMAGE_TYPE_PROMO_CATEGORY_164w_164h;
        },
    },
};
</script>

<style lang="scss" scoped>
$item-width: 160px;
$item-height: 156px;
$item-size-lg: 256px;

$item-double-height: 320px;
$item-double-height-lg: 528px;

$img-size-double: 156px;
$img-size-double-lg: 164px;
$img-size: 80px;
$img-size-lg: 128px;

$title-bottom-offset: 56px;

.marketing-promo-categories-slider-item {
    @apply rounded-4 relative flex bg-light overflow-hidden;
    width: $item-width;
    height: $item-height;
    scroll-snap-align: start;
    transition-duration: 200ms;
    -webkit-tap-highlight-color: transparent;

    &:hover {
        transform: translateY(
            theme('customVariables.marketingSlider.translateValue')
        );

        .item-img {
            @apply opacity-80;
        }
    }

    &.is-double-height {
        grid-row: 1 / 3;
        height: $item-double-height;

        .item-img {
            width: $img-size-double;
            height: $img-size-double;

            &.has-image-cover {
                @apply mt-0 w-full h-full rounded-4;
            }
        }

        .item-link {
            @apply justify-center;
        }
    }

    .item-link {
        @apply flex flex-col justify-start items-center w-full cursor-pointer;

        &:hover {
            @apply no-underline;
        }
    }

    .item-img {
        @apply mx-auto mt-1 overflow-hidden;
        width: $img-size;
        height: $img-size;

        &.has-image-cover {
            @apply mt-0 w-full h-full rounded-4;
        }
    }

    .item-title {
        @apply text-center absolute bottom-32 text-s text-gray2 leading-s;

        &.has-light-text {
            @apply text-light;
        }
    }
}

@screen mobile-and-tablet-only {
    .marketing-promo-categories-slider-item {
        .item-img {
            &:deep(.image) {
                @apply w-full h-auto;
            }
        }
    }
}

@screen lg {
    .marketing-promo-categories-slider-item {
        width: $item-size-lg;
        height: $item-size-lg;

        &.is-double-height {
            height: $item-double-height-lg;

            .item-img:not(.has-image-cover) {
                @apply mt-0;
                width: $img-size-double-lg;
                height: $img-size-double-lg;
            }
        }

        .item-img {
            @apply mt-5;
            width: $img-size-lg;
            height: $img-size-lg;
        }

        .item-title {
            @apply text-r leading-r;
            bottom: $title-bottom-offset;
        }
    }
}
</style>
