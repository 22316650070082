<template>
    <div
        class="marketing-value-proposition-slider-slide"
        @click="actionHandler()"
    >
        <component
            :is="componentTag"
            :path="slidePath"
            class="marketing-value-proposition-item"
        >
            <div
                :style="{ backgroundColor }"
                :class="pointerClass"
                class="slide"
            >
                <MarketingValuePropositionItemHeading
                    :text="headingText"
                    :text-color="headingColor"
                    :image-url="imageUrl"
                />

                <BaseParagraph
                    v-if="text"
                    :style="{ color: textColor }"
                    class="text"
                >
                    {{ text }}
                </BaseParagraph>
            </div>
        </component>
    </div>
</template>

<script>
import { MODAL_NEWSLETTER_NAME } from '@configs/modals';

import { light, text2 } from '@theme/resources/variables/colors.json';

import { isHttpLink } from '@assets/link';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

// eslint-disable-next-line max-len
import MarketingValuePropositionItemHeading from '@organisms/MarketingValueProposition/MarketingValuePropositionItemHeading';

export default {
    name: 'MarketingValuePropositionSliderSlide',

    components: {
        MarketingValuePropositionItemHeading,
        BaseParagraph,
        BaseLink,
        BaseLinkStatic,
    },

    props: {
        backgroundColor: {
            type: String,
            default: light,
        },

        headingColor: {
            type: String,
            default: text2,
        },

        headingText: {
            type: String,
            default: '',
        },

        imageUrl: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: text2,
        },

        text: {
            type: String,
            default: '',
        },

        url: {
            type: String,
            default: '',
        },

        isNewsletterSlide: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        slidePath() {
            const { url } = this;

            return !this.isNewsletterSlide && url ? url : '';
        },

        pointerClass() {
            return this.url || this.isNewsletterSlide ? 'pointer' : null;
        },

        componentTag() {
            const { slidePath } = this;

            if (!slidePath) {
                return 'div';
            }

            return isHttpLink(slidePath) ? BaseLinkStatic : BaseLink;
        },
    },

    methods: {
        openNewsletterModal() {
            return this.$modals.open(MODAL_NEWSLETTER_NAME);
        },

        actionHandler() {
            if (this.isNewsletterSlide) {
                this.openNewsletterModal();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-value-proposition-slider-slide {
    &:not(:last-of-type) {
        @apply mr-2;
    }

    .text {
        @apply mx-3 mb-3 text-r leading-r;
    }

    .marketing-value-proposition-item {
        &::v-deep {
            &.text-link {
                &:hover,
                &:active {
                    @apply no-underline;
                }
            }
        }
    }

    .slide {
        @apply bg-light rounded-4 flex flex-col justify-between flex-shrink-0 mt-1;

        transition: 0.2s ease;
        width: theme('customVariables.valueProposition.default.width');
        height: theme('customVariables.valueProposition.default.height');

        &:hover {
            @apply opacity-80 cursor-default;

            transform: translateY(
                theme('customVariables.marketingSlider.translateValue')
            );
        }

        &.pointer {
            @apply cursor-pointer;
        }
    }

    @screen lg {
        &:not(:last-of-type) {
            @apply mr-3;
        }

        .text {
            @apply mx-5 mb-5 text-l leading-l;
        }

        .slide {
            width: theme('customVariables.valueProposition.lg.width');
            height: theme('customVariables.valueProposition.lg.height');
        }
    }
}
</style>
