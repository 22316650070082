<template>
    <div class="side-menu">
        <BaseHeading v-if="heading" theme="h4" class="heading">
            {{ heading }}
        </BaseHeading>

        <div v-if="shouldShowDropdown" class="dropdown-wrapper">
            <div
                class="desktop-placeholder"
                :style="{ height: desktopPlaceholderHeight }"
            />
            <Dropdown
                :value="selectedLabel.name"
                :options="menuOptions"
                class="items-dropdown"
                :class="{ default: !currentLinkLabel }"
            >
                <template #selected>
                    <div class="dropdown-selected">
                        <SvgIcon
                            v-if="selectedLabel.logo"
                            :width="selectedLabel.logo.width"
                            :height="selectedLabel.logo.height"
                            stroke-color="none"
                        >
                            <component :is="selectedLabel.logo.svg" />
                        </SvgIcon>
                        <span
                            v-if="
                                !selectedLabel.logo ||
                                selectedLabel.showNameAndLogo
                            "
                        >
                            {{ selectedLabel.name }}
                        </span>
                        <Badge v-if="selectedLabel.showBadgeNew">
                            {{ $t('New') }}
                        </Badge>
                    </div>
                </template>
                <template
                    #option="{
                        option: {
                            path,
                            name,
                            logo,
                            showNameAndLogo,
                            showBadgeNew,
                        },
                    }"
                >
                    <ButtonComponent
                        class="text-link button-option"
                        :variant="PRIMARY_VARIANT"
                        :to="path"
                        @click.native="$emit('click-link', { path })"
                    >
                        <template v-if="logo" #icon>
                            <SvgIcon
                                v-if="logo"
                                :width="logo.width"
                                :height="logo.height"
                                stroke-color="none"
                            >
                                <component :is="logo.svg" />
                            </SvgIcon>
                        </template>
                        <template v-if="!logo || showNameAndLogo">
                            {{ name }}
                        </template>
                        <Badge v-if="showBadgeNew" :variant="BADGE_NEW">
                            {{ $t('New') }}
                        </Badge>
                    </ButtonComponent>
                </template>
            </Dropdown>
        </div>
        <div v-else class="items-wrapper">
            <div class="items">
                <SideMenuItem
                    v-for="(
                        {
                            path,
                            name,
                            logo,
                            showNameAndLogo = false,
                            showBadgeNew = false,
                        },
                        index
                    ) in menuOptions"
                    :key="index"
                    :path="path"
                    :name="name"
                    :logo="logo"
                    :show-icon-with-label="showNameAndLogo"
                    :show-badge-new="showBadgeNew"
                    @click.native="$emit('click-link', { path })"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import Dropdown from '@molecules/Dropdown/Dropdown';
import SideMenuItem from '@molecules/SideMenu/SideMenuItem';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { Badge, BADGE_VARIANTS } from '@eobuwie-ui/components/Badge/v1';

const DESKTOP_SIDE_MENU_ITEM_HEIGHT = 48;

export default {
    name: 'SideMenu',

    components: {
        BaseHeading,
        Dropdown,
        SideMenuItem,
        SvgIcon,
        ButtonComponent,
        Badge,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        heading: {
            type: String,
            default: '',
        },

        dropdownOptions: {
            type: Array,
            required: true,
        },

        defaultDropdownLabel: {
            type: String,
            default: 'Select category',
        },

        currentLink: {
            type: Object,
            default: () => ({}),
        },

        shouldRenderDropdown: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        menuOptions() {
            return this.dropdownOptions.map(({ name, ...rest }) => ({
                name,
                value: name,
                ...rest,
            }));
        },

        currentLinkLabel() {
            return (
                this.currentLink ||
                this.dropdownOptions.find(
                    ({ path }) => path === this.$route.path
                ) ||
                ''
            );
        },

        selectedLabel() {
            if (!this.currentLinkLabel?.name) {
                return { name: this.$t(this.defaultDropdownLabel) };
            }

            return this.currentLinkLabel;
        },

        desktopPlaceholderHeight() {
            return `${
                this.dropdownOptions.length * DESKTOP_SIDE_MENU_ITEM_HEIGHT
            }px`;
        },

        shouldShowDropdown() {
            if (!this.shouldRenderDropdown) {
                return false;
            }

            return this.isMobile;
        },
    },

    beforeCreate() {
        this.PRIMARY_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY;
        this.BADGE_NEW = BADGE_VARIANTS.NEW;
    },
};
</script>

<style lang="scss" scoped>
.side-menu {
    .heading {
        @apply block text-center;
    }

    .desktop-placeholder {
        @apply hidden;
    }

    .dropdown-wrapper {
        @apply mt-3;
    }

    .items-dropdown {
        .text-link {
            &:hover,
            &:focus {
                @apply no-underline;
            }

            &.button-option {
                @apply justify-start;
            }
        }

        &.default {
            &:deep() .select.filled {
                .selected-text {
                    @apply text-gray3 font-book;
                }
            }
        }

        &:deep() {
            .select.filled {
                .selected-text {
                    @apply font-bold;
                }
            }
        }
    }

    .items-wrapper {
        @apply hidden;
    }

    .dropdown-selected {
        @apply flex items-center gap-2;
    }

    .nuxt-link-exact-active {
        @apply font-bold bg-gray6;
    }

    @screen lg {
        .heading {
            @apply text-m leading-m px-3 py-10p text-left;
        }

        .desktop-placeholder {
            @apply block bg-gray7;
        }

        .items-dropdown {
            @apply hidden;
        }

        .items-wrapper,
        .items {
            @apply block;
        }
    }
}
</style>
