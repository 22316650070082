export const COLOR_GREEN = 'green';
export const COLOR_BLUE = 'blue';
export const COLOR_GRAY1 = 'gray1';

export const COLORS = {
    COLOR_GREEN,
    COLOR_BLUE,
    COLOR_GRAY1,
};

export const BACKGROUND_WHITE = 'white';
export const BACKGROUND_GRAY = 'gray';

export const BACKGROUNDS = {
    BACKGROUND_WHITE,
    BACKGROUND_GRAY,
};
