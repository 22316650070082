<template>
    <section class="marketing-promo-categories">
        <div class="tabs-list" role="tablist">
            <Tab
                v-for="({ title, isSelected, tabIndex }, index) in tabs"
                :key="`tab-${index}`"
                ref="tab"
                role="tab"
                :is-active="isSelected"
                :tabindex="tabIndex"
                :color="tabColor"
                @keydown.left.native="focusPrevious()"
                @keydown.right.native="focusNext()"
                @click.native="selectTab(index)"
                @focus.native="setFocusIndex(index)"
            >
                <slot name="tabs">{{ title }}</slot>
            </Tab>
        </div>
        <div v-for="index in countSliders" :key="index" class="container">
            <slot
                v-if="selectedTabIndex === index - 1"
                :name="`container${index}`"
            />
        </div>
    </section>
</template>

<script>
import { COLOR_GRAY1 } from '@types/Tabs';

import Tab from '@atoms/Tabs/Tab';

export default {
    name: 'MarketingPromoCategories',

    components: {
        Tab,
    },

    props: {
        tabTitle1: {
            type: String,
            default: null,
        },

        tabTitle2: {
            type: String,
            default: null,
        },

        tabTitle3: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            selectedTabIndex: 0,
            focusIndex: 0,
        };
    },

    computed: {
        tabs() {
            const { tabTitle1, tabTitle2, tabTitle3, selectedTabIndex } = this;
            const tabsArray = [tabTitle1, tabTitle2, tabTitle3];

            return tabsArray
                .filter(tab => {
                    return !!tab;
                })
                .map((tab, index) => {
                    const isSelected = selectedTabIndex === index;
                    const tabIndex = isSelected ? 0 : -1;

                    return {
                        title: tab,
                        isSelected,
                        tabIndex,
                    };
                });
        },

        countSliders() {
            return Object.keys(this.$slots).length;
        },
    },

    beforeCreate() {
        this.tabColor = COLOR_GRAY1;
    },

    methods: {
        selectTab(index) {
            this.selectedTabIndex = index;
            this.focusIndex = index;
        },

        focusPrevious() {
            const { focusIndex } = this;

            const newIndex =
                focusIndex === 0 ? this.containers.length - 1 : focusIndex - 1;

            this.focusIndex = newIndex;
            this.$refs.tab[newIndex].$el.focus();
        },

        focusNext() {
            const newIndex = (this.focusIndex + 1) % this.containers.length;

            this.focusIndex = newIndex;
            this.$refs.tab[newIndex].focus();
        },

        setFocusIndex(index) {
            this.focusIndex = index;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-promo-categories {
    .tabs-list {
        @apply flex w-full relative z-0 mb-3;
    }

    .content {
        @apply hidden outline-none;

        &.show {
            @apply block;
        }
    }

    .container {
        @apply w-full max-w-full;
    }
}
</style>
