<template>
    <div class="vertical-slider-product">
        <div class="col-one">
            <BaseLink :path="product.url" :title="product.name">
                <BasePicture
                    class="image"
                    :src="image.url"
                    :sources="image.sources"
                    :width="image.width"
                    :height="image.height"
                    :alt="image.alt"
                    :sizes="IMAGE_WIDTH"
                />
            </BaseLink>

            <AddToWishlistWrapper
                #default="{ isLoading, isAdded, toggle }"
                :product="product"
            >
                <ButtonIconToggle
                    :variant="FAVOURITE_VARIANT"
                    :size="FAVOURITE_SIZE"
                    :disabled="isLoading"
                    :toggled="isAdded"
                    :data-test-id="ADD_TO_WISHLIST"
                    class="add-to-wishlist"
                    @toggle="toggle()"
                />
            </AddToWishlistWrapper>
        </div>

        <div class="col-two">
            <ProductBadges :badges="product.badges" class="badges" />

            <BaseLink
                :path="product.url"
                :title="product.name"
                class="details-container"
            >
                <span class="text brand">{{ product.brandName }}</span>
                <span class="text">{{ product.name }}</span>
            </BaseLink>

            <ProductPrice
                :price-regular="product.price.regular.formatted"
                :price-regular-per-unit="product.price.regular.formatted"
                :price-promotional="product.price.promotional.formatted"
                :is-promotional-lower-than-omnibus="
                    isPromotionalLowerThanOmnibus
                "
                :price-omnibus="omnibusPrice"
                :discount="product.price.discount"
                class="item-price"
            />
        </div>
    </div>
</template>

<script>
import {
    IMAGE_TYPE_PRODUCT_128w_128h,
    IMAGE_TYPE_PRODUCT_256w_256h,
    PRODUCT_IMAGE_TYPES,
} from '@types/Image';
import { ADD_TO_WISHLIST } from '@types/AutomaticTestIDs';

import { getProductImage } from '@assets/images';

import ProductBadges from '@molecules/ProductBadges/ProductBadges';

import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLink from '@atoms/BaseLink/BaseLink';

import ProductPrice from '@molecules/ProductPrice/ProductPrice';
import AddToWishlistWrapper from '@molecules/AddToWishlistWrapper/AddToWishlistWrapper';

import {
    ButtonIconToggle,
    BUTTON_ICON_TOGGLE_VARIANTS,
    BUTTON_ICON_TOGGLE_SIZES,
} from '@eobuwie-ui/components/ButtonIconToggle/v1';

export default {
    name: 'VerticalSliderProduct',

    components: {
        ProductBadges,
        ProductPrice,
        BaseLink,
        BasePicture,
        AddToWishlistWrapper,
        ButtonIconToggle,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    computed: {
        image() {
            return getProductImage(this.product, 0, [
                IMAGE_TYPE_PRODUCT_128w_128h,
                IMAGE_TYPE_PRODUCT_256w_256h,
            ]);
        },

        omnibusPrice() {
            return this.product.price.omnibus?.formatted;
        },

        isPromotionalLowerThanOmnibus() {
            const { omnibus, promotional } = this.product.price;

            return !!omnibus?.amount && promotional.amount < omnibus?.amount;
        },
    },

    beforeCreate() {
        this.IMAGE_WIDTH = `${PRODUCT_IMAGE_TYPES[IMAGE_TYPE_PRODUCT_128w_128h].width}px`;
        this.ADD_TO_WISHLIST = ADD_TO_WISHLIST;
        this.FAVOURITE_VARIANT = BUTTON_ICON_TOGGLE_VARIANTS.FAVOURITE;
        this.FAVOURITE_SIZE = BUTTON_ICON_TOGGLE_SIZES.M;
    },
};
</script>

<style lang="scss" scoped>
.vertical-slider-product {
    @apply flex mb-3;

    .col-one {
        @apply mr-3 w-10 h-10 shrink-0 relative;
    }

    .col-two {
        @apply self-center min-w-0;
    }

    .details-container {
        @apply block mt-2;
    }

    .add-to-wishlist {
        @apply absolute top-0 right-0 z-1;
    }

    .text {
        @apply text-text3 truncate block text-s leading-s;
    }

    .brand {
        @apply text-dark;
    }

    .item-price {
        @apply justify-start mt-1;
    }

    .badges {
        @apply justify-start;
    }
}
</style>
