<template>
    <div :id="id" ref="container" class="faq-accordion">
        <TextButtonWithIcon
            class="title"
            @click.native="isExpanded = !isExpanded"
        >
            {{ title }}
            <template #right>
                <SvgIcon width="20px" height="20px" class="icon">
                    <component :is="isExpanded ? 'Up' : 'Down'" />
                </SvgIcon>
            </template>
        </TextButtonWithIcon>
        <div v-if="isExpanded" class="content">
            <slot />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { STICKY_HEADER_HEIGHT } from '@configs/header';

import Down from '@static/icons/20px/down.svg?inline';
import Up from '@static/icons/20px/up.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import TextButtonWithIcon from '@atoms/TextButtonWithIcon/TextButtonWithIcon';

export default {
    name: 'FaqAccordion',
    components: {
        SvgIcon,
        Down,
        Up,
        TextButtonWithIcon,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        id: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isExpanded: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),
    },

    mounted() {
        const { hash } = window.location;
        const { id } = this;

        if (id && hash && hash.slice(1) === id) {
            const { offsetTop } = this.$refs.container;

            let scrollToY = offsetTop;

            if (this.isMobile) {
                scrollToY -= STICKY_HEADER_HEIGHT;
            }

            window.scrollTo(0, scrollToY);

            this.isExpanded = true;
        }
    },
};
</script>

<style lang="scss" scoped>
.faq-accordion {
    .title,
    .content {
        @apply mb-12p;
    }

    .heading {
        @apply flex;
    }

    .title {
        @apply w-full justify-between text-r leading-r text-text font-bold;

        &:focus,
        &:hover {
            @apply no-underline;
        }
    }

    .icon {
        @apply text-text3;
    }

    &::after {
        @apply block h-1p w-full border-b-1 border-border;
        content: '';
    }
}
</style>
