import {
    CONSENTS_MODAL_GOOGLE_VIEW,
    CONSENTS_MODAL_FACEBOOK_VIEW,
    CONSENTS_MODAL_GOOGLE_CLOSE,
    CONSENTS_MODAL_FACEBOOK_CLOSE,
    CONSENTS_MODAL_GOOGLE_START_SHOPPING,
    CONSENTS_MODAL_FACEBOOK_START_SHOPPING,
} from '@analytics-modules/client/types/Events';

export const CLICK_DEBOUNCE_TIME = 1000;
export const VIEW_TIME_TO_SEND = 1000;

export const CONSENTS_MODAL_VIEW_EVENTS_MAP = {
    google: CONSENTS_MODAL_GOOGLE_VIEW,
    facebook: CONSENTS_MODAL_FACEBOOK_VIEW,
};

export const CONSENTS_MODAL_CLOSE_EVENTS_MAP = {
    google: CONSENTS_MODAL_GOOGLE_CLOSE,
    facebook: CONSENTS_MODAL_FACEBOOK_CLOSE,
};

export const CONSENTS_MODAL_START_SHOPPING_EVENTS_MAP = {
    google: CONSENTS_MODAL_GOOGLE_START_SHOPPING,
    facebook: CONSENTS_MODAL_FACEBOOK_START_SHOPPING,
};
