<template>
    <MarketingPromoCategoriesSliderItem v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
// eslint-disable-next-line max-len
import MarketingPromoCategoriesSliderItem from '@organisms/MarketingPromoCategoriesSlider/MarketingPromoCategoriesSliderItem';

export default {
    name: 'CmsMarketingPromoCategoriesSliderItem',

    components: {
        MarketingPromoCategoriesSliderItem,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingPromoCategoriesSliderItem),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    title: '',
                    link: '',
                    imageSrc: '',
                    isDoubleHeight: false,
                    hasLightText: false,
                    hasImageCover: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
