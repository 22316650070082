<template>
    <MarketingBarDefault v-bind="propsToBind" />
</template>

<script>
import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import MarketingBarDefault from '@marketing-bar-components/organisms/MarketingBarDefault/MarketingBarDefault';

export default {
    name: 'CmsMarketingBarDefault',

    components: {
        MarketingBarDefault,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketingBarDefault),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    textDesktop: '',
                    textMobile: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                backgroundColor: false,
                icon: false,
                iconColor: false,
                color: false,
                textLink: false,
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                textMobile: {
                    max: 40,
                },

                textDesktop: {
                    max: 80,
                },
            },
        };
    },
};
</script>
