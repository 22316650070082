import { render, staticRenderFns } from "./ProductBadges.vue?vue&type=template&id=3f0d782d&scoped=true"
import script from "./ProductBadges.vue?vue&type=script&lang=js"
export * from "./ProductBadges.vue?vue&type=script&lang=js"
import style0 from "./ProductBadges.vue?vue&type=style&index=0&id=3f0d782d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f0d782d",
  null
  
)

export default component.exports