<template>
    <div />
</template>

<script>
export default {
    name: 'CmsAppEobSmallBanner',

    /* eslint-disable vue/no-unused-properties */
    props: {
        firstText: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '',
        },

        buttonColor: {
            type: String,
            default: '',
        },

        deepLink: {
            type: String,
            default: '',
        },

        imageUrl: {
            type: String,
            default: '',
        },

        listingName: {
            type: String,
            default: '',
        },

        counterStartDate: {
            type: String,
            default: '',
        },

        counterEndDate: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    firstText: '',
                    textColor: '',
                    backgroundColor: '',
                    deepLink: '',
                    imageUrl: '',
                    listingName: '',
                    counterStartDate: '',
                    counterEndDate: '',
                    buttonColor: '',
                },
            },
        };
    },
};
</script>
