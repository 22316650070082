<template>
    <BaseButton
        :to="url"
        class="marketing-popular-category"
        :theme="THEME_TERTIARY"
    >
        {{ label }}
    </BaseButton>
</template>

<script>
import BaseButton from '@atoms/BaseButton/BaseButton';
import { THEME_TERTIARY } from '@types/Button';

export default {
    name: 'MarketingPopularCategory',

    components: {
        BaseButton,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },
    },

    created() {
        this.THEME_TERTIARY = THEME_TERTIARY;
    },
};
</script>

<style lang="scss" scoped>
.marketing-popular-category {
    @apply justify-center whitespace-nowrap;
}
</style>
