<template>
    <div class="marketing-brands-section-slider-slide">
        <MarketingBrandsSectionImage
            v-for="(
                { url, imageUrl, hasBadge, brandName, className }, index
            ) in slideRows"
            :key="`${brandName}${index}`"
            :url="url"
            :image-url="imageUrl"
            :has-badge="hasBadge"
            :brand-name="brandName"
            :class="className"
        />
    </div>
</template>

<script>
// eslint-disable-next-line max-len
import MarketingBrandsSectionImage from '@organisms/MarketingBrandsSectionSliderSlide/MarketingBrandsSectionImage';

export default {
    name: 'MarketingBrandsSectionSliderSlide',

    components: {
        MarketingBrandsSectionImage,
    },

    props: {
        firstBrandUrl: {
            type: String,
            required: true,
        },

        firstBrandImage: {
            type: String,
            required: true,
        },

        firstBrandName: {
            type: String,
            required: true,
        },

        firstBrandBadge: {
            type: Boolean,
            default: false,
        },

        secondBrandUrl: {
            type: String,
            required: true,
        },

        secondBrandImage: {
            type: String,
            required: true,
        },

        secondBrandName: {
            type: String,
            required: true,
        },

        secondBrandBadge: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        slideRows() {
            return [
                {
                    url: this.firstBrandUrl,
                    imageUrl: this.firstBrandImage,
                    hasBadge: this.firstBrandBadge,
                    brandName: this.firstBrandName,
                    className: 'first-image',
                },
                {
                    url: this.secondBrandUrl,
                    imageUrl: this.secondBrandImage,
                    hasBadge: this.secondBrandBadge,
                    brandName: this.secondBrandName,
                    className: 'second-image',
                },
            ];
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-brands-section-slider-slide {
    @apply mt-1;

    &:not(:last-of-type) {
        @apply mr-2;
    }

    .first-image,
    .second-image {
        @apply relative;
    }

    .first-image {
        @apply mb-2;
    }

    @screen lg {
        &:not(:last-of-type) {
            @apply mr-3;
        }

        .first-image {
            @apply mb-3;
        }
    }
}
</style>
