<template>
    <SliderSlide
        v-if="hasAllRequiredValues"
        class="marketing-cards-slider-slide"
    >
        <component :is="componentTag" :path="url" class="link">
            <BasePicture
                class="slide-image"
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :is-lazy="true"
                sizes="(max-width: 1023px) 256px, 33.33vw"
            />
            <BaseHeading class="sub-heading">
                {{ heading }}
            </BaseHeading>
            <BaseHeading class="heading">
                {{ subHeading }}
            </BaseHeading>
        </component>
    </SliderSlide>
</template>

<script>
import { isHttpLink } from '@assets/link';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import SliderSlide from '@molecules/SliderSlide/SliderSlide';

import {
    DEFAULT_IMAGE_FORMAT,
    MARKETING_CARD_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_MARKETING_CARD_256w_256h,
    IMAGE_TYPE_MARKETING_CARD_512w_512h,
} from '@types/Image';

export default {
    name: 'MarketingCardsSliderSlide',

    components: {
        BaseHeading,
        BasePicture,
        BaseLink,
        BaseLinkStatic,
        SliderSlide,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        url: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            const image256x256 = this.$imaginator.getImage(
                this.imageUrl,
                'marketing-card',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES[IMAGE_TYPE_MARKETING_CARD_256w_256h].name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_CARD_256w_256h]
            );

            const result = this.$imaginator.getImage(
                this.imageUrl,
                'marketing-card',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES[IMAGE_TYPE_MARKETING_CARD_512w_512h].name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_CARD_512w_512h]
            );

            [image256x256].forEach(image => {
                Object.entries(image.sources).forEach(([type, source]) => {
                    result.sources[type] = result.sources[type].concat(source);
                });
            });

            return result;
        },

        hasAllRequiredValues() {
            return this.subHeading && this.heading && this.imageUrl && this.url;
        },

        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },
    },
};
</script>

<style lang="scss" scoped>
$slide-min-width-mobile: 256px;
$slide-min-width-desktop: calc((100% / 3) - (32px * 2) / 3);

.marketing-cards-slider-slide {
    @apply mr-3;
    min-width: $slide-min-width-mobile;

    &:first-child {
        @apply ml-3;
    }

    &:last-child {
        @apply mr-0 pr-3;
        min-width: calc(#{$slide-min-width-mobile} + #{$tailwindcss-spacing-3});
    }

    .link {
        &:hover {
            @apply no-underline;
        }
    }

    .sub-heading,
    .heading {
        @apply block text-s leading-s;
    }

    .sub-heading {
        @apply text-gray2 font-book mt-2;
    }

    .slide-image {
        @apply overflow-hidden rounded-4;
    }

    @screen md {
        .sub-heading,
        .heading {
            @apply text-r leading-r;
        }
    }

    @screen lg {
        @apply mr-5;
        min-width: $slide-min-width-desktop;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0 pr-0;
            min-width: $slide-min-width-desktop;
        }

        .slide-image {
            &:deep(.image) {
                transition: transform 0.2s;
            }
        }

        &:hover {
            .slide-image {
                &:deep(.image) {
                    transform: scale(1.2) translateY(-4%);
                }
            }
        }
    }
}
</style>
