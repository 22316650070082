<template>
    <div class="marketing-cards-slider-headings">
        <BaseHeading class="heading">
            {{ heading }}
        </BaseHeading>
        <BaseHeading class="sub-heading">
            {{ subHeading }}
        </BaseHeading>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'MarketingCardsSliderHeadings',

    components: {
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-cards-slider-headings {
    @apply flex flex-col px-3;

    .heading {
        @apply text-s leading-s font-book text-gray2;
    }

    .sub-heading {
        @apply text-l leading-l mt-1;
    }

    @screen md {
        .heading {
            @apply text-r leading-r;
        }

        .sub-heading {
            @apply text-xl leading-xl;
        }
    }

    @screen lg {
        @apply px-0;
    }
}
</style>
