import { render, staticRenderFns } from "./MarketingPromoCategoriesSlider.vue?vue&type=template&id=200b5239&scoped=true"
import script from "./MarketingPromoCategoriesSlider.vue?vue&type=script&lang=js"
export * from "./MarketingPromoCategoriesSlider.vue?vue&type=script&lang=js"
import style0 from "./MarketingPromoCategoriesSlider.vue?vue&type=style&index=0&id=200b5239&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200b5239",
  null
  
)

export default component.exports