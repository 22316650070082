<template>
    <BaseLink class="help-center-card-link" :path="path" :label="label" />
</template>

<script>
import BaseLink from '@atoms/BaseLink/BaseLink';

export default {
    name: 'HelpCenterCardLink',

    components: {
        BaseLink,
    },

    props: {
        path: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.help-center-card-link {
    @apply mt-3 text-center;

    @screen mobile-and-tablet-only {
        @apply text-s leading-s;
    }
}
</style>
