<template>
    <div class="marketing-celebrity-slider-slide-photo-footer">
        <div class="picture-container">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :is-lazy="true"
            />
        </div>
        <div class="celebrity-info">
            <BaseHeading>{{ heading }}</BaseHeading>
            <BaseParagraph>{{ subHeading }}</BaseParagraph>
        </div>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BasePicture from '@atoms/BasePicture/BasePicture';
import { IMAGE_TYPE_MARKETING_AVATAR_32w_32h } from '@types/Image';

export default {
    name: 'MarketingCelebritySliderSlidePhotoFooter',

    components: {
        BasePicture,
        BaseParagraph,
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        avatarUrl: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.avatarUrl,
                'marketing-avatar',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPE_MARKETING_AVATAR_32w_32h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-celebrity-slider-slide-photo-footer {
    @apply flex items-center;

    .picture-container {
        @apply w-5;
    }

    .celebrity-info {
        @apply ml-12p;
    }

    &:deep(.base-picture) {
        .image {
            @apply rounded-50;
        }
    }
}
</style>
