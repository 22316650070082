<template>
    <ButtonWithIcon
        :theme="theme"
        :show-left-slot="false"
        :class="{ 'prevent-click': isPending }"
        class="copy-to-clipboard-button"
        @click.native="copyDiscountCodeToClipboard()"
    >
        {{ buttonLabel }}
        <template v-if="!!$scopedSlots.icon" #[iconPosition]>
            <transition :name="transitionName" mode="out-in">
                <div :key="isCopied ? 'check' : 'copy'">
                    <slot name="icon" :is-copied="isCopied" />
                </div>
            </transition>
        </template>
    </ButtonWithIcon>
</template>

<script>
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { THEMES, ICON_POSITIONS } from '@types/Button';

import { copyToClipboard } from '@assets/clipboard';

import ButtonWithIcon from '@molecules/ButtonWithIcon/ButtonWithIcon';

const CHANGE_LABEL_TO_COPIED_AFTER = 100;
const RESET_TO_DEFAULT_STATE_AFTER = 900;

export default {
    name: 'CopyToClipboardButton',

    components: {
        ButtonWithIcon,
    },

    props: {
        valueToCopy: {
            type: [String, Number],
            required: true,
        },

        beforeCopyLabel: {
            type: String,
            default: '',
        },

        afterCopyLabel: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_SECONDARY,
            validator: value => Object.values(THEMES).includes(value),
        },

        resetToDefaultStateAfter: {
            type: Number,
            default: RESET_TO_DEFAULT_STATE_AFTER,
        },

        iconPosition: {
            type: String,
            default: ICON_POSITIONS.ICON_POSITION_RIGHT,
            validator: value => Object.values(ICON_POSITIONS).includes(value),
        },
    },

    data() {
        return {
            isPending: false,
            isCopied: false,
        };
    },

    computed: {
        transitionName() {
            return this.isCopied ? 'fade' : '';
        },

        buttonLabel() {
            return this.isCopied
                ? this.afterCopyLabel || this.$t('Copied')
                : this.beforeCopyLabel || this.$t('Copy to clipboard');
        },
    },

    methods: {
        copyDiscountCodeToClipboard() {
            this.isPending = true;
            const { valueToCopy } = this;

            try {
                copyToClipboard(valueToCopy);

                setTimeout(() => {
                    this.isCopied = true;

                    this.$emit('value-copied');
                }, CHANGE_LABEL_TO_COPIED_AFTER);

                setTimeout(() => {
                    this.isCopied = false;
                    this.isPending = false;
                }, this.resetToDefaultStateAfter);
            } catch (err) {
                this.isCopied = false;
                this.isPending = false;

                this.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'copyToClipboard',
                    },
                    {
                        valueToCopy,
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.copy-to-clipboard-button {
    @apply flex px-0;

    &:focus {
        @apply outline-none;
    }

    &:deep() .right {
        @apply ml-1;
    }

    &:deep() .left {
        @apply mr-1;
    }

    &.prevent-click {
        @apply pointer-events-none;
    }

    .fade-enter-active {
        transition: opacity 0.3s ease;
    }

    .fade-enter {
        @apply opacity-0;
    }
}
</style>
