<template>
    <BannerWithCodeAndClock v-bind="propsToBind">
        <template #categories>
            <PageBuilderInterpreter :components-json="slotCategories" />
        </template>
        <PageBuilderInterpreter :components-json="slotDefault" />
    </BannerWithCodeAndClock>
</template>

<script>
import { THEMES } from '@types/BannerWithCodeAndClock';
import { MILLISECONDS_IN_SECOND, SECONDS_IN_DAY } from '@types/Datetime';

import { getProps } from '@modules/page-builder/helpers/component';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import BannerWithCodeAndClock from '@organisms/BannerWithCodeAndClock/BannerWithCodeAndClock';

export default {
    name: 'CmsBannerWithCodeAndClock',

    components: {
        BannerWithCodeAndClock,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        slotCategories: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(BannerWithCodeAndClock),
    },

    data() {
        const currentTimestamp = new Date().getTime() / MILLISECONDS_IN_SECOND;
        const tomorrowTimestamp = currentTimestamp + SECONDS_IN_DAY;

        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    color: '#034136',
                    heading: '',
                    code: 'WEEKEND',
                    theme: 'light',
                    timestampFrom: currentTimestamp.toString(),
                    timestampTo: tomorrowTimestamp.toString(),
                },

                slots: {
                    default: '',
                    categories: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
            },
        };
    },
};
</script>
