<template>
    <div class="product-badges">
        <BaseBadge
            v-for="{ code, label, color, backgroundColor } in badgesSorted"
            :key="`product-badge-${code}`"
            :color="color"
            :background-color="backgroundColor"
            class="badge"
        >
            <span v-html="label" />
        </BaseBadge>
    </div>
</template>

<script>
import BaseBadge from '@atoms/BaseBadge/BaseBadge';

import { TYPE_OCCASION, TYPE_DISCOUNT } from '@types/Badge';

const DISCOUNT_BADGE_TYPES = [TYPE_OCCASION, TYPE_DISCOUNT];

export default {
    name: 'ProductBadges',

    components: {
        BaseBadge,
    },

    props: {
        badges: {
            type: Object,
            required: true,
        },
    },

    computed: {
        badgesSorted() {
            return Object.values(this.badges).sort(
                (a, b) => this.isPromotionBadge(b) - this.isPromotionBadge(a)
            );
        },
    },

    methods: {
        isPromotionBadge(badge) {
            return DISCOUNT_BADGE_TYPES.includes(badge.code);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-badges {
    @apply flex justify-center;

    .badge {
        &:not(:first-child) {
            @apply ml-2;
        }
    }
}
</style>
