<template>
    <Observer
        v-if="showRecentlyViewed"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        :class="{ 'is-loaded': isLoaded, 'with-scrollbar': showSliderNav }"
        class="recently-viewed-slider-container"
        @intersect="displayContent($event)"
    >
        <ContainerContent
            v-if="isDisplayed"
            class="recently-viewed-slider-content"
        >
            <Loader v-if="!isLoaded" height="auto" />

            <RecentlyViewedProductsDataProvider
                :sku="sku"
                @loaded="loadedProducts($event)"
                @error="handleError()"
            >
                <component
                    :is="productsSliderComponent"
                    v-if="isLoaded"
                    :slider-title="$t('Recently viewed')"
                    :products="productsLoaded"
                    :has-scrollbar="showSliderNav"
                    :has-wish-list-button="true"
                    container="recently-viewed-products-slider"
                    :action-field="RECENTLY_VIEWED"
                    @navigation-button-click="
                        emitNavigationButtonClickEvent($event)
                    "
                />
            </RecentlyViewedProductsDataProvider>
        </ContainerContent>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { debounceAggregate } from '@assets/debounce-aggregate';

import {
    RECENTLY_VIEWED_PRODUCTS_SLIDER_TRESHOLD,
    RECENTLY_VIEWED_PRODUCTS_SLIDER_NAME,
} from '@configs/recently-viewed-products';
import { RECENTLY_VIEWED } from '@configs/simple-slider';
import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';

import { PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';

import LoadProducts from '@mixins/LoadProducts';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

const { mapState: mapRecentlyViewedProductsState } = createNamespacedHelpers(
    'recentlyViewedProducts'
);

export default {
    name: 'RecentlyViewedProductsSliderWrapper',
    components: {
        Observer,
        Loader,
        ContainerContent,

        RecentlyViewedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recently-viewed-products-data-provider" */
                '@molecules/RecentlyViewedProductsDataProvider/RecentlyViewedProductsDataProvider'
            ),
        }),
    },

    mixins: [LoadProducts],

    props: {
        sku: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isDisplayed: false,
            isLoaded: false,
            showRecentlyViewed: false,
            showSliderNav: true,
        };
    },

    computed: {
        ...mapRecentlyViewedProductsState(['products']),
    },

    beforeCreate() {
        this.RECENTLY_VIEWED = RECENTLY_VIEWED;
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products, RECENTLY_VIEWED);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    mounted() {
        if (
            this.$recentlyViewedProducts.get().length >=
                RECENTLY_VIEWED_PRODUCTS_SLIDER_TRESHOLD ||
            this.products.length >= RECENTLY_VIEWED_PRODUCTS_SLIDER_TRESHOLD
        ) {
            this.showRecentlyViewed = true;
        }
    },

    methods: {
        async loadedProducts(products) {
            if (products.length >= RECENTLY_VIEWED_PRODUCTS_SLIDER_TRESHOLD) {
                this.isLoaded = true;

                await this.loadProductsSliderComponent();
                this.productsLoaded = products;
            } else {
                this.showRecentlyViewed = false;
            }
        },

        handleError() {
            this.showRecentlyViewed = false;
            this.isDisplayed = false;
        },

        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        emitNavigationButtonClickEvent(direction) {
            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
                {
                    sliderName: RECENTLY_VIEWED_PRODUCTS_SLIDER_NAME,
                    direction:
                        direction === 1
                            ? GLOBAL_LABELS.NAV_RIGHT
                            : GLOBAL_LABELS.NAV_LEFT,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$title-height: $tailwindcss-spacing-7;
$scrollbar-height: $tailwindcss-spacing-4;
$product-height: 420px;

@mixin products-slider-height($title-height, $scrollbar-height) {
    min-height: calc(
        #{$title-height} + #{$product-height} + #{$scrollbar-height}
    );
}

.recently-viewed-slider-container {
    @apply flex bg-gray5 mt-7;

    @include products-slider-height($title-height, 0px);

    .recently-viewed-slider-content {
        @apply px-0;
    }

    &.with-scrollbar {
        @include products-slider-height($title-height, $scrollbar-height);
    }

    &.is-loaded {
        @apply bg-light;
    }

    @screen lg {
        @apply mt-9;

        .recently-viewed-slider-content {
            @apply px-5;
        }
    }
}
</style>
