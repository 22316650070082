<template>
    <div />
</template>

<script>
import { DEFAULT_ZOWIE_CHAT_SRC } from '@assets/zowie-chat';

let AVAILABLE_PAGES = [];

export default {
    name: 'CmsGlobalConfig',

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        isZowieChatEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieChatEnabledPages: {
            type: Array,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieScriptSrc: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieChatInstanceId: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        isInviteFriendEnabledFlag: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isInviteFriendPausedFlag: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isMagicLinkEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isAccountVerificationEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        facebookUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        instagramUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        youtubeUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        freeDeliveryText: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        synthroneScriptUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        synthroneBrands: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    isZowieChatEnabled: false,
                    zowieChatEnabledPages: [],
                    zowieScriptSrc: DEFAULT_ZOWIE_CHAT_SRC,
                    zowieChatInstanceId: '',
                    isInviteFriendEnabledFlag: false,
                    isInviteFriendPausedFlag: false,
                    isMagicLinkEnabled: false,
                    isAccountVerificationEnabled: false,
                    facebookUrl: '',
                    instagramUrl: '',
                    youtubeUrl: '',
                    freeDeliveryText: '',
                    synthroneScriptUrl: '',
                    synthroneBrands: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                zowieChatEnabledPages: AVAILABLE_PAGES,
            },
        };
    },

    beforeCreate() {
        AVAILABLE_PAGES = this.$router.options.routes
            .map(item =>
                [item, ...(item?.children || [])].filter(item2 => item2?.name)
            )
            .flat()
            .map(({ name }) => name);
    },
};
</script>
