<template>
    <div
        :class="[`price-${displayToApply}`, `aligned-${alignText}`]"
        class="product-price"
    >
        <div :class="{ 'is-sale': isSale }" class="price-final">
            {{ formatLocalePrice(promotionalPriceToDisplay) }}
        </div>
        <div
            v-if="showPricePerUnit && !showSingleItemPrice"
            class="price-per-unit"
            :class="{ 'is-sale': isSale }"
        >
            {{ pricePerUnitText }}
        </div>
        <div
            v-if="showOmnibusPriceInformation"
            class="information-prices-wrapper"
        >
            <div class="information-price">
                {{ formatLocalePrice(lowestPriceText) }}
            </div>
            <div v-if="isRegularPriceEnabled" class="information-price">
                {{ formatLocalePrice(regularPriceText) }}
            </div>
        </div>

        <div
            v-else-if="
                isSale && !isOmnibusStrictEnabled && isRegularPriceEnabled
            "
            class="price-regular"
        >
            {{ formatLocalePrice(regularPriceToDisplay) }}
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { GOOD_PRICE_ENABLED_LOCALES } from '@configs/product';

import {
    IS_OMNIBUS_STRICT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
} from '@localeConfig/keys';

import { DISPLAYS, ALIGNS } from '@types/ProductPrice';

import { checkIfExistsInValuesMap } from '@assets/props';
import { cutOffTheCents } from '@assets/cut-off-the-cents';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ProductPrice',

    props: {
        pricePromotional: {
            type: String,
            required: true,
        },

        pricePromotionalPerUnit: {
            type: String,
            default: '',
        },

        priceRegular: {
            type: String,
            default: '',
        },

        priceRegularPerUnit: {
            type: String,
            default: '',
        },

        showPricePerUnit: {
            type: Boolean,
            default: false,
        },

        isPromotionalLowerThanOmnibus: {
            type: Boolean,
            default: false,
        },

        priceOmnibus: {
            type: String,
            default: null,
        },

        discount: {
            type: String,
            default: null,
        },

        isWithMarketingAction: {
            type: Boolean,
            default: false,
        },

        forceShowOmnibus: {
            type: Boolean,
            default: false,
        },

        showSingleItemPrice: {
            type: Boolean,
            default: false,
        },

        display: {
            type: String,
            default: DISPLAYS.DISPLAY_INLINE,
            validator: checkIfExistsInValuesMap(DISPLAYS),
        },

        alignText: {
            type: String,
            default: ALIGNS.ALIGN_CENTER,
            validator: checkIfExistsInValuesMap(ALIGNS),
        },
    },

    computed: {
        ...mapConfigGetters(['locale']),

        isOmnibusStrictEnabled() {
            return this.$getLocaleConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        },

        isRegularPriceEnabled() {
            return this.$getLocaleConfigByKey(IS_REGULAR_PRICE_ENABLED);
        },

        isSale() {
            if (!this.isOmnibusStrictEnabled) {
                return this.discount && this.arePricesDifferent;
            }

            return (
                this.discount &&
                this.priceOmnibus &&
                this.isPromotionalLowerThanOmnibus
            );
        },

        isOmnibusPriceInformationEnabled() {
            if (GOOD_PRICE_ENABLED_LOCALES.includes(this.locale)) {
                return this.arePricesDifferent;
            }

            return false;
        },

        arePricesDifferent() {
            return this.pricePromotional !== this.priceRegular;
        },

        arePricesTheSame() {
            const { pricePromotional } = this;

            return (
                pricePromotional === this.priceRegular &&
                pricePromotional === this.priceOmnibus
            );
        },

        showOmnibusPriceInformation() {
            return (
                this.isOmnibusStrictEnabled &&
                this.priceOmnibus &&
                !this.arePricesTheSame &&
                (this.isOmnibusPriceInformationEnabled ||
                    this.isSale ||
                    this.isWithMarketingAction ||
                    this.forceShowOmnibus)
            );
        },

        displayToApply() {
            if (this.isOmnibusStrictEnabled) {
                return DISPLAYS.DISPLAY_BLOCK;
            }

            return this.display;
        },

        lowestPriceText() {
            return `${this.$t('Lowest price')}: ${this.priceOmnibus}`;
        },

        regularPriceText() {
            return `${this.$t('Regular price')}: ${this.priceRegularPerUnit}`;
        },

        pricePerUnitText() {
            return `${this.$t('Per unit: {price}', {
                price: this.pricePromotionalPerUnit,
            })}`;
        },

        promotionalPriceToDisplay() {
            return this.showSingleItemPrice
                ? this.pricePromotionalPerUnit
                : this.pricePromotional;
        },

        regularPriceToDisplay() {
            return this.showSingleItemPrice
                ? this.priceRegularPerUnit
                : this.priceRegular;
        },
    },

    methods: {
        formatLocalePrice(price) {
            return cutOffTheCents(price, this.$getLocaleConfigByKey);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-price {
    @apply text-r leading-r flex justify-center items-center flex-wrap;

    &.price-block {
        @apply flex-col;
    }

    &.price-inline {
        .price-final {
            &:not(:only-child) {
                @apply ml-2;
            }
        }
    }

    .price-regular {
        @apply text-text3 text-s line-through;
    }

    .price-final {
        @apply text-text2 font-bold;
    }

    .is-sale {
        @apply text-red1;
    }

    .price-per-unit {
        @apply text-xs leading-xs;
    }

    &.aligned-right {
        .price-final {
            @apply self-end;
        }

        .information-prices-wrapper {
            @apply text-right;
        }

        .price-per-unit {
            @apply self-end;
        }
    }

    &.aligned-m-left-d-right,
    &.aligned-left {
        .price-final {
            @apply self-start;
        }

        .information-prices-wrapper {
            @apply text-left;
        }

        .price-per-unit {
            @apply self-start;
        }
    }

    .information-prices-wrapper {
        @apply mt-1 whitespace-nowrap text-center w-full;
    }

    .information-price {
        @apply text-xs leading-xs text-text3;
    }

    @screen lg {
        &.aligned-m-left-d-right {
            .price-final {
                @apply self-end;
            }

            .information-prices-wrapper {
                @apply text-right;
            }

            .price-per-unit {
                @apply self-end;
            }
        }
    }
}
</style>
