<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppVisibleRulesV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        fromDate: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        toDate: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                fromDate: {
                    hasDateTimePicker: true,
                },

                toDate: {
                    hasDateTimePicker: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
