<template>
    <PageBuilderInterpreter
        :tag="MarketingBarTextAndLinks"
        :components-json="slotDefault"
        :tag-props="propsToBind"
    />
</template>

<script>
import { MARKETING_BAR_ALLOWED_ICONS } from '@configs/marketing-bar';

import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import MarketingBarTextAndLinks from '@marketing-bar-components/organisms/MarketingBarTextAndLinks/MarketingBarTextAndLinks';

export default {
    name: 'CmsMarketingBarTextAndLinks',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingBarTextAndLinks),
    },

    data() {
        return {
            MarketingBarTextAndLinks,

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    color: '#fff',
                    textDesktop: '',
                    textMobile: '',
                    textLink: '',
                    backgroundColor: '',
                    icon: '',
                    iconColor: '',
                },

                slots: {
                    default: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                icon: [...Object.values(MARKETING_BAR_ALLOWED_ICONS), ''],
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                textMobile: {
                    max: 40,
                },

                textDesktop: {
                    max: 80,
                },
            },
        };
    },
};
</script>
