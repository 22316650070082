const ZOWIE_CONTAINER_ID = 'chatbotize';

export const DEFAULT_ZOWIE_EMBEDDED_SRC =
    'https://embedded-chat.chatbotize.com/chatbotize-entrypoint.min.js';

export default function zowieFormInit(data) {
    const { scriptSrc, ...options } = data;

    const chatbotizeExists = !!document.getElementById(ZOWIE_CONTAINER_ID);
    const formWithIdExists = !!document.getElementById(options.divId);

    const createChatbotizeInitFn = formOptions => () =>
        // eslint-disable-next-line no-undef
        Chatbotize.init(formOptions);

    if (chatbotizeExists && formWithIdExists) {
        createChatbotizeInitFn(options)();

        return;
    }

    (function (d, s, id, cb) {
        const js = d.createElement(s);
        const n = d.createElement('div');

        n.id = id;

        if (js.readyState) {
            js.onreadystatechange = function () {
                if (
                    js.readyState === 'loaded' ||
                    js.readyState === 'complete'
                ) {
                    js.onreadystatechange = null;
                    cb();
                }
            };
        } else {
            js.onload = function () {
                cb();
            };
        }

        js.src = scriptSrc;
        document.body.appendChild(js);
        document.body.appendChild(n);
    })(document, 'script', ZOWIE_CONTAINER_ID, createChatbotizeInitFn(options));
}
