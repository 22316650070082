<template>
    <TextButton ref="tab" class="tab" :class="classes" :disabled="isDisabled">
        <slot />
    </TextButton>
</template>

<script>
import TextButton from '@atoms/TextButton/TextButton';
import { BACKGROUNDS, COLORS } from '@types/Tabs';
import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'Tab',

    components: {
        TextButton,
    },

    props: {
        isActive: {
            type: Boolean,
            default: false,
        },

        isDisabled: {
            type: Boolean,
            default: false,
        },

        background: {
            type: String,
            default: BACKGROUNDS.BACKGROUND_WHITE,
            validate: checkIfExistsInValuesMap(BACKGROUNDS, true),
        },

        color: {
            type: String,
            default: COLORS.COLOR_GREEN,
            validate: checkIfExistsInValuesMap(COLORS, true),
        },
    },

    computed: {
        classes() {
            return [
                this.color,
                `background-${this.background}`,
                { active: this.isActive, disabled: this.isDisabled },
            ];
        },
    },

    methods: {
        // eslint-disable-next-line vue/no-unused-properties
        focus() {
            this.$el.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.tab {
    @apply px-3 py-2 text-s text-text3 leading-s font-bold uppercase;
    @apply flex items-center justify-center relative;
    min-height: theme('customSizes.56p');

    &:after {
        @apply w-full border-t-2 absolute left-0 bottom-0 hidden border-current z-1;

        content: '';
    }

    &:hover,
    &:active,
    &:focus {
        @apply no-underline;
    }

    &.active {
        @apply cursor-default;

        &:after {
            @apply block;
        }
    }

    &.green {
        &:hover,
        &.active {
            @apply text-green2;
        }
    }

    &.blue {
        &:hover,
        &.active {
            @apply text-blue2;
        }
    }

    &.gray1 {
        &:hover,
        &.active {
            @apply text-gray1;
        }

        &:not(.active):after {
            @apply block border-t-1 border-gray6;
        }
    }

    &.disabled {
        @apply text-gray4;

        &.active {
            @apply text-gray4;
        }
    }
}
</style>
