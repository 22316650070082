<template>
    <div class="help-center-card">
        <BaseLink :path="allQuestionsUrl" class="link">
            <SvgIcon width="48px" height="48px" class="icon">
                <component :is="iconType" />
            </SvgIcon>
            <div class="heading-wrapper">
                <BaseHeading class="heading">{{ heading }}</BaseHeading>
            </div>
        </BaseLink>

        <div v-if="$slots.default" class="links-wrapper">
            <slot />
        </div>

        <BaseLink
            v-if="allQuestionsUrl"
            :path="allQuestionsUrl"
            class="all-questions"
        >
            {{ $t('All questions') }}
        </BaseLink>
    </div>
</template>

<script>
import { ICON_MAP, ICON_TYPES } from '@configs/cms-help-center';

import { checkIfExistsInValuesMap } from '@assets/props';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseLink from '@atoms/BaseLink/BaseLink';

export default {
    name: 'HelpCenterCard',

    components: {
        SvgIcon,
        BaseHeading,
        BaseLink,
    },

    props: {
        icon: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(ICON_TYPES),
        },

        heading: {
            type: String,
            required: true,
        },

        allQuestionsUrl: {
            type: String,
            default: '',
        },
    },

    computed: {
        iconType() {
            return ICON_MAP[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
.help-center-card {
    @apply flex flex-col items-center;

    .link {
        @apply text-center;
    }

    .icon {
        @apply m-auto text-primary;
    }

    .heading-wrapper {
        @apply flex items-center h-7 mt-2;
    }

    .heading {
        @apply line-clamp-2;
    }

    .links-wrapper {
        @apply flex flex-col mt-3;
    }

    .all-questions {
        @apply mt-3 font-bold text-s leading-s;
    }

    @screen lg {
        .heading-wrapper {
            @apply mt-12p;
        }

        .heading {
            @apply text-m leading-m;
        }

        .all-questions {
            @apply text-r leading-r;
        }
    }
}
</style>
