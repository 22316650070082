<template>
    <div class="grid-column">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'GridColumn',
};
</script>
