<template>
    <ProductCard v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import ProductCard from '@molecules/ProductCard/ProductCard';

export default {
    name: 'CmsProductCard',

    components: {
        ProductCard,
    },

    extends: CmsBaseComponent,

    props: getProps(ProductCard),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            fetch: {
                product: '0000206401144', // 0000201264072
            },
        };
    },
};
</script>
