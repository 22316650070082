<template>
    <MarketingBannerHOne v-bind="propsToBind" :class="cssClass">
        <PageBuilderInterpreter :components-json="slotDefault" />
    </MarketingBannerHOne>
</template>

<script>
import { THEMES } from '@types/MarketingBannerHOne';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingBannerHOne from '@organisms/MarketingBannerHOne/MarketingBannerHOne';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsMarketingBannerHOne',

    components: {
        MarketingBannerHOne,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingBannerHOne),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    targetUrl: '',
                    heading: '',
                    subHeading: '',
                    discountCode: '',
                    buttonText: '',
                    description: '',
                    imageUrlFirst: '',
                    imageUrlSecond: '',
                    backgroundColor: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
            },
        };
    },
};
</script>
