<template>
    <Observer
        v-if="displayContainer"
        :options="OBSERVER_OPTIONS"
        :observe-once="true"
        :class="[container, { 'is-loading': !isLoaded }]"
        class="marketing-products-slider"
        @intersect="displayContent($event)"
    >
        <RecommendedProductsDataProvider
            v-if="isDisplayed"
            :recommendation-type="recommendationType"
            :category-breadcrumbs="categoryBreadcrumbs"
            :product-sku="productSku"
            :custom-campaign-id="customCampaignId"
            :products-sku-array="productSkusArray"
            :size="size"
            @loaded="loadedProducts($event)"
            @error="handleError()"
        >
            <component
                :is="productsSliderComponent"
                v-if="isLoaded"
                :products="productsLoaded"
                :has-scrollbar="true"
                :has-wish-list-button="true"
                :promo-action-background="promoActionBackground"
                :promo-action-text-color="promoActionTextColor"
                :promo-action-text="promoActionText"
                :theme="theme"
                :action-field="recommendationType"
                @navigation-button-click="
                    emitNavigationButtonClickEvent($event)
                "
                @wishlist-button-click="onAddToWishlistClick($event)"
            />
        </RecommendedProductsDataProvider>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';

import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';
import { THEMES } from '@types/MarketingSection';

import SyneriseDataLayerProduct from '@models/Analytics/SyneriseDataLayerProduct';

import { PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';
import { RECOMMENDATIONS_ADD_TO_WISHLIST } from '@analytics-types/Events';

import { parseArrayString } from '@modules/page-builder/helpers/component';

import { debounceAggregate } from '@assets/debounce-aggregate';
import { checkIfExistsInValuesMap } from '@assets/props';

import LoadProducts from '@mixins/LoadProducts';

import Observer from '@atoms/Observer/Observer';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MarketingProductsSlider',

    components: {
        Observer,

        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),
    },

    mixins: [LoadProducts],

    props: {
        container: {
            type: String,
            required: true,
        },

        recommendationType: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(
                SYNERISE_RECOMMENDATION_TYPES,
                true
            ),
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: String,
            default: '',
        },

        productsSkuList: {
            type: String,
            default: '',
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        promoActionBackground: {
            type: String,
            default: '',
        },

        promoActionTextColor: {
            type: String,
            default: '',
        },

        promoActionText: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },
    },

    data() {
        return {
            isDisplayed: false,
            isLoaded: false,
            displayContainer: true,
        };
    },

    computed: {
        ...mapConfigGetters(['currency']),

        productSkusArray() {
            return parseArrayString(this.productsSkuList);
        },

        eventData() {
            return {
                currentScreenName: this.$route.name,
                campaignID: 'Sponsored',
                campaignHash: 'SponsoredHash',
            };
        },
    },

    beforeCreate() {
        this.OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products, this.recommendationType);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    mounted() {
        if (!this.recommendationType && !this.productsSkuList) {
            this.displayContainer = false;
            this.isDisplayed = false;
        }
    },

    methods: {
        async loadedProducts({ products }) {
            if (!products.length) {
                this.displayContainer = false;

                return;
            }

            this.isLoaded = true;
            await this.loadProductsSliderComponent();
            this.productsLoaded = products;
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
        },

        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
            }
        },

        emitNavigationButtonClickEvent(direction) {
            if (!this.recommendationType) {
                return;
            }

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_RECOMMENDATION_SLIDER_ARROW_CLICK,
                {
                    sliderName: this.recommendationType,
                    direction:
                        direction === 1
                            ? GLOBAL_LABELS.NAV_RIGHT
                            : GLOBAL_LABELS.NAV_LEFT,
                }
            );
        },

        onAddToWishlistClick({ product, index, isAdded }) {
            if (isAdded) {
                return;
            }

            this.$analytics.emit(RECOMMENDATIONS_ADD_TO_WISHLIST, {
                currency: this.currency,
                value: product.price.promotional.amount,
                recommendationProducts: [
                    this.buildSyneriseDataLayerProduct(product, index),
                ],
                ...this.eventData,
            });
        },

        buildSyneriseDataLayerProduct(product, position) {
            return new SyneriseDataLayerProduct({
                product,
                position,
                recommendationType: this.recommendationType,
                pageType: this.$route.name,
            }).build();
        },
    },
};
</script>

<style lang="scss" scoped>
$slide-height-mobile: 276px;
$slide-height: 384px;
$scrollbar-height-mobile: 24px + $tailwindcss-spacing-1;
$scrollbar-height: $tailwindcss-spacing-6;

@mixin products-slider-min-height($slide-height, $scrollbar-height) {
    min-height: calc(#{$slide-height} + #{$scrollbar-height});
}

.marketing-products-slider {
    @include products-slider-min-height(
        $slide-height-mobile,
        $scrollbar-height-mobile
    );

    &.is-loading {
        @apply bg-gray8;
    }

    @screen lg {
        @include products-slider-min-height($slide-height, $scrollbar-height);
    }
}
</style>
