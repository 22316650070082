<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppSearchMenuScreenV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotHeaderItems: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotFooterItems: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    slotHeaderItems: '',
                    slotFooterItems: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotHeaderItems: this.slotHeaderItems
                    ? JSON.parse(this.slotHeaderItems)
                    : '',

                slotFooterItems: this.slotFooterItems
                    ? JSON.parse(this.slotFooterItems)
                    : '',
            };
        },
    },
};
</script>
