<template>
    <PageBuilderInterpreter :components-json="slotDefault" />
</template>

<script>
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsApp',

    components: {
        PageBuilderInterpreter,
    },

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },
            },
        };
    },
};
</script>
