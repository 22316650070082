<script>
import { mapState } from 'vuex';
import { generateSpacingClasses } from '@modules/page-builder/helpers/html';
import { isPropSlotName } from '@modules/page-builder/helpers/component';

export default {
    name: 'CmsBaseComponent',

    props: {
        marginTop: {
            type: String,
            default: '',
        },

        staticClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            fetch: {},
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {},
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: generateSpacingClasses('mt'),
                staticClass: [
                    {
                        label: 'brak',
                        value: '',
                    },
                    {
                        label: 'w-full',
                        value: 'w-full',
                    },
                ],
            },
        };
    },

    computed: {
        ...mapState({ isMobileProp: 'isMobile' }),

        // eslint-disable-next-line vue/no-unused-properties
        cssClass() {
            return `${this.marginTop} ${this.staticClass}`;
        },

        // eslint-disable-next-line vue/no-unused-properties
        propsToBind() {
            const propsArray = Object.entries(this.$props).filter(
                ([propName]) => {
                    return (
                        propName !== 'marginTop' &&
                        propName !== 'staticClass' &&
                        !isPropSlotName(propName)
                    );
                }
            );

            return Object.fromEntries(propsArray);
        },
    },
};
</script>
