<template>
    <MarketingInfluencerSliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingInfluencerSliderSlide from '@organisms/MarketingInfluencerSlider/MarketingInfluencerSliderSlide';

export default {
    name: 'CmsMarketingInfluencerSliderSlide',

    components: {
        MarketingInfluencerSliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingInfluencerSliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    influencerName: '',
                    influencerAvatarUrl: '',
                    imageUrl: '',
                    productSkus: '',
                    productsCount: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
