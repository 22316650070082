<template>
    <div class="vertical-products-simple-slider">
        <ContainerContent class="slider-container">
            <SimpleSlider
                orientation="vertical"
                :items-count="itemsCount"
                :additional-scroll-offset="0"
                class="slider"
                :has-scrollbar="false"
                :slider-class="sliderClass"
                :navigation-buttons-styles="navigationButtonsStyles"
                navigation-button-class="vertical-products-navigation-button"
            >
                <template #slides>
                    <VerticalProductsSimpleSliderSlide
                        v-for="product in products"
                        :key="`vertical-products-simple-slider-slide-${product.sku}`"
                        :product="product"
                        class="item"
                    />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { md as BREAKPOINT_MD } from '@theme/resources/variables/responsiveBreakpoints.json';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

import VerticalProductsSimpleSliderSlide from '@organisms/VerticalProductsSimpleSlider/VerticalProductsSimpleSliderSlide';

const RESIZE_DEBOUNCE = 150;

export default {
    name: 'VerticalProductsSimpleSlider',

    components: {
        SimpleSlider,
        VerticalProductsSimpleSliderSlide,
        ContainerContent,
    },

    props: {
        products: {
            type: Array,
            default: () => [],
        },

        sliderClass: {
            type: String,
            default: '',
        },

        itemsCount: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        navigationButtonsStyles: {},
    }),

    computed: {
        mobileDeviceBreakpoint() {
            return parseInt(BREAKPOINT_MD, 10) - 1;
        },
    },

    mounted() {
        this.getNavigationButtonsStyles();
        this.onResizeFn = debounce(
            this.getNavigationButtonsStyles,
            RESIZE_DEBOUNCE
        );
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeDestroy() {
        this.onResizeFn.cancel();
        window.removeEventListener('resize', this.onResizeFn, false);
    },

    methods: {
        isMobileDevice() {
            return window.matchMedia(
                `(max-width: ${this.mobileDeviceBreakpoint}px)`
            ).matches;
        },

        getNavigationButtonsStyles() {
            if (this.isMobileDevice()) {
                this.navigationButtonsStyles = { display: 'none' };

                return;
            }

            this.navigationButtonsStyles = {};
        },
    },
};
</script>

<style lang="scss" scoped>
.vertical-products-simple-slider {
    &:deep() {
        .vertical-products-navigation-button {
            @apply border-0;

            &.navigation-button-prev {
                @apply -mt-7;
            }

            &.navigation-button-next {
                @apply -mb-7;
            }
        }
    }
}
</style>
