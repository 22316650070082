<template>
    <div v-if="hasTooltipContent" class="question-mark-tooltip">
        <BaseTooltip
            :position="tooltipPosition"
            padding="15px"
            :is-mobile="isMobile"
            tooltip-class="review-tooltip"
            tooltip-container-class="review-tooltip-container"
        >
            <SvgIcon :width="iconWidth" :height="iconHeight">
                <QuestionMarkCircled />
            </SvgIcon>

            <template #tooltipContent>
                <slot>
                    <span v-html="tooltipContent" />
                </slot>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import QuestionMarkCircled from '@static/icons/24px/question-mark-circled.svg?inline';

export default {
    name: 'QuestionMarkTooltip',

    components: {
        BaseTooltip,
        SvgIcon,
        QuestionMarkCircled,
    },

    props: {
        isMobile: {
            type: Boolean,
            required: true,
        },

        tooltipContent: {
            type: String,
            default: '',
        },

        iconWidth: {
            type: String,
            default: '24px',
        },

        iconHeight: {
            type: String,
            default: '24px',
        },

        tooltipPosition: {
            type: String,
            default: 'left',
        },
    },

    computed: {
        hasTooltipContent() {
            return this.tooltipContent.length || !!this.$slots.default;
        },
    },
};
</script>

<style lang="scss" scoped>
$tooltip-width-mobile: 246px;
$tooltip-width-tablet: 286px;

.question-mark-tooltip {
    @apply self-center;

    &:deep() {
        .review-tooltip {
            @apply block;
        }

        .review-tooltip-container {
            width: $tooltip-width-mobile;
        }

        .list {
            @apply list-decimal;
        }

        .list-item {
            @apply mt-3 ml-3;
        }

        .tooltip-link {
            @apply text-blue4 font-bold;
        }
    }

    @screen md {
        &:deep() {
            .review-tooltip-container {
                width: $tooltip-width-tablet;
            }
        }
    }
}
</style>
