import LoaderSmall from '@static/icons/20px/loader.svg?inline';
import LoaderBig from '@static/icons/24px/loader.svg?inline';

import {
    BLOCK_LOADER_SIZE_SMALL,
    BLOCK_LOADER_SIZE_BIG,
} from '@types/CmsBlockImport';

export const BLOCK_LOADER_ICON_MAP = {
    [BLOCK_LOADER_SIZE_SMALL]: {
        icon: LoaderSmall,
        size: 20,
    },
    [BLOCK_LOADER_SIZE_BIG]: {
        icon: LoaderBig,
        size: 24,
    },
};
