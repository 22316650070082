<template>
    <PageBuilderInterpreter
        :tag="HelpCenterCard"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { getProps } from '@modules/page-builder/helpers/component';
import HelpCenterCard from '@molecules/HelpCenterCard/HelpCenterCard';
import { ICON_TYPES } from '@configs/cms-help-center';

export default {
    name: 'CmsHelpCenterCard',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(HelpCenterCard),
    },

    data() {
        return {
            HelpCenterCard,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    icon: 'account',
                    heading: 'heading',
                    allQuestionsUrl: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                icon: Object.values(ICON_TYPES),
            },
        };
    },
};
</script>
