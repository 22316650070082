<template>
    <MarketingProductsSlider v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import { THEMES } from '@types/MarketingSection';
import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingProductsSlider from '@organisms/MarketingSliderSection/MarketingProductsSlider';

export default {
    name: 'CmsMarketingProductsSlider',

    components: {
        MarketingProductsSlider,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingProductsSlider),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    theme: THEMES.LIGHT,
                    container: 'marketing-products-slider-container',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                theme: Object.values(THEMES),
                recommendationType: [
                    ...Object.values(SYNERISE_RECOMMENDATION_TYPES),
                    '',
                ],
            },
        };
    },
};
</script>
