import { ASYNC_CMS_BLOCKS_SERVICE_CONFIG } from '@async-services/cmsBlocks/meta';

const { NAME, METHODS } = ASYNC_CMS_BLOCKS_SERVICE_CONFIG;

export default async (app = null) => {
    if (!app) {
        return [];
    }

    const availableBlocks = await app.$asyncServices.use(
        NAME,
        METHODS.GET_AVAILABLE_BLOCKS
    );

    return availableBlocks.map(({ identifier }) => identifier);
};
