<template>
    <section class="marketing-popular-categories">
        <ContainerContent :align-left="true">
            <div class="wrapper">
                <BaseHeading class="heading" tag="h2" theme="h3">
                    {{ heading }}
                </BaseHeading>
                <WithScroll
                    theme="light"
                    :is-horizontal="true"
                    :hide-scrollbar="true"
                >
                    <div class="categories">
                        <slot />
                    </div>
                </WithScroll>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import WithScroll from '@atoms/WithScroll/WithScroll';

export default {
    name: 'MarketingPopularCategories',
    components: {
        ContainerContent,
        BaseHeading,
        WithScroll,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$categories-md-margin: $tailwindcss-spacing-3;
$categories-md-items-in-row: 3;

.marketing-popular-categories {
    @apply w-full;

    .wrapper {
        @apply w-full;
    }

    .heading {
        @apply block mb-3 px-3;
    }

    .categories {
        @apply flex max-w-full text-center;

        &::after,
        &::before {
            @apply p-2;
            content: '';
        }
    }

    &:deep() {
        .container-content {
            @apply px-0;
        }

        .marketing-popular-category {
            @apply mr-3;

            &:last-of-type {
                @apply mr-0;
            }
        }
    }
}

@screen lg {
    .marketing-popular-categories {
        .heading {
            @apply px-0;
        }

        .categories {
            @apply flex-wrap justify-between;

            &::after,
            &::before {
                @apply hidden;
            }
        }

        &:deep() {
            .marketing-popular-category {
                @apply mr-0 mb-3;
                width: calc(
                    (100% / #{$categories-md-items-in-row}) -
                        (
                            (2 * #{$categories-md-margin}) / #{$categories-md-items-in-row}
                        )
                );
            }

            .container-content {
                @apply px-5;
            }
        }
    }
}

@screen xl {
    .marketing-popular-categories {
        .categories {
            @apply flex-nowrap;
        }

        &:deep() {
            .marketing-popular-category {
                @apply w-full mr-3 mb-0;
            }
        }
    }
}
</style>
