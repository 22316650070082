<template>
    <ContainerContentWithSidebar v-bind="propsToBind">
        <template #sidebar>
            <PageBuilderInterpreter :components-json="slotSidebar" />
        </template>
        <template #default>
            <PageBuilderInterpreter :components-json="slotDefault" />
        </template>
    </ContainerContentWithSidebar>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import ContainerContentWithSidebar from '@molecules/ContainerContentWithSidebar/ContainerContentWithSidebar';

export default {
    name: 'CmsContainerContentWithSidebar',

    components: {
        ContainerContentWithSidebar,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        slotSidebar: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(ContainerContentWithSidebar),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                    sidebar: '',
                },
            },
        };
    },
};
</script>
