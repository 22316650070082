<template>
    <div
        :style="{ backgroundColor }"
        :class="theme"
        class="marketing-promo-slider"
    >
        <ContainerContent class="container">
            <div class="header">
                <div v-if="sliderTitle" class="title">
                    <SvgIcon v-if="icon" class="icon">
                        <component :is="iconType" />
                    </SvgIcon>

                    <BaseHeading theme="h3" class="slider-title">
                        {{ sliderTitle }}
                    </BaseHeading>
                </div>

                <CountdownTimer
                    :countdown-end-date="countdownEndDate"
                    :countdown-text="countdownText"
                    :before-countdown-text="beforeCountdownText"
                    class="timer"
                />
            </div>
        </ContainerContent>

        <div class="slider">
            <slot />
        </div>

        <ContainerContent v-if="showAllCopy && showAllLink" class="container">
            <div class="button-wrapper">
                <ButtonSmall
                    :variant="buttonVariant"
                    :to="showAllLink"
                    class="button"
                >
                    {{ showAllCopy }}
                </ButtonSmall>
            </div>
        </ContainerContent>
    </div>
</template>

<script>
import { light } from '@theme/resources/variables/colors.json';

import { ICON_MAP, ICON_TYPES } from '@types/MarketingPromoSlider';
import { THEMES } from '@types/MarketingSection';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import CountdownTimer from '@organisms/CountdownTimer/CountdownTimer';

import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';

export default {
    name: 'MarketingPromoSlider',

    components: {
        CountdownTimer,
        BaseHeading,
        SvgIcon,
        ContainerContent,
        ButtonSmall,
    },

    props: {
        sliderTitle: {
            type: String,
            default: '',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        showAllCopy: {
            type: String,
            default: '',
        },

        showAllLink: {
            type: String,
            default: '',
        },

        beforeCountdownText: {
            type: String,
            default: '',
        },

        countdownText: {
            type: String,
            default: '',
        },

        countdownEndDate: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
            validator: checkIfExistsInValuesMap(ICON_TYPES, true),
        },

        backgroundColor: {
            type: String,
            default: light,
        },
    },

    computed: {
        buttonVariant() {
            return this.theme === THEMES.THEME_LIGHT
                ? BUTTON_SMALL_VARIANTS.SECONDARY_ALTERNATIVE
                : BUTTON_SMALL_VARIANTS.PRIMARY;
        },

        iconType() {
            return ICON_MAP[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
$header-height: 52px;
$button-padding: 72px;

.marketing-promo-slider {
    @apply block py-5;

    &.light {
        .icon,
        .slider-title,
        .timer {
            @apply text-light;
        }
    }

    .container {
        @apply block;
    }

    .title {
        @apply w-full flex items-center;
    }

    .timer {
        @apply mt-1 self-center;
    }

    .icon {
        @apply mr-2 w-5 h-5;
    }

    .button-wrapper {
        @apply mt-3;
    }

    .button {
        @apply w-full;
    }

    .slider {
        @apply mt-3;
        max-width: theme('customVariables.containerContent.maxWidth');
    }

    @screen md {
        .header {
            @apply flex;
        }

        .text {
            @apply text-r leading-r mt-2 mr-3 whitespace-nowrap;
        }

        .timer {
            @apply flex justify-end w-full;
        }

        .button-wrapper {
            @apply mx-0 text-center;
        }

        .button {
            @apply w-auto;
            padding-right: $button-padding;
            padding-left: $button-padding;
        }
    }

    @screen lg {
        .header {
            height: $header-height;
        }

        .icon {
            @apply w-7 h-7;
        }

        .slider {
            @apply mt-5 px-5 mx-auto;
        }
    }
}
</style>
