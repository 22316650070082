<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppProductListingScreenV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotHeader: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        firstItemOffset: {
            type: String,
            default: '0',
        },

        // eslint-disable-next-line vue/no-unused-properties
        displayItems: {
            type: String,
            default: '0',
        },

        // eslint-disable-next-line vue/no-unused-properties
        spacedBy: {
            type: String,
            default: '0',
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotMarketingBar: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    header: '',
                    marketingBar: '',
                },

                props: {
                    firstItemOffset: '0',
                    displayItems: '0',
                    spacedBy: '0',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotHeader: this.slotHeader ? JSON.parse(this.slotHeader) : '',
                slotMarketingBar: this.slotMarketingBar
                    ? JSON.parse(this.slotMarketingBar)
                    : '',
            };
        },
    },
};
</script>
