<template>
    <section v-if="!disabled" class="marketing-cards-slider">
        <ContainerContent :align-left="true" class="slider-container">
            <div class="wrapper">
                <MarketingCardsSliderHeadings
                    :heading="heading"
                    :sub-heading="subHeading"
                />

                <SimpleSlider
                    :items-count="3"
                    :additional-scroll-offset="32"
                    class="slider"
                >
                    <template #slides>
                        <slot />
                    </template>
                </SimpleSlider>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import MarketingCardsSliderHeadings from './MarketingCardsSliderHeadings';

export default {
    name: 'MarketingCardsSlider',

    components: {
        ContainerContent,
        MarketingCardsSliderHeadings,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

$heading-height: #{theme('lineHeight.r')};
$sub-heading-height: #{theme('lineHeight.r')};
$headings-margin: #{theme('spacing.2')};

$items-count: 3;
$slide-width: math.div(100%, $items-count);
$slide-gap: $tailwindcss-spacing-5;

$navigation-buttons-width: $tailwindcss-spacing-7 * 2;
$navigation-buttons-height: $tailwindcss-spacing-8;

.marketing-cards-slider {
    @apply w-full;

    .slider-container {
        @apply px-0;
    }

    .wrapper {
        @apply w-full;
    }

    .slider {
        @apply mt-3;

        &:deep() {
            .navigation-button {
                @apply hidden;
            }
        }

        @screen md {
            &:deep() {
                .navigation-button {
                    @apply flex;
                    top: calc(
                        (
                                100% - #{$heading-height} - #{$sub-heading-height} -
                                    #{$headings-margin}
                            ) / 2
                    );

                    &:disabled {
                        @apply hidden;
                    }
                }
            }
        }
    }

    @screen lg {
        .slider-container {
            @apply px-5;
        }
    }
}
</style>
