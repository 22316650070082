<template>
    <div class="promotion-labels">
        <BaseBadge :background-color="promoLabel.bgColor">
            <span
                :style="{
                    color: promoLabel.textColor,
                }"
                class="content"
                v-html="promoLabel.formattedContent"
            />
        </BaseBadge>
    </div>
</template>

<script>
import BaseBadge from '@atoms/BaseBadge/BaseBadge';

export default {
    name: 'PromotionLabel',

    components: {
        BaseBadge,
    },

    props: {
        promoLabel: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.promotion-labels {
    @apply flex justify-center;

    .content {
        @apply text-center;
    }
}
</style>
