<template>
    <RecommendedProductsSliderWrapper v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import RecommendedProductsSliderWrapper from '@organisms/RecommendedProductsSliderWrapper/RecommendedProductsSliderWrapper';
import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

export default {
    name: 'CmsRecommendedProductsSliderWrapper',

    components: {
        RecommendedProductsSliderWrapper,
    },

    extends: CmsBaseComponent,

    props: getProps(RecommendedProductsSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    recommendationType:
                        SYNERISE_RECOMMENDATION_TYPES.TYPE_CUSTOM,

                    productSku: '',
                    productBrand: '',
                    categoryBreadcrumbs: [],
                    customCampaignId: '',
                    container: 'recommendation-slider-container',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                recommendationType: {
                    disabled: true,
                },
            },
        };
    },
};
</script>
