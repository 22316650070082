import Check from '@static/icons/24px/check.svg?inline';
import Info from '@static/icons/24px/info.svg?inline';
import ExclamationMark from '@static/icons/24px/exclamation-mark.svg?inline';
import Return from '@static/icons/24px/replay.svg?inline';
import Lock from '@static/icons/24px/lock-outline.svg?inline';

export const TYPE_POSITIVE = 'positive';
export const TYPE_NEGATIVE = 'negative';
export const TYPE_NEUTRAL = 'neutral';
export const TYPE_WARNING = 'warning';
export const TYPE_RETURN = 'return';
export const TYPE_LOCK = 'lock';

export const TYPES = {
    TYPE_POSITIVE,
    TYPE_NEGATIVE,
    TYPE_NEUTRAL,
    TYPE_WARNING,
    TYPE_RETURN,
    TYPE_LOCK,
};

export const ICON_TYPE_POSITIVE = Check;
export const ICON_TYPE_NEGATIVE = ExclamationMark;
export const ICON_TYPE_NEUTRAL = Info;
export const ICON_TYPE_WARNING = ExclamationMark;
export const ICON_TYPE_RETURN = Return;
export const ICON_TYPE_LOCK = Lock;

export const TYPE_TO_ICON_MAP = {
    [TYPE_POSITIVE]: ICON_TYPE_POSITIVE,
    [TYPE_NEGATIVE]: ICON_TYPE_NEGATIVE,
    [TYPE_NEUTRAL]: ICON_TYPE_NEUTRAL,
    [TYPE_WARNING]: ICON_TYPE_WARNING,
    [TYPE_RETURN]: ICON_TYPE_RETURN,
    [TYPE_LOCK]: ICON_TYPE_LOCK,
};
