<template>
    <div class="marketing-influencer-headings">
        <span class="heading">{{ heading }}</span>
        <BaseHeading tag="h2" theme="h3" class="sub-heading">
            {{ subHeading }}
        </BaseHeading>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'MarketingInfluencerSliderHeadings',

    components: {
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-influencer-headings {
    @apply flex flex-col;

    .heading {
        @apply text-s leading-s;
    }

    .sub-heading {
        @apply mt-2;
    }

    @screen md {
        @apply -mb-7;

        .heading {
            @apply text-r leading-r;
        }
    }
}
</style>
