<template>
    <BaseHeading v-bind="propsToBind" :class="cssClass">
        <slot v-if="slotDefault">{{ JSON.parse(slotDefault) }}</slot>
    </BaseHeading>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import { VALID_HEADING_TYPES } from '@configs/headings';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import colors from '@theme/resources/variables/colors.json';

export default {
    name: 'CmsBaseHeading',

    components: {
        BaseHeading,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(BaseHeading),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: 'example heading',
                },

                props: {
                    marginTop: 'mt-4',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                tag: VALID_HEADING_TYPES,
                theme: VALID_HEADING_TYPES,
                color: Object.keys(colors),
            },
        };
    },
};
</script>
