export default {
    props: {
        isLinkDisabled: {
            type: Boolean,
            default: false,
        },

        product: {
            type: Object,
            default: () => ({}),
        },

        isLazy: {
            type: Boolean,
            default: true,
        },

        hasWishListButton: {
            type: Boolean,
            default: true,
        },

        showPromoSticker: {
            type: Boolean,
            default: true,
        },

        priceDisplay: {
            type: String,
            default: 'inline',
        },

        pictureSizes: {
            type: String,
            default: null,
        },

        isMobile: {
            type: Boolean,
            default: false,
        },

        hasSponsoredText: {
            type: Boolean,
            default: false,
        },

        attributionToken: {
            type: [String, null],
            default: null,
        },

        index: {
            type: Number,
            default: null,
        },
    },
};
