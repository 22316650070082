<template>
    <SideMenuItem v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import SideMenuItem from '@molecules/SideMenu/SideMenuItem';

export default {
    name: 'CmsSideMenuItem',

    components: {
        SideMenuItem,
    },

    extends: CmsBaseComponent,

    props: getProps(SideMenuItem),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    path: '/',
                    name: 'label',
                },
            },
        };
    },
};
</script>
