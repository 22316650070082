<template>
    <PageBuilderInterpreter :components-json="slotDefault" />
</template>

<script>
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsAppEobBoxBenefits',

    components: {
        PageBuilderInterpreter,
    },

    /* eslint-disable vue/no-unused-properties */
    props: {
        priority: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            required: true,
        },

        women: {
            type: Boolean,
            default: false,
        },

        men: {
            type: Boolean,
            default: false,
        },

        child: {
            type: Boolean,
            default: false,
        },

        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    priority: '',
                    tag: '',
                    women: false,
                    men: false,
                    child: false,
                },
            },
        };
    },
};
</script>
