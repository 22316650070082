<template>
    <div class="marketing-value-proposition-item-heading">
        <BasePicture
            v-if="imageUrl"
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :is-lazy="true"
            class="image"
        />

        <div v-else-if="text" :style="{ color: textColor }" class="text">
            {{ text }}
        </div>
    </div>
</template>

<script>
import { text2 } from '@theme/resources/variables/colors.json';

import { IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h } from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketingValuePropositionItemHeading',

    components: {
        BasePicture,
    },

    props: {
        textColor: {
            type: String,
            default: text2,
        },

        imageUrl: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-value-proposition',
                this.text,
                IMAGE_TYPE_MARKETING_VALUE_PROPOSITION_198w_140h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-value-proposition-item-heading {
    .text {
        @apply mt-3 ml-3;
        font-size: theme(
            'customVariables.valueProposition.default.headingSize'
        );
        line-height: theme(
            'customVariables.valueProposition.default.headingLineHeight'
        );
    }

    .image {
        @apply mt-3 ml-3;
        width: theme('customVariables.valueProposition.default.imageWidth');
        height: theme('customVariables.valueProposition.default.imageHeight');
    }

    @screen lg {
        .text {
            @apply mt-5 ml-5;
            font-size: theme('customVariables.valueProposition.lg.headingSize');
            line-height: theme(
                'customVariables.valueProposition.lg.headingLineHeight'
            );
        }

        .image {
            @apply mt-5 ml-5;
            width: theme('customVariables.valueProposition.lg.imageWidth');
            height: theme('customVariables.valueProposition.lg.imageHeight');
        }
    }
}
</style>
