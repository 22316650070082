<template>
    <RecentlyViewedProductsSliderWrapper
        v-bind="propsToBind"
        :class="cssClass"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import RecentlyViewedProductsSliderWrapper from '@organisms/RecentlyViewedProductsSliderWrapper/RecentlyViewedProductsSliderWrapper';
import { ITEMS_COUNT } from '@configs/simple-slider';

export default {
    name: 'CmsRecentlyViewedProductsSliderWrapper',

    components: {
        RecentlyViewedProductsSliderWrapper,
    },

    extends: CmsBaseComponent,

    props: getProps(RecentlyViewedProductsSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                itemsCount: Object.values(ITEMS_COUNT),
            },
        };
    },
};
</script>
