<template>
    <HelpCenterCardLink v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import HelpCenterCardLink from '@molecules/HelpCenterCard/HelpCenterCardLink';

export default {
    name: 'CmsHelpCenterCardLink',

    components: {
        HelpCenterCardLink,
    },

    extends: CmsBaseComponent,

    props: getProps(HelpCenterCardLink),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    path: '/',
                    label: 'label',
                },
            },
        };
    },
};
</script>
