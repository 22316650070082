<template>
    <div class="marketing-iframe">
        <iframe :src="src" class="content" @load="$emit('iframe-loaded')" />
    </div>
</template>

<script>
export default {
    name: 'MarketingIframe',

    props: {
        src: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-iframe {
    @apply flex flex-col w-full h-full;

    .content {
        @apply h-full w-full;
    }
}
</style>
