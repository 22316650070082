<template>
    <div
        :style="{
            '--marketing-hero-background-color': bgColor,
        }"
        class="marketing-hero-block-content"
    >
        <div class="texts">
            <HeaderText
                :tag="HEADER_TEXT_TAGS.H1"
                :size="HEADER_TEXT_SIZES.L"
                :variant="textVariant"
                class="subheader"
            >
                {{ subheader }}
            </HeaderText>
            <HeaderText
                v-if="header"
                :tag="HEADER_TEXT_TAGS.H1"
                :size="HEADER_TEXT_SIZES.XL"
                :variant="textVariant"
                class="header"
            >
                {{ header }}
            </HeaderText>
            <BodyText
                v-if="body"
                :tag="BODY_TEXT_TAGS.P"
                :size="BODY_TEXT_SIZES.M"
                :variant="BODY_TEXT_VARIANTS.REGULAR"
                :color="bodyTextColor"
                class="body"
            >
                {{ body }}
            </BodyText>
        </div>
        <ButtonLarge v-if="buttonLabel" :variant="buttonVariant" class="button">
            {{ buttonLabel }}
        </ButtonLarge>
    </div>
</template>

<script>
import { checkIfExistsInValuesMap } from '@assets/props';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
    HEADER_TEXT_VARIANTS,
} from '@eobuwie-ui/components/HeaderText/v1';
import {
    BodyText,
    BODY_TEXT_TAGS,
    BODY_TEXT_SIZES,
    BODY_TEXT_COLORS,
    BODY_TEXT_VARIANTS,
} from '@eobuwie-ui/components/BodyText/v1';
import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingHeroBlockContent',

    components: {
        HeaderText,
        BodyText,
        ButtonLarge,
    },

    props: {
        subheader: {
            type: String,
            required: true,
        },

        header: {
            type: String,
            default: '',
        },

        body: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        bgColor: {
            type: String,
            default: '#0000',
        },

        textVariant: {
            type: String,
            default: HEADER_TEXT_VARIANTS.BASIC,
            validator: checkIfExistsInValuesMap(HEADER_TEXT_VARIANTS),
        },

        bodyTextColor: {
            type: String,
            default: BODY_TEXT_COLORS.BASIC,
            validator: checkIfExistsInValuesMap(BODY_TEXT_COLORS),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.PRIMARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },
    },

    beforeCreate() {
        this.HEADER_TEXT_TAGS = HEADER_TEXT_TAGS;
        this.HEADER_TEXT_SIZES = HEADER_TEXT_SIZES;
        this.BODY_TEXT_TAGS = BODY_TEXT_TAGS;
        this.BODY_TEXT_SIZES = BODY_TEXT_SIZES;
        this.BODY_TEXT_VARIANTS = BODY_TEXT_VARIANTS;
    },
};
</script>

<style lang="scss" scoped>
$marketing-hero-width: 432px;

.marketing-hero-block-content {
    @apply w-full p-ui-4;

    background: var(--marketing-hero-background-color);

    .texts {
        @apply flex flex-col items-start gap-y-ui-2;
    }

    .button {
        @apply mt-ui-4;
    }

    .subheader,
    .header,
    .body {
        @apply truncate;
    }

    @screen lg {
        @apply py-ui-6 px-ui-7 block;

        width: $marketing-hero-width;

        .texts {
            @apply gap-y-ui-3;

            // Muszą byc zagnieżdżone, żeby style z DS ich nie nadpisały w buildzie produkcyjnym
            .subheader {
                @apply font-ui-header-xl;
            }

            .header {
                @apply font-ui-header-xxl;
            }

            .body {
                @apply font-ui-body-m;
            }
        }

        .button {
            @apply mt-ui-6;
        }
    }
}
</style>
