import {
    SECONDS_IN_DAY,
    SECONDS_IN_HOUR,
    SECONDS_IN_MINUTE,
    MILLISECONDS_IN_SECOND,
} from '@types/Datetime';

const HOURS_IN_DAY = 24;

export function getTimeParts(timeDiff) {
    const seconds = timeDiff % SECONDS_IN_MINUTE;
    const minutes = Math.floor(
        (timeDiff % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE
    );
    const hours = Math.floor((timeDiff % SECONDS_IN_DAY) / SECONDS_IN_HOUR);
    const days = Math.floor(timeDiff / SECONDS_IN_DAY);

    return {
        seconds,
        minutes,
        hours,
        days,
    };
}

class CountDown {
    constructor({
        element,
        timestamp,
        eventName,
        isDaysInHours,
        onTimeRender = () => {},
    }) {
        this.animationStartTime = 0;
        this.elements = {
            root: element,
            seconds: null,
            minutes: null,
            hours: null,
            days: null,
            containerToShow: null,
            containerToHide: null,
        };

        this.timestamp = parseInt(timestamp, 10);
        this.eventName = eventName;
        this.isDaysInHours = Boolean(isDaysInHours);

        this.isCounting = false;
        this.onTimeRender = onTimeRender;

        this.initTimeElements();
    }

    initTimeElements() {
        const { root } = this.elements;

        this.elements.seconds = root.querySelector('[data-seconds]');
        this.elements.minutes = root.querySelector('[data-minutes]');
        this.elements.hours = root.querySelector('[data-hours]');
        this.elements.days = root.querySelector('[data-days]');
        this.elements.containerToShow = root.querySelector('[data-show]');
        this.elements.containerToHide = root.querySelector('[data-hide]');
    }

    start() {
        this.isCounting = true;
        window.requestAnimationFrame(this.animate.bind(this));
    }

    stop() {
        this.isCounting = false;
    }

    animate(timestamp) {
        const timeDiff = this.getSecondsDiff();

        if (timeDiff >= 0) {
            if (timestamp - this.animationStartTime >= MILLISECONDS_IN_SECOND) {
                this.renderTime(timeDiff);

                this.animationStartTime = timestamp;
            }

            if (this.isCounting) {
                window.requestAnimationFrame(this.animate.bind(this));
            }
        } else {
            const {
                elements: { root: element, containerToShow, containerToHide },
                eventName,
            } = this;

            this.renderTime(0);

            if (containerToShow) {
                containerToShow.style.display = 'flex';
            }

            if (containerToHide) {
                containerToHide.style.display = 'none';
            }

            if (eventName) {
                const event = new CustomEvent(eventName, {
                    detail: { element },
                });

                window.dispatchEvent(event);
            }
        }
    }

    getSecondsDiff() {
        const timestampNow = new Date().getTime() / MILLISECONDS_IN_SECOND;

        return Math.floor(this.timestamp - timestampNow);
    }

    renderTime(timeDiff) {
        const {
            elements: {
                seconds: secondsContainer,
                minutes: minutesContainer,
                hours: hoursContainer,
                days: daysContainer,
            },
            isDaysInHours,
        } = this;

        const timeParts = getTimeParts(timeDiff);

        this.onTimeRender(timeParts);

        const { seconds = 0, minutes = 0, hours = 0, days = 0 } = timeParts;

        const hoursToShow = isDaysInHours ? hours + days * HOURS_IN_DAY : hours;

        if (secondsContainer) {
            secondsContainer.innerText = String(seconds).padStart(2, '0');
        }

        if (minutesContainer) {
            minutesContainer.innerText = String(minutes).padStart(2, '0');
        }

        if (hoursContainer) {
            hoursContainer.innerText = String(hoursToShow).padStart(2, '0');
        }

        if (daysContainer && !isDaysInHours) {
            daysContainer.innerText = String(days).padStart(2, '0');
        }
    }
}

export default {
    init(element, isDaysInHours, onTimeRender) {
        if (!(element instanceof HTMLElement)) {
            return;
        }

        const { timestamp, eventName } = element.dataset;

        if (!timestamp) {
            return;
        }

        return new CountDown({
            element,
            timestamp,
            eventName,
            isDaysInHours,
            onTimeRender,
        });
    },
};
