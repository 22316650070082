<template>
    <div />
</template>

<script>
import { PAYMENT_WIDGETS } from '@types/PaymentWidgets';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsPaymentWidgetConfig',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        paymentType: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        customText: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    paymentType: PAYMENT_WIDGETS.TEXT,
                    customText: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                paymentType: Object.values(PAYMENT_WIDGETS),
            },
        };
    },
};
</script>
