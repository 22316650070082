<template>
    <div class="marketing-brands-section-image">
        <BaseBadge v-if="hasBadge" class="badge">{{ $t('New') }}</BaseBadge>

        <BaseLink v-if="image" :path="url">
            <div class="wrapper">
                <BasePicture
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :is-lazy="true"
                />
            </div>
        </BaseLink>
    </div>
</template>

<script>
import { IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h } from '@types/Image';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseBadge from '@atoms/BaseBadge/BaseBadge';

export default {
    name: 'MarketingBrandsSectionImage',

    components: {
        BasePicture,
        BaseLink,
        BaseBadge,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        brandName: {
            type: String,
            required: true,
        },

        hasBadge: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-brands-picture',
                this.brandName,
                IMAGE_TYPE_MARKETING_BRANDS_SECTION_256w_106h
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-brands-section-image {
    transition: 0.2s ease;

    &:hover {
        @apply opacity-80 cursor-pointer;
        transform: translateY(
            theme('customVariables.marketingSlider.translateValue')
        );
    }

    .badge {
        @apply absolute z-1 right-4 top-4;
    }

    .wrapper {
        @apply bg-light flex items-center justify-center relative;
        width: theme('customVariables.brandsSection.default.width');
        height: theme('customVariables.brandsSection.default.height');
    }

    &:deep(.base-picture) {
        @apply w-full;
    }

    @screen lg {
        .wrapper {
            width: theme('customVariables.brandsSection.lg.width');
        }
    }
}
</style>
