<template>
    <div
        v-if="!divWithIdAlreadyExists"
        :id="divId"
        class="zowie-form"
        :class="cssClass"
    />
</template>

<script>
import zowieFormInit, { DEFAULT_ZOWIE_EMBEDDED_SRC } from '@assets/zowie-form';

const ZOWIE_DEFAULT_SESSION_TIMEOUT = '900000'; // 15 minutes

export default {
    name: 'CmsZowieForm',

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        scriptSrc: {
            type: String,
            default: DEFAULT_ZOWIE_EMBEDDED_SRC,
        },

        // eslint-disable-next-line vue/no-unused-properties
        instanceId: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        sessionTerminatedText: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        sessionTerminatedButtonCaption: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        endChatQuestion: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        endChatButtonYesCaption: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        endChatButtonNoCaption: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        endChatButtonCaption: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        downloadTranscriptButtonCaption: {
            type: String,
            default: '',
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        closable: {
            type: Boolean,
            default: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        sessionTimeout: {
            type: String,
            default: ZOWIE_DEFAULT_SESSION_TIMEOUT,
        },

        // eslint-disable-next-line vue/no-unused-properties
        divId: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        divWithIdAlreadyExists: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        cssClass: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    scriptSrc: DEFAULT_ZOWIE_EMBEDDED_SRC,
                    instanceId: '',
                    sessionTerminatedText: '',
                    sessionTerminatedButtonCaption: '',
                    endChatQuestion: '',
                    endChatButtonYesCaption: '',
                    endChatButtonNoCaption: '',
                    endChatButtonCaption: '',
                    downloadTranscriptButtonCaption: '',
                    closable: true,
                    sessionTimeout: parseInt(ZOWIE_DEFAULT_SESSION_TIMEOUT, 10),
                    divId: 'chatbotize-widget',
                    divWithIdAlreadyExists: false,
                    cssClass: '',
                },
            },
        };
    },

    mounted() {
        zowieFormInit({
            scriptSrc: this.scriptSrc || DEFAULT_ZOWIE_EMBEDDED_SRC,
            nodeId: this.divId,
            instanceId: this.instanceId,
            requestWebPush: false,
            closable: this.closable,
            sessionTimeout: parseInt(this.sessionTimeout, 10),
            content: {
                sessionTerminatedText: this.sessionTerminatedText,
                sessionTerminatedButtonCaption: this
                    .sessionTerminatedButtonCaption,
                endChatQuestion: this.endChatQuestion,
                endChatButtonYesCaption: this.endChatButtonYesCaption,
                endChatButtonNoCaption: this.endChatButtonNoCaption,
                endChatButtonCaption: this.endChatButtonCaption,
                downloadTranscriptButtonCaption: this
                    .downloadTranscriptButtonCaption,
            },
        });
    },
};
</script>

<style scoped>
.zowie-form {
    @apply w-full h-full;
}
</style>
