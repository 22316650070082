<template>
    <MarketingDiscountBanner v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingDiscountBanner from '@organisms/MarketingDiscountBanner/MarketingDiscountBanner';
import { THEMES } from '@types/MarketingDiscountBanner';

export default {
    name: 'CmsMarketingDiscountBanner',

    components: {
        MarketingDiscountBanner,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingDiscountBanner),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    description: '',
                    productsButtonLabel: '',
                    productsUrl: '',
                    rulesHeading: '',
                    rulesContent: '',
                    discountCode: '',
                    theme: '',
                    backgroundColor: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
            },
        };
    },
};
</script>
