<template>
    <BaseMessage v-bind="propsToBind" :class="cssClass">
        <template #default>
            <PageBuilderInterpreter :components-json="slotDefault" />
        </template>
        <template v-if="slotAdditionalText.length > 0" #additional-text>
            <PageBuilderInterpreter :components-json="slotAdditionalText" />
        </template>
        <template v-if="slotTooltip.length > 0" #tooltip>
            <PageBuilderInterpreter :components-json="slotTooltip" />
        </template>
    </BaseMessage>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import BaseMessage from '@atoms/BaseMessage/BaseMessage';
import { TYPES, TYPE_NEUTRAL } from '@types/Message';

export default {
    name: 'CmsBaseMessage',

    components: {
        BaseMessage,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        slotAdditionalText: {
            type: String,
            default: JSON.stringify(''),
        },

        slotTooltip: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(BaseMessage),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                    'additional-text': '',
                    tooltip: '',
                },

                props: {
                    type: TYPE_NEUTRAL,
                    isMobile: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                type: Object.values(TYPES),
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                isMobile: {
                    disabled: true,
                },
            },
        };
    },
};
</script>
