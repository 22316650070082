import { buildCategories } from '@analytics-assets/build-item';

export default class SyneriseDataLayerProduct {
    constructor({
        product = {},
        recommendationType = '',
        pageType = '',
        position,
    }) {
        this.product = product;
        this.recommendationType = recommendationType;
        this.position = position;
        this.pageType = pageType;
    }

    static buildResult(data) {
        const {
            item_id = '',
            item_name = '',
            item_brand = '',
            price = '',
            quantity = '',
            recommendationType = '',
            position,
            category = '',
            pageType = '',
        } = data;

        const categories = buildCategories(
            category.slice(category.indexOf('/') + 1)
        );

        const result = {
            item_id,
            item_name,
            item_brand,
            price,
            quantity,
            ...categories,
            item_list_name: pageType,
            item_list_id: recommendationType,
            list: recommendationType,
        };

        if (Number.isInteger(position)) {
            result.index = position + 1;
        }

        return result;
    }

    build() {
        const {
            brandName,
            name,
            sku,
            price,
            quantity,
            categories: [category = ''],
        } = this.product;

        const data = {
            item_id: sku,
            item_name: name,
            price: price.promotional.amount,
            item_brand: brandName,
            quantity,
            category,
            recommendationType: this.recommendationType,
            position: this.position,
            pageType: this.pageType,
        };

        return SyneriseDataLayerProduct.buildResult(data);
    }
}
