<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppBenefitListV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotHeader: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotListItem: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotVisibleRules: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        tag: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    header: '',
                    listItem: '',
                    visibleRules: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotHeader: this.slotHeader ? JSON.parse(this.slotHeader) : '',
                slotListItem: this.slotListItem
                    ? JSON.parse(this.slotListItem)
                    : '',

                slotVisibleRules: this.slotVisibleRules
                    ? JSON.parse(this.slotVisibleRules)
                    : '',
            };
        },
    },
};
</script>
