<template>
    <TextButtonWithIcon
        :tabindex="tabIndex"
        :theme="THEME_PURE"
        :class="{ disabled, selected: isSelected, 'with-icon': withIcon }"
        class="base-tag"
        @click.native="changeState()"
    >
        <template #left>
            <slot name="left" />
        </template>
        <slot name="content" />
        <template #right>
            <SvgIcon v-if="withIcon" width="16px" height="16px">
                <Close />
            </SvgIcon>
        </template>
    </TextButtonWithIcon>
</template>

<script>
import { THEME_PURE } from '@types/Button';

import Close from '@static/icons/20px/close.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import TextButtonWithIcon from '@atoms/TextButtonWithIcon/TextButtonWithIcon';

export default {
    name: 'BaseTag',

    components: {
        TextButtonWithIcon,
        SvgIcon,
        Close,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },

        withIcon: {
            type: Boolean,
            default: true,
        },

        selected: {
            type: Boolean,
            default: false,
        },

        setIsSelectedOnChange: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isSelected: this.selected,
        };
    },

    computed: {
        tabIndex() {
            return this.disabled ? '-1' : '0';
        },
    },

    watch: {
        selected(isSelected) {
            this.isSelected = isSelected;
        },
    },

    beforeCreate() {
        this.THEME_PURE = THEME_PURE;
    },

    methods: {
        changeState() {
            if (!this.withIcon) {
                if (this.setIsSelectedOnChange) {
                    this.isSelected = !this.isSelected;
                }

                this.$emit('changed-state');
            } else {
                this.$emit('remove-tag');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/focus-visible.scss';

.base-tag {
    @apply rounded-16 leading-s text-s text-gray2 border-2 border-transparent;
    @apply font-semibold bg-gray8 px-12p py-1 justify-center inline-flex items-center;

    @include focusOutlineNone();

    &:hover {
        @apply bg-gray5;
    }

    &:hover,
    &:active,
    &:focus {
        @apply no-underline;
    }

    &.selected {
        @apply bg-gray2 text-light;
    }

    &.disabled {
        @apply bg-gray8 text-gray4 pointer-events-none;
    }

    &.with-icon {
        @apply bg-gray8 border-gray2;

        &:hover,
        &:active,
        &:focus {
            @apply bg-gray5;
        }
    }
}
</style>
