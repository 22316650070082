<template>
    <MarketingCelebritySliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingCelebritySliderSlide from '@organisms/MarketingCelebritySlider/MarketingCelebritySliderSlide';

export default {
    name: 'CmsMarketingCelebritySliderSlide',

    components: {
        MarketingCelebritySliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingCelebritySliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    title: '',
                    content: '',
                    imageUrl: '',
                    photoHeading: '',
                    photoSubHeading: '',
                    avatarUrl: '',
                    customCampaignId: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
