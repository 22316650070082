<template>
    <SponsoredSliderWrapper
        v-bind="propsToBind"
        :class="cssClass"
        :placement-page-name="HOME_PAGE_PLACEMENT_PAGE_NAME"
    />
</template>

<script>
import { HOME_PAGE_PLACEMENT_PAGE_NAME } from '@configs/synerise';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import SponsoredSliderWrapper from '@organisms/SponsoredSlider/SponsoredSliderWrapper';

export default {
    name: 'CmsSponsoredSliderWrapper',

    components: {
        SponsoredSliderWrapper,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        heading: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                },
            },
        };
    },

    beforeCreate() {
        this.HOME_PAGE_PLACEMENT_PAGE_NAME = HOME_PAGE_PLACEMENT_PAGE_NAME;
    },
};
</script>
