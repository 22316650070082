<template>
    <div />
</template>

<script>
export default {
    name: 'CmsAppEobHeaderMarketingBar',

    /* eslint-disable vue/no-unused-properties */
    props: {
        name: {
            type: String,
            default: '',
        },

        tag: {
            type: String,
            default: '',
        },

        text: {
            type: String,
            default: '',
        },

        deepLink: {
            type: String,
            default: '',
        },

        textColor: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    name: '',
                    tag: '',
                    text: '',
                    deepLink: '',
                    textColor: '',
                    backgroundColor: '',
                },
            },
        };
    },
};
</script>
