var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"side-menu"},[(_vm.heading)?_c('BaseHeading',{staticClass:"heading",attrs:{"theme":"h4"}},[_vm._v("\n        "+_vm._s(_vm.heading)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.shouldShowDropdown)?_c('div',{staticClass:"dropdown-wrapper"},[_c('div',{staticClass:"desktop-placeholder",style:({ height: _vm.desktopPlaceholderHeight })}),_vm._v(" "),_c('Dropdown',{staticClass:"items-dropdown",class:{ default: !_vm.currentLinkLabel },attrs:{"value":_vm.selectedLabel.name,"options":_vm.menuOptions},scopedSlots:_vm._u([{key:"selected",fn:function(){return [_c('div',{staticClass:"dropdown-selected"},[(_vm.selectedLabel.logo)?_c('SvgIcon',{attrs:{"width":_vm.selectedLabel.logo.width,"height":_vm.selectedLabel.logo.height,"stroke-color":"none"}},[_c(_vm.selectedLabel.logo.svg,{tag:"component"})],1):_vm._e(),_vm._v(" "),(
                            !_vm.selectedLabel.logo ||
                            _vm.selectedLabel.showNameAndLogo
                        )?_c('span',[_vm._v("\n                        "+_vm._s(_vm.selectedLabel.name)+"\n                    ")]):_vm._e(),_vm._v(" "),(_vm.selectedLabel.showBadgeNew)?_c('Badge',[_vm._v("\n                        "+_vm._s(_vm.$t('New'))+"\n                    ")]):_vm._e()],1)]},proxy:true},{key:"option",fn:function({
                    option: {
                        path,
                        name,
                        logo,
                        showNameAndLogo,
                        showBadgeNew,
                    },
                }){return [_c('ButtonComponent',{staticClass:"text-link button-option",attrs:{"variant":_vm.PRIMARY_VARIANT,"to":path},nativeOn:{"click":function($event){return _vm.$emit('click-link', { path })}},scopedSlots:_vm._u([(logo)?{key:"icon",fn:function(){return [(logo)?_c('SvgIcon',{attrs:{"width":logo.width,"height":logo.height,"stroke-color":"none"}},[_c(logo.svg,{tag:"component"})],1):_vm._e()]},proxy:true}:null],null,true)},[_vm._v(" "),(!logo || showNameAndLogo)?[_vm._v("\n                        "+_vm._s(name)+"\n                    ")]:_vm._e(),_vm._v(" "),(showBadgeNew)?_c('Badge',{attrs:{"variant":_vm.BADGE_NEW}},[_vm._v("\n                        "+_vm._s(_vm.$t('New'))+"\n                    ")]):_vm._e()],2)]}}],null,false,3287351)})],1):_c('div',{staticClass:"items-wrapper"},[_c('div',{staticClass:"items"},_vm._l((_vm.menuOptions),function({
                        path,
                        name,
                        logo,
                        showNameAndLogo = false,
                        showBadgeNew = false,
                    },index){return _c('SideMenuItem',{key:index,attrs:{"path":path,"name":name,"logo":logo,"show-icon-with-label":showNameAndLogo,"show-badge-new":showBadgeNew},nativeOn:{"click":function($event){return _vm.$emit('click-link', { path })}}})}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }