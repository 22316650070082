import spacing from '@theme/resources/variables/spacing.json';

export function clearTags(text, allowedTags = []) {
    const pattern = allowedTags.length
        ? `(</?(?:${allowedTags.join('|')})/?>)|<[^>]+>`
        : '(</?[^>]+>)';
    const replaceString = allowedTags.length ? '$1' : '';
    const tagsRegExp = new RegExp(pattern, 'gi');

    return text.replace(tagsRegExp, replaceString);
}

export function generateSpacingClasses(prefix) {
    const spacingClasses = Object.entries(spacing).map(([suffix, value]) => {
        const hasMinus = suffix[0] === '-';
        const prefixValue = hasMinus ? `-${prefix}` : prefix;
        const suffixValue = hasMinus ? suffix.substr(1) : suffix;

        return {
            label: value,
            value: `${prefixValue}-${suffixValue}`,
        };
    });

    return [...[{ label: 'brak', value: '' }], ...spacingClasses];
}
