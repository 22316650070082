<template>
    <section class="marketing-discount-banner-heading" :class="theme">
        <div class="column-heading">
            <BaseHeading class="heading">
                {{ heading }}
            </BaseHeading>
        </div>

        <div class="column-rules">
            <div
                class="rules-click-container"
                @click="$emit('rules-info-click')"
            >
                <span class="rules-text">{{ $t('Promotion rules') }}</span>
                <SvgIcon width="24px" height="24px" class="rules-icon">
                    <Info />
                </SvgIcon>
            </div>
        </div>
    </section>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import Info from '@static/icons/24px/info.svg?inline';
import { THEMES } from '@types/MarketingDiscountBanner';

export default {
    name: 'MarketingDiscountBannerHeading',

    components: {
        BaseHeading,
        SvgIcon,
        Info,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: value => Object.values(THEMES).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-discount-banner-heading {
    @apply flex items-start justify-between w-full;

    &.light {
        .rules-text {
            @apply text-gray1;
        }
    }

    &.dark {
        .heading {
            @apply text-light;
        }

        .rules-text {
            @apply text-light;
        }

        .rules-icon {
            @apply text-light;
        }
    }

    .column-rules {
        @apply flex justify-end ml-3;
    }

    .heading {
        @apply text-l leading-l;
    }

    .rules-click-container {
        @apply cursor-pointer inline-flex whitespace-nowrap;
    }

    .rules-text {
        @apply hidden mr-12p;
    }

    @screen lg {
        .column-heading {
            @apply w-2/3;
        }

        .column-rules {
            @apply w-1/3 ml-0;
        }

        .heading {
            @apply text-xl leading-xl;
        }

        .rules-text {
            @apply inline;
        }
    }
}
</style>
