<template>
    <section
        v-if="hasAllRequiredProps"
        class="marketing-banner"
        :class="[theme]"
    >
        <ContainerContent class="banner-container">
            <BaseLink :path="pageUrl" class="wrapper">
                <BasePicture
                    :width="image.width"
                    :height="image.height"
                    :src="image.url"
                    :alt="image.alt"
                    :sources="image.sources"
                    :sources-with-media="sourcesWithMedia"
                    :is-lazy="true"
                />
                <div class="headings-wrapper">
                    <BaseParagraph class="sub-heading">
                        {{ subHeading }}
                    </BaseParagraph>
                    <BaseHeading tag="h2" class="heading">
                        {{ heading }}
                    </BaseHeading>
                    <ButtonLarge :variant="BUTTON_VARIANT" class="button">
                        {{ buttonLabel }}
                    </ButtonLarge>
                </div>
            </BaseLink>
        </ContainerContent>
    </section>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
    IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
    IMAGE_TYPE_MARKETING_BANNER_450w_450h,
    IMAGE_TYPE_MARKETING_BANNER_767w_450h,
    MARKETING_BANNER_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';
import { THEMES } from '@types/MarketingBanner';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BasePicture from '@atoms/BasePicture/BasePicture';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingBanner',

    components: {
        BasePicture,
        BaseLink,
        BaseHeading,
        BaseParagraph,
        ContainerContent,
        ButtonLarge,
    },

    props: {
        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: value => Object.values(THEMES).includes(value),
        },

        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        bannerUrl: {
            type: String,
            required: true,
        },

        imageUrl450x450: {
            type: String,
            required: true,
        },

        imageUrl767x450: {
            type: String,
            required: true,
        },

        imageUrl1532x1149: {
            type: String,
            required: true,
        },
    },

    computed: {
        image() {
            const image450x450 = this.$imaginator.getImage(
                this.imageUrl450x450,
                'marketing-banner',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES.eob_marketing_banner_450w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_450w_450h]
            );

            const image767x450 = this.$imaginator.getImage(
                this.imageUrl767x450,
                'marketing-banner',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES.eob_marketing_banner_767w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_MARKETING_BANNER_767w_450h]
            );

            const result = this.$imaginator.getImage(
                this.imageUrl1532x1149,
                'marketing-banner',
                `${this.heading} ${this.subHeading}`,
                IMAGE_TYPES.eob_marketing_banner_1532w_450h.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [
                    IMAGE_TYPE_MARKETING_BANNER_1532w_450h,
                    IMAGE_TYPE_MARKETING_BANNER_1152w_450h,
                ]
            );

            [image767x450, image450x450].forEach(image => {
                Object.entries(image.sources).forEach(([type, source]) => {
                    result.sources[type] = result.sources[type].concat(source);
                });
            });

            return result;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },

        hasAllRequiredProps() {
            return (
                !!this.subHeading &&
                !!this.heading &&
                !!this.buttonLabel &&
                !!this.imageUrl450x450 &&
                !!this.imageUrl767x450 &&
                !!this.imageUrl1532x1149
            );
        },

        pageUrl() {
            return this.bannerUrl;
        },
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_LARGE_VARIANTS.PRIMARY_ACCENT;
    },

    methods: {
        getMediaForSource(source) {
            const mediaMap = {
                450: '(max-width: 450px)',
                767: '(min-width: 451px)',
                1152: '(min-width: 768px)',
                1532: '(min-width: 1153px)',
            };

            return mediaMap[source.width] || '';
        },
    },
};
</script>

<style lang="scss" scoped>
$banner-height: 450px;
$banner-light-background-color: rgba($tailwindcss-colors-light, 0.75);

.marketing-banner {
    .banner-container {
        @apply px-0;
    }

    .wrapper {
        @apply flex w-full relative overflow-hidden;
        height: $banner-height;
    }

    &:deep(.base-picture) {
        .image {
            @apply max-w-none max-h-none absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .headings-wrapper {
        @apply absolute left-0 bottom-0 w-full p-3;

        background: $banner-light-background-color;
    }

    .sub-heading {
        @apply block;
    }

    .heading {
        @apply block text-xl leading-xl mt-2;
    }

    .button {
        @apply mt-3;
    }

    @screen md {
        &:deep(.base-picture) {
            .image {
                @apply left-auto right-0 transform-none;
            }
        }

        .headings-wrapper {
            @apply h-full flex flex-col justify-center items-start p-5;

            width: 360px;
        }
    }

    @screen lg {
        .headings-wrapper {
            width: 460px;
        }
    }
}
</style>
