<template>
    <section :style="styles" :class="theme" class="marketing-slider-section">
        <ContainerContent class="marketing-slider-section-container">
            <div class="wrapper">
                <div class="top-section">
                    <div class="left">
                        <BaseHeading v-if="smallTitle" class="small-title">
                            {{ smallTitle }}
                        </BaseHeading>

                        <BaseHeading
                            v-if="sliderTitle"
                            theme="h2"
                            class="slider-title"
                        >
                            {{ sliderTitle }}
                        </BaseHeading>
                    </div>

                    <ButtonSmall
                        v-if="showAllLink"
                        :variant="buttonVariant"
                        :to="showAllLink"
                        class="show-all-button-desktop"
                    >
                        {{ showAllCopy }}
                    </ButtonSmall>
                </div>

                <slot @error="handleError()" />

                <ButtonSmall
                    v-if="showAllLink"
                    :to="showAllLink"
                    :variant="buttonVariant"
                    class="show-all-button-mobile"
                >
                    {{ showAllCopy }}
                </ButtonSmall>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { THEMES } from '@types/MarketingSection';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';

export default {
    name: 'MarketingSliderSection',

    components: {
        ContainerContent,
        BaseHeading,
        ButtonSmall,
    },

    props: {
        smallTitle: {
            type: String,
            default: '',
        },

        sliderTitle: {
            type: String,
            default: '',
        },

        showAllLink: {
            type: String,
            default: '',
        },

        showAllCopy: {
            type: String,
            default: 'Show all',
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        backgroundColor: {
            type: String,
            default: '',
        },
    },

    computed: {
        buttonVariant() {
            return this.theme === THEMES.THEME_LIGHT
                ? BUTTON_SMALL_VARIANTS.PRIMARY
                : BUTTON_SMALL_VARIANTS.PRIMARY_ALTERNATIVE;
        },

        styles() {
            const { backgroundColor } = this;

            return backgroundColor ? { backgroundColor } : null;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-slider-section {
    @apply w-full relative;

    &::after {
        @apply block w-full h-1 bg-light;
        content: '';
    }

    &.dark {
        @apply bg-gray1;

        &::after {
            @apply bg-dark;
        }

        .small-title,
        .slider-title {
            @apply text-light;
        }
    }

    .marketing-slider-section-container {
        @apply py-5;
    }

    .wrapper {
        @apply w-full;
    }

    .top-section {
        @apply mb-3 pr-3;
    }

    .small-title {
        @apply block text-s font-book tracking-wide2 uppercase;
    }

    .slider-title {
        @apply block;
    }

    .show-all-button-mobile {
        @apply mt-1 mx-3;
        width: calc(100% - #{$tailwindcss-spacing-5});
    }

    .show-all-button-desktop {
        @apply hidden;
    }

    @screen md {
        .top-section {
            @apply flex justify-between;
        }

        .show-all-button-mobile {
            @apply hidden;
        }

        .show-all-button-desktop {
            @apply inline-flex w-auto m-0 self-end;
        }
    }

    @screen lg {
        .marketing-slider-section-container {
            @apply py-7;
        }

        .top-section {
            @apply pr-5 mb-4 mx-auto;
            max-width: #{theme('container.maxWidth')};
        }

        .small-title {
            @apply mb-1 tracking-wide1;
        }

        .slider-title {
            @apply px-0;
        }
    }
}
</style>
