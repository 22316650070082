<template>
    <section v-if="hasAllRequiredValues" class="marketing-blog">
        <ContainerContent class="marketing-blog-container">
            <div class="wrapper">
                <div class="picture-container">
                    <BasePicture
                        class="image"
                        :width="image.width"
                        :height="image.height"
                        :src="image.url"
                        :alt="image.alt"
                        :sources="image.sources"
                        :is-lazy="true"
                    />
                </div>
                <div
                    class="content-container"
                    :class="{ 'with-slider': hasSlider }"
                >
                    <MarketingBlogContent
                        class="content"
                        :badge="badge"
                        :date="date"
                        :title="title"
                        :text="text"
                        :link="link"
                        :has-slider="hasSlider"
                    />

                    <RecommendedProductsSliderWrapper
                        v-if="customCampaignId"
                        class="products-slider"
                        :recommendation-type="TYPE_CUSTOM"
                        :custom-campaign-id="customCampaignId"
                        :items-count="ITEMS_COUNT"
                    />
                    <CustomProductsSimpleSliderWrapper
                        v-else-if="productSkusArray"
                        class="products-slider"
                        :items-count="ITEMS_COUNT"
                        :product-skus="productSkusArray"
                    />
                </div>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import { TYPE_CUSTOM } from '@types/Synerise';
import {
    SOURCE_IMAGE_FORMATS,
    DEFAULT_IMAGE_FORMAT,
    MARKETING_BLOG_IMAGE_TYPES as IMAGE_TYPES,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';
import MarketingBlogContent from '@organisms/MarketingBlog/MarketingBlogContent';
import RecommendedProductsSliderWrapper from '@organisms/RecommendedProductsSliderWrapper/RecommendedProductsSliderWrapper';

import { parseArrayString } from '@modules/page-builder/helpers/component';

const VERTICAL_IMAGES = [
    IMAGE_TYPES.eob_marketing_blog_786w_1048h.name,
    IMAGE_TYPES.eob_marketing_blog_492w_656h.name,
    IMAGE_TYPES.eob_marketing_blog_328w_328h.name,
];

const SQUARE_IMAGES = [
    IMAGE_TYPES.eob_marketing_blog_786w_786h.name,
    IMAGE_TYPES.eob_marketing_blog_492w_492h.name,
    IMAGE_TYPES.eob_marketing_blog_328w_328h.name,
];

export default {
    name: 'MarketingBlog',

    components: {
        BasePicture,
        ContainerContent,
        CustomProductsSimpleSliderWrapper,
        MarketingBlogContent,
        RecommendedProductsSliderWrapper,
    },

    props: {
        badge: {
            type: String,
            required: true,
        },

        date: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        text: {
            type: String,
            required: true,
        },

        link: {
            type: String,
            required: true,
        },

        imageUrl: {
            type: String,
            required: true,
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        productSkus: {
            type: String,
            default: '',
        },
    },

    computed: {
        productSkusArray() {
            return parseArrayString(this.productSkus);
        },

        image() {
            const { hasSlider } = this;
            const imageType = hasSlider
                ? IMAGE_TYPES.eob_marketing_blog_786w_1048h.name
                : IMAGE_TYPES.eob_marketing_blog_786w_786h.name;
            const sourceImageTypes = hasSlider
                ? VERTICAL_IMAGES
                : SQUARE_IMAGES;

            return this.$imaginator.getImage(
                this.imageUrl,
                'marketing-blog',
                this.title,
                imageType,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                sourceImageTypes
            );
        },

        hasSlider() {
            return !!this.customCampaignId || !!this.productSkus;
        },

        hasAllRequiredValues() {
            return (
                this.badge &&
                this.date &&
                this.title &&
                this.text &&
                this.link &&
                this.imageUrl
            );
        },
    },

    beforeCreate() {
        this.TYPE_CUSTOM = TYPE_CUSTOM;
        this.ITEMS_COUNT = 3;
    },
};
</script>

<style lang="scss" scoped>
$slider-margin-left-mobile: $tailwindcss-spacing-3;

.marketing-blog {
    .wrapper {
        @apply w-full;
    }

    .products-slider {
        @apply mt-5;
    }

    &:deep(.slider-container-content) {
        margin-left: -$slider-margin-left-mobile;
    }

    @screen md {
        .wrapper {
            @apply flex;
        }

        .marketing-blog-container {
            @apply px-5;
        }

        .picture-container {
            @apply w-1/2;
        }

        .content-container {
            @apply w-1/2 mt-4;

            .content {
                @apply ml-8;
            }

            &.with-slider {
                @apply mt-0 flex flex-col justify-center;

                .content {
                    @apply ml-5;
                }
            }
        }

        &:deep(.slider-container-content) {
            @apply ml-0;
        }
    }

    @screen container {
        .content-container {
            @apply mt-8;
        }
    }
}
</style>
