<template>
    <section v-if="hasAllRequiredValues" class="marketing-influencer-slider">
        <ContainerContent class="marketing-influencer-container">
            <div class="wrapper">
                <MarketingInfluencerSliderHeadings
                    class="headings"
                    :heading="heading"
                    :sub-heading="subHeading"
                />

                <SimpleSlider
                    :items-count="2"
                    class="main-influencer-slider"
                    slider-class="influencer-products-slider"
                    :has-scrollbar="false"
                >
                    <template #slides>
                        <slot />
                    </template>
                </SimpleSlider>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import MarketingInfluencerSliderHeadings from './MarketingInfluencerSliderHeadings';

export default {
    name: 'MarketingInfluencerSlider',

    components: {
        ContainerContent,
        MarketingInfluencerSliderHeadings,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },

    computed: {
        hasAllRequiredValues() {
            return this.heading && this.subHeading;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-influencer-slider {
    .wrapper {
        @apply w-full;
    }

    .container-content {
        @apply pr-0;
    }

    &:deep() {
        .main-influencer-slider {
            @apply mt-3;

            .navigation-button {
                @apply hidden z-2;
            }

            .container-content {
                @apply px-0;
            }
        }
    }

    @screen md {
        .container-content {
            @apply pr-3;
        }

        &:deep() {
            .main-influencer-slider {
                .influencer-products-slider {
                    &.wrapper {
                        @apply py-7;
                    }
                }

                .navigation-button {
                    @apply flex;

                    &:disabled {
                        @apply hidden;
                    }
                }
            }
        }
    }

    @screen lg {
        .marketing-influencer-container {
            @apply px-5;
        }
    }
}
</style>
