<template>
    <div />
</template>

<script>
export default {
    name: 'CmsAppEobBoxBenefit',

    /* eslint-disable vue/no-unused-properties */
    props: {
        firstText: {
            type: String,
            default: '',
        },

        secondText: {
            type: String,
            default: '',
        },

        smallText: {
            type: String,
            default: '',
        },

        largeText: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: '',
        },

        deepLinkSku: {
            type: String,
            default: '',
        },

        defaultValue: {
            type: String,
            default: '',
        },

        idSynerise: {
            type: String,
            default: '',
        },

        landingPage: {
            type: String,
            default: '',
        },

        webview: {
            type: String,
            default: '',
        },

        boxColor: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    firstText: '',
                    secondText: '',
                    smallText: '',
                    largeText: '',
                    icon: '',
                    deepLinkSku: '',
                    defaultValue: '',
                    idSynerise: '',
                    landingPage: '',
                    webview: '',
                    boxColor: '',
                },
            },

            propsCustomFormConfig: {
                firstText: {
                    max: 48,
                },

                secondText: {
                    max: 48,
                },
            },
        };
    },
};
</script>
