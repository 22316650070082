<template>
    <MarketingBannerPromoLink v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import { THEMES } from '@types/MarketingSection';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingBannerPromoLink from '@molecules/MarketingPromoBannerLink/MarketingBannerPromoLink';

export default {
    name: 'CmsMarketingBannerPromoLink',

    components: {
        MarketingBannerPromoLink,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketingBannerPromoLink),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                theme: Object.values(THEMES),
            },
        };
    },
};
</script>
