<template>
    <div class="responsive-picture" :class="{ 'align-center': alignCenter }">
        <BasePicture
            class="image"
            :width="image.width"
            :height="image.height"
            :src="image.url"
            :alt="image.alt"
            :sources="image.sources"
            :sources-with-media="sourcesWithMedia"
            :is-lazy="true"
        />
    </div>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    MAGENTO_IMAGE_TYPES as IMAGE_TYPES,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'ResponsivePicture',

    components: {
        BasePicture,
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        mobileUrl: {
            type: String,
            default: '',
        },

        alt: {
            type: String,
            default: '',
        },

        alignCenter: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        image() {
            const result = this.$imaginator.getImage(
                this.url,
                'magento-image-desktop',
                this.alt,
                IMAGE_TYPES.mag.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );

            const { mobileUrl } = this;

            if (!mobileUrl) {
                return result;
            }

            const mobileImage = this.$imaginator.getImage(
                mobileUrl,
                'magento-image',
                this.alt,
                IMAGE_TYPES.mag.name,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );

            Object.entries(mobileImage.sources).forEach(([type, source]) => {
                result.sources[type] = result.sources[type].concat(source);
            });

            return result;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach((source, index) => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.mobileUrl
                            ? this.getMediaForSource(index)
                            : '',
                    });
                });
            });

            return result;
        },
    },

    methods: {
        getMediaForSource(index) {
            const mediaMap = {
                0: '(min-width: 1024px)',
                1: '(max-width: 1023px)',
            };

            return mediaMap[index] || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.responsive-picture {
    @apply flex justify-start;

    &.align-center {
        @apply justify-center;
    }

    .image {
        @apply inline-block w-auto;
    }
}
</style>
