<template>
    <TextButton class="text-button-with-icon">
        <span v-if="$slots.left" class="left">
            <slot name="left" />
        </span>
        <slot />
        <span v-if="$slots.right" class="right">
            <slot name="right" />
        </span>
    </TextButton>
</template>

<script>
import TextButton from '@atoms/TextButton/TextButton';

export default {
    name: 'TextButtonWithIcon',

    components: {
        TextButton,
    },
};
</script>

<style lang="scss" scoped>
.text-button-with-icon {
    @apply inline-flex items-center justify-start text-left text-s leading-s shrink-0;

    .left {
        @apply mr-2;
    }

    .right {
        @apply ml-2;
    }
}
</style>
