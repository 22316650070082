<template>
    <div>
        <pre>{{ preview }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppBannerV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        bannerMediaLink: {
            type: Object,
            default: () => ({
                light: {
                    type: '',
                    url: '',
                },

                dark: {
                    type: '',
                    url: '',
                },
            }),

            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        deeplink: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        deeplinkInputTitle: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        tag: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotHeader: {
            type: String,
            default: JSON.stringify(''),
        },

        // eslint-disable-next-line vue/no-unused-properties
        slotVisibleRules: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                bannerMediaLink: {
                    hasMediaLinkTheme: true,
                    required: true,
                },

                deeplink: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    bannerMediaLink: {
                        light: {
                            type: '',
                            url: '',
                        },

                        dark: {
                            type: '',
                            url: '',
                        },
                    },
                },

                slots: {
                    header: '',
                    visibleRules: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },

    computed: {
        preview() {
            return {
                ...this.propsToBind,
                slotHeader: this.slotHeader ? JSON.parse(this.slotHeader) : '',
                slotVisibleRules: this.slotVisibleRules
                    ? JSON.parse(this.slotVisibleRules)
                    : '',
            };
        },
    },
};
</script>
