<template>
    <button
        :type="type"
        :disabled="$attrs.disabled"
        :class="['button-icon', color, theme, size]"
        :tabindex="$attrs.disabled ? '-1' : $attrs.tabindex || null"
    >
        <slot />
    </button>
</template>

<script>
import { COLORS, THEMES, SIZES } from '@types/ButtonIcon';

import { checkIfExistsInValuesMap } from '@assets/props';

export default {
    name: 'ButtonIcon',

    props: {
        type: {
            type: String,
            required: false,
            default: 'button',
        },

        color: {
            type: String,
            default: COLORS.COLOR_GREEN,
            validator: checkIfExistsInValuesMap(COLORS, true),
        },

        theme: {
            type: String,
            default: THEMES.THEME_PURE,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        size: {
            type: String,
            default: SIZES.SIZE_NORMAL,
            validator: checkIfExistsInValuesMap(SIZES, true),
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/focus-visible.scss';

@mixin boxShadow($width, $color: false) {
    @if $color {
        box-shadow: inset 0 0 0 $width $color;
    } @else {
        // if color is absent then it's inherited from text color
        box-shadow: inset 0 0 0 $width;
    }
}

@mixin boxShadowBase($color: false) {
    @include boxShadow(2px, $color);
}

@mixin boxShadowHover($color: false) {
    @include boxShadow(3px, $color);
}

@mixin hoverAndActive() {
    &:hover,
    &:active,
    &.is-active {
        @content;
    }
}

@mixin iconColor($iconColor, $iconHover, $iconDisabled) {
    color: $iconColor;

    @include hoverAndActive {
        color: $iconHover;
    }

    &[disabled] {
        color: $iconDisabled;
    }
}

@mixin backgroundColor($bg, $bgHover, $bgDisabled) {
    background: $bg;

    @include hoverAndActive {
        background: $bgHover;
    }

    &[disabled] {
        background: $bgDisabled;
    }
}

@mixin border($border, $borderHover, $borderDisabled) {
    @include boxShadowBase($border);
    box-shadow: inset 0 0 0 2px $border;

    @include hoverAndActive {
        @include boxShadowHover($borderHover);
    }

    &[disabled] {
        @include boxShadowBase($borderDisabled);
    }
}

.button-icon {
    @apply min-w-xxl font-bold flex items-center justify-center min-h-xxl
           no-underline outline-none cursor-pointer;

    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

    @include focusOutlineNone();

    &[disabled] {
        @apply pointer-events-none;
    }

    &.small {
        @apply text-s px-2 leading-s min-h-xl;
    }

    &.primary {
        @include boxShadowBase(theme('colors.green2'));

        @include hoverAndActive {
            @include boxShadowHover(theme('colors.green2'));
        }

        &[disabled] {
            @include boxShadowBase(theme('colors.green4'));
        }
    }

    &.secondary {
        @include boxShadowBase(theme('colors.gray5'));

        @include hoverAndActive {
            @include boxShadowHover(theme('colors.gray4'));
        }

        &[disabled] {
            @include boxShadowBase(theme('colors.gray4'));
        }
    }

    &.primary,
    &.secondary {
        @include hoverAndActive {
            background: theme('colors.gray8');
        }
    }

    &.tertiary {
        @include hoverAndActive {
            background: theme('colors.gray8');
        }
    }

    &.green {
        &.primary {
            @include backgroundColor(
                theme('colors.light'),
                theme('colors.green5'),
                theme('colors.transparent')
            );
        }

        &.primary,
        &.secondary,
        &.tertiary,
        &.pure {
            @include iconColor(
                theme('colors.light'),
                theme('colors.gray8'),
                theme('colors.gray3')
            );
        }
    }

    &.white {
        &.primary {
            @include backgroundColor(
                theme('colors.light'),
                theme('colors.gray8'),
                theme('colors.transparent')
            );

            @include iconColor(
                theme('colors.gray2'),
                theme('colors.gray1'),
                theme('colors.gray1')
            );
        }
    }
}
</style>
