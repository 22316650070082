<template>
    <SimpleSlider
        :theme="theme"
        :full-width="true"
        :class="theme"
        :has-navigation-buttons="hasNavigationButtonsOnTabletOrDesktop"
        class="marketing-slider"
    >
        <template #slides>
            <slot />
        </template>
    </SimpleSlider>
</template>

<script>
import { mapState } from 'vuex';

import { THEMES } from '@types/MarketingSection';

import { checkIfExistsInValuesMap } from '@assets/props';

import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

export default {
    name: 'MarketingSlider',

    components: {
        SimpleSlider,
    },

    props: {
        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        hasNavigationButtons: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState(['isTabletOrDesktop']),

        hasNavigationButtonsOnTabletOrDesktop() {
            return this.hasNavigationButtons ? this.isTabletOrDesktop : false;
        },
    },
};
</script>
