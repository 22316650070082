// eslint-disable-next-line import/prefer-default-export
export const copyToClipboard = value => {
    const tempInput = document.createElement('input');

    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
};
