export const BLOCK_LOADER_SIZE_SMALL = 'small';
export const BLOCK_LOADER_SIZE_BIG = 'big';

export const BLOCK_LOADER_THEME_LIGHT = 'light';
export const BLOCK_LOADER_THEME_DARK = 'dark';

export const BLOCK_LOADER_SIZES = [
    BLOCK_LOADER_SIZE_SMALL,
    BLOCK_LOADER_SIZE_BIG,
];

export const BLOCK_LOADER_THEMES = [
    BLOCK_LOADER_THEME_LIGHT,
    BLOCK_LOADER_THEME_DARK,
];
