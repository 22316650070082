<template>
    <div
        :style="{ backgroundColor }"
        :class="theme"
        class="marketing-banner-promo-link"
    >
        <BaseHeading v-if="title" theme="h3" class="title">
            {{ title }}
        </BaseHeading>

        <BaseParagraph v-if="description" class="description">
            {{ description }}
        </BaseParagraph>

        <BaseButton
            v-if="showAllCopy && showAllLink"
            :color="buttonColor"
            :size="SIZE_SMALL"
            :to="showAllLink"
            class="button"
        >
            {{ showAllCopy }}
        </BaseButton>
    </div>
</template>

<script>
import { dark } from '@theme/resources/variables/colors.json';

import { THEMES } from '@types/MarketingSection';
import { COLOR_BLACK, COLOR_WHITE, SIZE_SMALL } from '@types/Button';

import { checkIfExistsInValuesMap } from '@assets/props';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseButton from '@atoms/BaseButton/BaseButton';

export default {
    name: 'MarketingBannerPromoLink',

    components: {
        BaseHeading,
        BaseParagraph,
        BaseButton,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        showAllCopy: {
            type: String,
            default: '',
        },

        showAllLink: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: dark,
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },
    },

    computed: {
        buttonColor() {
            return this.theme === THEMES.THEME_LIGHT
                ? COLOR_WHITE
                : COLOR_BLACK;
        },
    },

    beforeCreate() {
        this.SIZE_SMALL = SIZE_SMALL;
    },
};
</script>

<style lang="scss" scoped>
$description-line-height: 2.6rem;

.marketing-banner-promo-link {
    @apply px-4 py-4 text-center;

    &.light {
        .title,
        .description {
            @apply text-light;
        }
    }

    .description {
        @apply text-center text-r mt-3;
        line-height: $description-line-height;
    }

    .button {
        @apply text-r leading-r mt-3 h-7;
    }

    @screen lg {
        @apply flex text-left px-5 items-center justify-between;

        .description {
            @apply mt-0 text-left mx-5;
        }

        .button {
            @apply mt-0 flex-none;
        }
    }
}
</style>
