<template>
    <div
        :style="{ background: backgroundColor }"
        class="marketing-hero-block-video"
    >
        <BasePicture
            v-if="hasFallbackImage"
            :sources-with-media="imageSources"
            :src="imageMobile.url"
            :is-lazy="isVideoLazyLoaded"
            :width="
                isMobile
                    ? videoSettingsMobile.width
                    : videoSettingsDesktop.width
            "
            :height="
                isMobile
                    ? videoSettingsMobile.height
                    : videoSettingsDesktop.height
            "
            alt=""
            class="image"
        />
        <IframeVideo
            v-if="isMobileVideoVisible"
            :is-lazy="isVideoLazyLoaded"
            :src="videoSettingsMobile.src"
            :width="videoSettingsMobile.width"
            :height="videoSettingsMobile.height"
            class="video video-mobile"
        />
        <IframeVideo
            v-if="isDesktopVideoVisible"
            :is-lazy="isVideoLazyLoaded"
            :src="videoSettingsDesktop.src"
            :width="videoSettingsDesktop.width"
            :height="videoSettingsDesktop.height"
            class="video video-desktop"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { MEDIA_SIZE } from '@types/MarketingBlocks';
import {
    DEFAULT_IMAGE_FORMAT,
    IMAGE_FORMAT_JPG_EXTENSION,
    IMAGE_FORMAT_WEBP_EXTENSION,
    IMAGE_TYPE_MAGENTO,
    SOURCE_IMAGE_FORMATS,
} from '@types/Image';

import IframeVideo from '@atoms/IframeVideo/IframeVideo';
import BasePicture from '@atoms/BasePicture/BasePicture';

export default {
    name: 'MarketingHeroBlockVideo',

    components: {
        BasePicture,
        IframeVideo,
    },

    inject: {
        backgroundColor: {
            default: 'transparent',
        },
    },

    provide() {
        return {
            backgroundColor: 'transparent',
        };
    },

    props: {
        videoSourceDesktop: {
            type: String,
            required: true,
        },

        videoSourceMobile: {
            type: String,
            required: true,
        },

        imageSourceDesktop: {
            type: String,
            default: '',
        },

        imageSourceMobile: {
            type: String,
            default: '',
        },

        isVideoLazyLoaded: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isMobileVideoVisible: true,
            isDesktopVideoVisible: true,
            isMobileWatcher: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        videoSettingsDesktop() {
            return {
                src: this.getEmbedVideoSrc(this.videoSourceDesktop),
                width: MEDIA_SIZE.desktop.width,
                height: MEDIA_SIZE.desktop.height,
            };
        },

        videoSettingsMobile() {
            return {
                src: this.getEmbedVideoSrc(this.videoSourceMobile),
                width: MEDIA_SIZE.mobile.width,
                height: MEDIA_SIZE.mobile.height,
            };
        },

        imageMobile() {
            return this.$imaginator.getImage(
                this.imageSourceMobile,
                'marketing-hero-block-image',
                '',
                IMAGE_TYPE_MAGENTO,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },

        imageDesktop() {
            return this.$imaginator.getImage(
                this.imageSourceDesktop,
                'marketing-hero-block-image',
                '',
                IMAGE_TYPE_MAGENTO,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS
            );
        },

        hasFallbackImage() {
            return this.imageSourceMobile && this.imageSourceDesktop;
        },

        imageSources() {
            return [
                {
                    media: '(max-width: 1023px)',
                    srcset: this.imageMobile.url,
                    type: IMAGE_FORMAT_WEBP_EXTENSION,
                },
                {
                    media: '(min-width: 1024px)',
                    srcset: this.imageDesktop.url,
                    type: IMAGE_FORMAT_WEBP_EXTENSION,
                },
                {
                    media: '(max-width: 1023px)',
                    srcset: this.imageMobile.url,
                    type: IMAGE_FORMAT_JPG_EXTENSION,
                },
                {
                    media: '(min-width: 1024px)',
                    srcset: this.imageDesktop.url,
                    type: IMAGE_FORMAT_JPG_EXTENSION,
                },
            ];
        },
    },

    mounted() {
        this.isMobileWatcher = this.$watch(
            'isMobile',
            () => {
                this.isMobileVideoVisible = this.isMobile;
                this.isDesktopVideoVisible = !this.isMobile;
            },
            { immediate: true }
        );
    },

    beforeDestroy() {
        this.isMobileWatcher?.();
    },

    methods: {
        getEmbedVideoSrc(src) {
            return `${src}?autoplay=1&loop=1&muted=1&controls=0&autopause=false`;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-hero-block-video {
    @apply relative;

    .image ~ .video {
        @apply absolute left-ui-0 top-ui-0 w-ui-percent-100 h-ui-percent-100;
    }

    .image {
        &:deep(.image) {
            &:not(.loaded) {
                @apply bg-ui-container-transparent-default;
            }
        }
    }

    @screen mobile-and-tablet-only {
        .video-desktop {
            @apply hidden;
        }

        .image {
            @apply w-full h-auto block overflow-hidden;
            aspect-ratio: 640/640;
        }
    }

    @screen lg {
        .video-mobile {
            @apply hidden;
        }

        .image {
            @apply w-full h-auto block overflow-hidden;
            aspect-ratio: 1440/405;
        }
    }
}
</style>
