<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsAppImageGridItemTextV2',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        image: {
            type: Object,
            required: true,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        title: {
            type: String,
            required: true,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        deeplink: {
            type: String,
            required: true,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        deeplinkInputTitle: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        titleColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        backgroundColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },

        // eslint-disable-next-line vue/no-unused-properties
        imageMaskColor: {
            type: Object,
            default: () => ({
                light: '',
                dark: '',
            }),
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                image: {
                    hasImageTheme: true,
                    required: true,
                },

                deeplink: {
                    isUrl: true,
                    required: true,
                },

                titleColor: {
                    hasColorTheme: true,
                },

                backgroundColor: {
                    hasColorTheme: true,
                },

                imageMaskColor: {
                    hasColorTheme: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    image: {
                        light: '',
                        dark: '',
                    },

                    title: '',
                    deeplink: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
