<template>
    <section ref="slider" class="marketing-promo-categories-slider">
        <div class="slider">
            <ButtonIcon
                v-for="(
                    { isDisabled, direction, icon, cssClass }, key
                ) in navigationButtons"
                :key="key"
                :disabled="isDisabled"
                class="nav-button"
                :class="[cssClass]"
                @click.native="onNavigationButtonClick(direction)"
            >
                <slot name="icon" :icon="key">
                    <SvgIcon width="14px" height="14px">
                        <component :is="icon" />
                    </SvgIcon>
                </slot>
            </ButtonIcon>

            <div ref="wrapper" class="wrapper">
                <slot />
            </div>
        </div>
    </section>
</template>

<script>
import debounce from 'lodash.debounce';

import approximatelyEqual from '@assets/approximatelyEqual';

import ArrowLeft from '@static/icons/24px/arrow3-left.svg?inline';
import ArrowRight from '@static/icons/24px/arrow3-right.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import ButtonIcon from '@atoms/ButtonIcon/ButtonIcon';

const SCROLL_DEBOUNCE = 100;
const RESIZE_DEBOUNCE = 500;

export default {
    name: 'MarketingPromoCategoriesSlider',

    components: {
        SvgIcon,
        ButtonIcon,
        ArrowLeft,
        ArrowRight,
    },

    data() {
        return {
            slidesWidth: [],
            currentPosition: 0,
            wrapperScrollWidth: 0,
            wrapperVisibleWidth: 0,
            slideWidth: 0,
            slidesRef: null,
            singleSlide: null,
        };
    },

    computed: {
        navigationButtons() {
            return {
                prev: {
                    isDisabled: this.isBoundedLeft,
                    direction: -1,
                    icon: ArrowLeft,
                    cssClass: 'nav-button-left',
                },

                next: {
                    isDisabled: this.isBoundedRight,
                    direction: 1,
                    icon: ArrowRight,
                    cssClass: 'nav-button-right',
                },
            };
        },

        isBoundedLeft() {
            return approximatelyEqual(this.currentPosition, 0, 5);
        },

        isBoundedRight() {
            return approximatelyEqual(
                this.wrapperScrollWidth - this.wrapperVisibleWidth,
                this.currentPosition,
                5
            );
        },
    },

    mounted() {
        this.init();

        this.attachMutationObserver();

        this.onResizeFn = debounce(this.resizeHandler, RESIZE_DEBOUNCE);
        this.onScrollFn = debounce(this.calcOnScroll, SCROLL_DEBOUNCE);

        this.$refs.wrapper.addEventListener('scroll', this.onScrollFn);
        window.addEventListener('resize', this.onResizeFn, false);
    },

    beforeDestroy() {
        if (this.onResizeFn && this.onScrollFn) {
            this.onResizeFn.cancel();
            this.onScrollFn.cancel();
            this.$refs.wrapper.removeEventListener('scroll', this.onScrollFn);
            window.removeEventListener('resize', this.onResizeFn, false);
            this.onResizeFn = null;
        }
    },

    methods: {
        init() {
            if (this.$refs.wrapper.children.length) {
                if (!this.slidesRef) {
                    this.slidesRef = Array.from(this.$refs.wrapper.children);
                }

                [this.singleSlide] = this.slidesRef;

                this.slideWidth = this.singleSlide.offsetWidth;
            }

            this.calcOnInit();
        },

        resizeHandler() {
            if (!this.onResizeFn) {
                return;
            }

            this.init();
        },

        calcOnInit() {
            this.calcWrapperWidth();
            this.calcSlidesWidth();

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcOnScroll() {
            if (!this.$refs.wrapper) {
                return;
            }

            this.calcCurrentPosition();
            this.calcActiveSlide();
        },

        calcWrapperWidth() {
            this.wrapperScrollWidth = this.$refs.wrapper.scrollWidth;
            this.wrapperVisibleWidth = this.$refs.wrapper.offsetWidth;
        },

        calcSlidesWidth() {
            const childNodes = [...this.$refs.wrapper.childNodes];

            this.slidesWidth = childNodes.map(node => ({
                offsetLeft: node.offsetLeft,
                width: node.offsetWidth,
            }));
        },

        calcActiveSlide() {
            const { slidesWidth, currentPosition, slidesRef } = this;
            const slides = slidesWidth;
            let activeSlideIndex = 0;

            const activeIndex = slides.findIndex(slide => {
                return approximatelyEqual(slide.offsetLeft, currentPosition, 5);
            });

            if (activeIndex !== -1) {
                activeSlideIndex = Math.max(activeIndex, 0);
            }

            if (slidesRef) {
                slidesRef.forEach(child => {
                    child.classList.remove('active');
                });
                slidesRef[activeSlideIndex].classList.add('active');
            }
        },

        calcCurrentPosition() {
            const { scrollLeft = 0 } = this.$refs.wrapper;

            this.currentPosition = scrollLeft;
        },

        onNavigationButtonClick(direction = 1) {
            this.changeSlide(direction);
        },

        changeSlide(direction = 1) {
            const offset = this.slideWidth;

            this.scroll(direction * offset);
        },

        scroll(position = 0) {
            this.$refs.wrapper.scrollBy({
                left: position,
                behavior: 'smooth',
            });
        },

        attachMutationObserver() {
            this.observer = new MutationObserver(() => {
                this.init();
            });
            this.observer.observe(this.$refs.wrapper, {
                childList: true,
                subtree: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$scrollbar-size: $tailwindcss-spacing-1;

.marketing-promo-categories-slider {
    @apply w-full;

    .slider {
        @apply relative inline-block max-w-full w-full;
    }

    .wrapper {
        @apply overflow-x-scroll overflow-y-hidden w-full grid justify-start pb-3;
        grid-template-rows: 1fr 1fr;
        grid-gap: theme('spacing.2');
        grid-auto-flow: column;
        scroll-snap-type: x mandatory;
        -webkit-scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scroll-behavior: smooth;

        &::-webkit-scrollbar-thumb {
            @apply rounded-4 bg-gray1;
        }

        &::-webkit-scrollbar-track {
            @apply rounded-4 bg-gray6;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            height: $scrollbar-size;
        }
    }

    .nav-button {
        @apply hidden;
    }

    @screen md {
        .wrapper {
            grid-gap: theme('spacing.3');
        }

        .nav-button {
            @apply absolute top-1/2 w-7 h-7 bg-gray4 rounded-4
            flex items-center justify-center z-1 opacity-80;

            &:disabled {
                @apply hidden;
            }
        }

        .nav-button-left {
            left: theme('spacing.4');
            transform: translate(-50%, -50%) translateY(theme('spacing.-2'));
        }

        .nav-button-right {
            right: theme('spacing.4');
            transform: translate(50%, -50%) translateY(theme('spacing.-2'));
        }
    }
}
</style>
