<template>
    <div
        :id="id"
        :class="{ 'with-blurred-background': isBackgroundBlurred }"
        class="modal"
        @keyup.esc="closeOnEsc()"
    >
        <WithClickOutsideDetection :handler="'closeModal'">
            <div
                ref="modal"
                :class="modalWindowClasses"
                class="modal-window"
                tabindex="0"
            >
                <ButtonIcon
                    :data-test-id="CLOSE_MODAL"
                    :variant="BUTTON_ICON_VARIANT"
                    :class="{
                        'hide-close-button-on-mobile': hideCloseButtonOnMobile,
                    }"
                    class="close-button"
                    @click.native="closeModal()"
                >
                    <Icon :icon="Close" />
                </ButtonIcon>

                <header
                    v-if="!isFullscreenDesktop && $slots.header"
                    :class="headerClasses"
                    class="modal-header"
                >
                    <slot name="header" />
                </header>

                <section
                    v-if="$slots.body"
                    :class="modalBodyClass"
                    class="modal-body"
                >
                    <slot name="body" />
                </section>

                <footer
                    v-if="$slots.footer"
                    :class="modalFooterClass"
                    class="modal-footer"
                >
                    <slot name="footer" />
                </footer>
            </div>
        </WithClickOutsideDetection>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PREVENT_SCROLL_MODAL_CLASS_NAME } from '@configs/class-names';

import { SIZE_CONTENT, SIZES } from '@types/Modal';
import { CLOSE_MODAL } from '@types/AutomaticTestIDs';

import { checkIfExistsInValuesMap } from '@assets/props';

import WithClickOutsideDetection from '@molecules/WithClickOutsideDetection/WithClickOutsideDetection';

import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';

import { Icon } from '@eobuwie-ui/components/Icon/v1';

import { Close } from '@eobuwie-ui/icons/v2/navigation';

const { mapActions: mapActionsLayout } = createNamespacedHelpers('layout');

export default {
    name: 'Modal',

    components: {
        ButtonIcon,
        WithClickOutsideDetection,
        Icon,
    },

    props: {
        id: {
            type: String,
            required: true,
        },

        isBackgroundBlurred: {
            type: Boolean,
            default: false,
        },

        headerWithBorder: {
            type: Boolean,
            default: false,
        },

        headerWithCenteredText: {
            type: Boolean,
            default: false,
        },

        grayHeader: {
            type: Boolean,
            default: false,
        },

        canCloseOnEsc: {
            type: Boolean,
            default: true,
        },

        isNotFullScreenOnMobile: {
            type: Boolean,
            default: true,
        },

        hideCloseButtonOnMobile: {
            type: Boolean,
            default: false,
        },

        isFullscreenDesktop: {
            type: Boolean,
            default: false,
        },

        modalWindowClass: {
            type: [String, Array],
            default: '',
        },

        modalHeaderClass: {
            type: [String, Array],
            default: '',
        },

        modalBodyClass: {
            type: [String, Array],
            default: '',
        },

        modalFooterClass: {
            type: [String, Array],
            default: '',
        },

        size: {
            type: String,
            default: SIZE_CONTENT,
            validator: checkIfExistsInValuesMap(SIZES),
        },

        fixedHeaderAndFooter: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        mainModalClasses() {
            return [
                ...this.modalClassAsArray(this.modalWindowClass),
                {
                    'is-not-full-screen-on-mobile': !this
                        .isNotFullScreenOnMobile,

                    'modal-fullscreen': this.isFullscreenDesktop,
                },
            ];
        },

        headerClasses() {
            return [
                ...this.modalClassAsArray(this.modalHeaderClass),
                {
                    'with-border': this.headerWithBorder,
                    'with-centered-text': this.headerWithCenteredText,
                    'gray-header': this.grayHeader,
                },
            ];
        },

        modalWindowClasses() {
            return [
                ...this.modalClassAsArray(this.mainModalClasses),
                `size-${this.size}`,
                {
                    'fixed-header-and-footer': this.fixedHeaderAndFooter,
                },
            ];
        },

        HTMLClasses() {
            return [PREVENT_SCROLL_MODAL_CLASS_NAME];
        },
    },

    mounted() {
        this.$refs.modal.focus();
        this.$refs.modal.scrollTop = 0;
        this.addHTMLClasses(this.HTMLClasses);
    },

    beforeCreate() {
        this.CLOSE_MODAL = CLOSE_MODAL;
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.Close = Close;
    },

    destroyed() {
        this.removeHTMLClasses(this.HTMLClasses);
    },

    methods: {
        ...mapActionsLayout(['addHTMLClasses', 'removeHTMLClasses']),

        closeOnEsc() {
            if (this.canCloseOnEsc) {
                this.closeModal();
            }
        },

        closeModal() {
            this.$emit('close');
        },

        modalClassAsArray(modalClass) {
            return typeof modalClass !== 'string' && !Array.isArray(modalClass)
                ? []
                : [modalClass].flat();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/blurred-background';

@mixin not-full-screen-modal() {
    @apply absolute h-auto left-1/2 top-1/2 max-h-full;
    transform: translate(-50%, -50%);
}

.modal {
    @apply fixed inset-0 w-full h-full justify-center items-center overflow-auto z-8;

    &:focus {
        @apply outline-none;
    }

    // MS Edge Browser
    @supports (-ms-ime-align: auto) {
        @apply overflow-hidden;
    }

    &.with-blurred-background {
        @include blurred-background();
    }

    .modal-window {
        @apply w-auto h-full overflow-auto relative bg-light;

        &:focus {
            @apply outline-none;
        }

        &.is-not-full-screen-on-mobile {
            @include not-full-screen-modal();
            transform: translate(-50%, 0);
        }

        &.modal-fullscreen {
            @apply h-full w-full p-0;
        }

        .hide-close-button-on-mobile {
            @apply hidden;
        }

        .button-icon {
            @apply absolute z-1;
        }

        .modal-header {
            &.with-border {
                @apply border-solid border-b-1;
            }

            &.with-centered-text {
                @apply flex items-center justify-center text-center;
            }
        }

        &.fixed-header-and-footer {
            @apply flex flex-col;

            .modal-body {
                @apply overflow-y-auto;
            }
        }

        @screen md {
            &,
            &.is-not-full-screen-on-mobile {
                @include not-full-screen-modal();
            }
        }
    }

    .close-button {
        @apply top-0 right-4 mt-1 mb-1;
    }

    .hide-close-button-on-mobile {
        @apply hidden;
    }

    .modal-header {
        @apply font-bold text-r pr-7 pl-3 flex items-center;
        height: theme('customVariables.modal.headerHeight');

        &.with-border {
            @apply border-gray5;
        }

        &.with-centered-text {
            @apply pl-7;
        }

        &.gray-header {
            @apply bg-gray8;
        }
    }

    .modal-body,
    .modal-footer {
        @apply p-3;
    }

    @screen mobile-and-tablet-only {
        .modal-window {
            &.is-not-full-screen-on-mobile {
                @include not-full-screen-modal();
                @apply bottom-0 left-0 right-0 top-auto transform-none rounded-t-4;
            }
        }
    }

    @screen md {
        .modal-window {
            @apply border-gray5 border-solid border-1 rounded-4;

            &.size-small {
                width: theme('customVariables.modal.sizeSmallWidth');
            }

            &.size-large {
                width: theme('customVariables.modal.sizeLargeWidth');
            }

            &.is-not-full-screen-on-mobile {
                &.modal-fullscreen {
                    @apply h-full;
                }
            }
        }

        .close-button {
            @apply right-18;
        }
    }

    @screen lg {
        .modal-body,
        .modal-footer {
            @apply p-5 pt-3;
        }

        .modal-header {
            @apply text-m leading-m;
        }
    }
}
</style>
