<template>
    <MarketingValuePropositionSliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
// eslint-disable-next-line max-len
import MarketingValuePropositionSliderSlide from '@organisms/MarketingValueProposition/MarketingValuePropositionSliderSlide';

export default {
    name: 'CmsMarketingValuePropositionSliderSlide',

    components: {
        MarketingValuePropositionSliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingValuePropositionSliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    backgroundColor: '',
                    headingColor: '',
                    headingText: '',
                    imageUrl: '',
                    textColor: '',
                    text: '',
                    url: '',
                    isNewsletterSlide: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
