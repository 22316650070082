<template>
    <CustomerServiceChatDataProvider #default="{ links, cmsBlock }">
        <CustomerServiceChat :links="links" :cms-block="cmsBlock" />
    </CustomerServiceChatDataProvider>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import CustomerServiceChatDataProvider from '@molecules/CustomerServiceChatDataProvider/CustomerServiceChatDataProvider';
import CustomerServiceChat from '@organisms/CustomerServiceChat/CustomerServiceChat';

export default {
    name: 'CmsCustomerServiceChat',

    components: {
        CustomerServiceChatDataProvider,
        CustomerServiceChat,
    },

    extends: CmsBaseComponent,

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
