<template>
    <MarketingHeroBlock :class="cssClass" v-bind="propsToBind">
        <PageBuilderInterpreter :components-json="slotDefault" />
    </MarketingHeroBlock>
</template>

<script>
import { getProps } from '@modules/page-builder/helpers/component';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import MarketingHeroBlock from '@organisms/MarketingHeroBlock/MarketingHeroBlock';

import { HEADER_TEXT_VARIANTS } from '@eobuwie-ui/components/HeaderText/v1';
import { BODY_TEXT_COLORS } from '@eobuwie-ui/components/BodyText/v1';
import { BUTTON_LARGE_VARIANTS } from '@eobuwie-ui/components/ButtonLarge/v1';

export default {
    name: 'CmsMarketingHeroBlock',

    components: { MarketingHeroBlock, PageBuilderInterpreter },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingHeroBlock),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    url: '',
                    subheader: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
                textVariant: Object.values(HEADER_TEXT_VARIANTS),
                bodyTextColor: Object.values(BODY_TEXT_COLORS),
                buttonVariant: Object.values(BUTTON_LARGE_VARIANTS),
            },
        };
    },
};
</script>
