<template>
    <MarketingBrandsSectionSliderSlide v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
// eslint-disable-next-line max-len
import MarketingBrandsSectionSliderSlide from '@organisms/MarketingBrandsSectionSliderSlide/MarketingBrandsSectionSliderSlide';

export default {
    name: 'CmsMarketingBrandsSectionSliderSlide',

    components: {
        MarketingBrandsSectionSliderSlide,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingBrandsSectionSliderSlide),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    firstBrandUrl: '',
                    firstBrandImage: '',
                    firstBrandName: '',
                    firstBrandBadge: '',
                    secondBrandUrl: '',
                    secondBrandImage: '',
                    secondBrandName: '',
                    secondBrandBadge: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
