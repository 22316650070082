<template>
    <PageBuilderInterpreter :components-json="slotDefault" />
</template>

<script>
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsAppEobText',

    components: {
        PageBuilderInterpreter,
    },

    /* eslint-disable vue/no-unused-properties */
    props: {
        priority: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            required: true,
        },

        firstText: {
            type: String,
            default: '',
        },

        secondText: {
            type: String,
            default: '',
        },

        women: {
            type: Boolean,
            default: false,
        },

        men: {
            type: Boolean,
            default: false,
        },

        child: {
            type: Boolean,
            default: false,
        },

        textColor: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '',
        },

        regulationsText: {
            type: String,
            default: '',
        },

        regulationsPdf: {
            type: String,
            default: '',
        },

        counterStartDate: {
            type: String,
            default: '',
        },

        counterEndDate: {
            type: String,
            default: '',
        },

        image: {
            type: String,
            default: '',
        },

        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    priority: '',
                    tag: '',
                    women: false,
                    men: false,
                    child: false,
                    firstText: '',
                    secondText: '',
                    textColor: '',
                    backgroundColor: '',
                    regulationsText: '',
                    regulationsPdf: '',
                    counterStartDate: '',
                    counterEndDate: '',
                    image: '',
                },
            },

            propsCustomFormConfig: {
                tag: {
                    max: 24,
                },
            },
        };
    },
};
</script>
