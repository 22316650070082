export const TYPE_NUM = 'num';
export const TYPE_DOT = 'dot';

export const THEME_PRIMARY = 'primary';
export const THEME_TERTIARY = 'tertiary';
export const THEME_DARK = 'dark';

export const SIZE_BIG = 'big';
export const SIZE_SMALL = 'small';

export const TYPES = { TYPE_NUM, TYPE_DOT };

export const THEMES = { THEME_PRIMARY, THEME_TERTIARY, THEME_DARK };

export const SIZES = { SIZE_BIG, SIZE_SMALL };
