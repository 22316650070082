<template>
    <span class="base-badge" :class="additionalClasses" :style="style">
        <span v-if="hasIconSlot" class="icon">
            <slot name="icon" />
        </span>
        <slot />
    </span>
</template>

<script>
import { COLORS } from '@types/Badge';

import { checkIfExistsInValuesMap } from '@assets/props';
import { isHexColor } from '@assets/color';

export default {
    name: 'BaseBadge',

    props: {
        color: {
            type: String,
            default: COLORS.COLOR_GREEN,
            validator: value =>
                checkIfExistsInValuesMap(COLORS, true)(value) ||
                isHexColor(value),
        },

        backgroundColor: {
            type: String,
            default: '',
            validator: value => value === '' || isHexColor(value),
        },

        hasSquareBorder: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasIconSlot() {
            return !!this.$slots.icon;
        },

        hasBackground() {
            return isHexColor(this.backgroundColor);
        },

        hasHexColor() {
            return isHexColor(this.color);
        },

        additionalClasses() {
            const classes = [this.hasSquareBorder ? 'no-rounded' : null];

            if (this.hasIconSlot) {
                classes.push('has-icon');

                return classes;
            }

            if (this.hasBackground) {
                return classes;
            }

            if (!this.hasHexColor) {
                classes.push(this.color);
            }

            return classes;
        },

        style() {
            const styles = {};

            if (this.hasBackground && !this.hasIconSlot) {
                styles.backgroundColor = this.backgroundColor;
            }

            if (this.hasHexColor) {
                styles.color = this.color;
            }

            return styles;
        },
    },
};
</script>

<style lang="scss" scoped>
.base-badge {
    @apply text-xs leading-s px-2 py-2p rounded-4 text-gray1;
    @apply inline-flex items-center justify-center whitespace-nowrap;

    &.no-rounded {
        @apply rounded-0;
    }

    &.has-icon {
        @apply bg-light;
    }

    &.green {
        @apply bg-green2 text-light;
    }

    &.red {
        @apply bg-red1 text-light;
    }

    &.black {
        @apply bg-gray1 text-light;
    }

    &.white {
        @apply bg-light text-gray1;

        box-shadow: inset 0 0 0 2px theme('colors.gray2');
    }

    &.blue {
        @apply bg-blue2 text-light;
    }

    &.blue-light {
        @apply bg-blue4 text-blue2;
    }

    &.blue-light2 {
        @apply bg-blue5 text-blue2;
    }

    &.purple {
        @apply bg-purple text-light;
    }

    &.dark-purple {
        @apply bg-dark-purple text-light;
    }

    &.green-light {
        @apply bg-green6 text-green1;
    }

    .icon {
        @apply shrink-0;

        &:not(:only-child) {
            @apply mr-2;
        }
    }
}
</style>
