<template>
    <MarketingBarDiscountCode v-bind="propsToBind" />
</template>

<script>
import { MARKETING_BAR_ALLOWED_ICONS } from '@configs/marketing-bar';

import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import MarketingBarDiscountCode from '@marketing-bar-components/organisms/MarketingBarDiscountCode/MarketingBarDiscountCode';

export default {
    name: 'CmsMarketingBarDiscountCode',

    components: {
        MarketingBarDiscountCode,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketingBarDiscountCode),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    code: '',
                    color: '#fff',
                    textDesktop: '',
                    textMobile: '',
                    backgroundColor: '',
                    iconColor: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
                icon: [...Object.values(MARKETING_BAR_ALLOWED_ICONS), ''],
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                textMobile: {
                    max: 40,
                },

                textDesktop: {
                    max: 80,
                },
            },
        };
    },
};
</script>
