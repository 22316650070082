<template>
    <section v-if="hasAllRequiredProps" class="marketing-display-with-iframe">
        <ContainerContent class="display-container">
            <BasePicture
                :width="image.width"
                :height="image.height"
                :src="image.url"
                :alt="image.alt"
                :sources="image.sources"
                :sources-with-media="sourcesWithMedia"
                :is-lazy="true"
                @click="showModal = !showModal"
            />
        </ContainerContent>
        <Modal
            v-if="showModal"
            id="modal-iframe"
            class="modal-iframe"
            :is-fullscreen-desktop="true"
            @close="showModal = !showModal"
        >
            <template #body>
                <Loader v-if="!isIframeLoaded" height="100%" />
                <MarketingIframe
                    v-show="isIframeLoaded"
                    :src="iframeSrc"
                    class="iframe"
                    @iframe-loaded="loadedIframe()"
                />
            </template>
        </Modal>
    </section>
</template>

<script>
import {
    DEFAULT_IMAGE_FORMAT,
    SOURCE_IMAGE_FORMATS,
    IMAGE_TYPE_DISPLAY_768w_457h,
    IMAGE_TYPE_DISPLAY_1325w_250h,
} from '@types/Image';

import BasePicture from '@atoms/BasePicture/BasePicture';
import MarketingIframe from '@atoms/MarketingIframe/MarketingIframe';
import Loader from '@atoms/Loader/Loader';

import Modal from '@molecules/Modal/Modal';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'MarketingDisplayWithIframe',

    components: {
        MarketingIframe,
        BasePicture,
        ContainerContent,
        Modal,
        Loader,
    },

    props: {
        iframeSrc: {
            type: String,
            required: true,
        },

        imageAlt: {
            type: String,
            default: '',
        },

        imageUrl768x457: {
            type: String,
            required: true,
        },

        imageUrl1325x250: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isIframeLoaded: false,
            showModal: false,
        };
    },

    computed: {
        image() {
            const image768x457 = this.$imaginator.getImage(
                this.imageUrl768x457,
                'marketing-display',
                this.imageAlt,
                IMAGE_TYPE_DISPLAY_768w_457h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_DISPLAY_768w_457h]
            );

            const result = this.$imaginator.getImage(
                this.imageUrl1325x250,
                'marketing-display',
                this.imageAlt,
                IMAGE_TYPE_DISPLAY_1325w_250h,
                DEFAULT_IMAGE_FORMAT,
                SOURCE_IMAGE_FORMATS,
                [IMAGE_TYPE_DISPLAY_1325w_250h]
            );

            Object.entries(image768x457.sources).forEach(([type, source]) => {
                result.sources[type] = result.sources[type].concat(source);
            });

            return result;
        },

        sourcesWithMedia() {
            const { sources } = this.image;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },

        hasAllRequiredProps() {
            return (
                !!this.iframeSrc &&
                !!this.imageUrl768x457 &&
                !!this.imageUrl1325x250
            );
        },
    },

    methods: {
        loadedIframe() {
            this.isIframeLoaded = true;
        },

        getMediaForSource(source) {
            const mediaMap = {
                768: '(max-width: 767px)',
                1325: '(min-width: 768px)',
            };

            return mediaMap[source.width] || '';
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-display-with-iframe {
    .display-container {
        @apply flex w-full px-0 cursor-pointer;
    }

    .modal-iframe {
        &:deep(.modal-body) {
            @apply w-full h-full px-0 py-0;
        }
    }
}
</style>
