<template>
    <div>
        <pre>{{ propsToBind }}</pre>
    </div>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

export default {
    name: 'CmsSyneriseConfig',

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        apiKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerKey: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        trackerDomain: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        scriptUrl: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobPdp1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobPdp2: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobPdpUnavbl1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobPrecart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobCart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobCategory1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobEmptyCart1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobSearchResults1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobErrorPage1: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        webEobPdpProductNotification1: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    apiKey: '',
                    trackerKey: '',
                    trackerDomain: '',
                    scriptUrl: '',
                    webEobPdp1: '',
                    webEobPdp2: '',
                    webEobPdpUnavbl1: '',
                    webEobPrecart1: '',
                    webEobCart1: '',
                    webEobCategory1: '',
                    webEobEmptyCart1: '',
                    webEobSearchResults1: '',
                    webEobErrorPage1: '',
                    webEobPdpProductNotification1: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                scriptUrl: {
                    isUrl: true,
                },

                trackerDomain: {
                    isUrl: true,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
