<template>
    <div
        v-if="hasAllRequiredValues"
        class="marketing-celebrity-slider-slide-content"
    >
        <div class="text">
            <BaseHeading class="title">
                {{ title }}
            </BaseHeading>
            <BaseParagraph class="content">
                {{ content }}
            </BaseParagraph>
        </div>

        <CustomProductsSimpleSliderWrapper
            class="celebrity-products-slider"
            :items-count="productsSliderItems"
            :product-skus="productSkusArray"
        />
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import { xl as BREAKPOINT_XL } from '@theme/resources/variables/responsiveBreakpoints.json';
import CustomProductsSimpleSliderWrapper from '@organisms/CustomProductsSimpleSliderWrapper/CustomProductsSimpleSliderWrapper';
import { parseArrayString } from '@modules/page-builder/helpers/component';

const ITEMS_COUNT_LG = 3;
const ITEMS_COUNT_XL = 4;

export default {
    name: 'MarketingCelebritySliderSlideContent',

    components: {
        CustomProductsSimpleSliderWrapper,
        BaseParagraph,
        BaseHeading,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        productSkus: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        mediaQueryList: null,
        isContainerBreakpointMediaQuery: false,
    }),

    computed: {
        productSkusArray() {
            return parseArrayString(this.productSkus);
        },

        productsSliderItems() {
            return this.isContainerBreakpointMediaQuery
                ? ITEMS_COUNT_XL
                : ITEMS_COUNT_LG;
        },

        hasAllRequiredValues() {
            return this.title && this.content && this.productSkus;
        },
    },

    beforeMount() {
        this.mediaQueryList = window.matchMedia(
            `(min-width: ${BREAKPOINT_XL})`
        );
        this.mediaQueryList.addListener(this.checkViewport);
        this.isContainerBreakpointMediaQuery = this.mediaQueryList.matches;
    },

    beforeDestroy() {
        this.mediaQueryList.removeListener(this.checkViewport);
    },

    methods: {
        checkViewport(event) {
            this.isContainerBreakpointMediaQuery = event.matches;
        },
    },
};
</script>

<style lang="scss" scoped>
$navigation-buttons-height: $tailwindcss-spacing-8;
$margin-left-mobile: $tailwindcss-spacing-3;

.marketing-celebrity-slider-slide-content {
    .text {
        @apply px-3;
    }

    .title {
        @apply text-l leading-l;
    }

    .content {
        @apply text-text2 mt-3;
    }

    .celebrity-products-slider {
        @apply mt-3;
    }

    &:deep() {
        .simple-slider {
            .navigation-buttons {
                @apply hidden;
            }
        }
    }

    @screen lg {
        .text {
            @apply ml-3;
        }

        .title {
            @apply text-xl leading-xl;
        }

        .celebrity-products-slider {
            @apply ml-0;
        }

        &:deep() {
            .simple-slider {
                .navigation-buttons {
                    @apply flex;
                    height: $navigation-buttons-height;
                }
            }
        }
    }
}
</style>
