<template>
    <MarketingHeroBlockImage v-bind="propsToBind" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingHeroBlockImage from '@molecules/MarketingHeroBlockImage/MarketingHeroBlockImage';

export default {
    name: 'CmsMarketingHeroBlockImage',

    components: {
        MarketingHeroBlockImage,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingHeroBlockImage),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    imageDesktop: '',
                    imageMobile: '',
                },
            },
        };
    },
};
</script>
