export const DISPLAY_INLINE = 'inline';
export const DISPLAY_BLOCK = 'block';

export const ALIGN_CENTER = 'center';
export const ALIGN_RIGHT = 'right';
export const ALIGN_LEFT = 'left';
export const ALIGN_MOBILE_LEFT_DESKTOP_RIGHT = 'm-left-d-right';

export const DISPLAYS = { DISPLAY_INLINE, DISPLAY_BLOCK };

export const ALIGNS = {
    ALIGN_LEFT,
    ALIGN_CENTER,
    ALIGN_RIGHT,
    ALIGN_MOBILE_LEFT_DESKTOP_RIGHT,
};
