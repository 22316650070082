<template>
    <ResponsivePicture v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import ResponsivePicture from '@molecules/ResponsivePicture/ResponsivePicture';

export default {
    name: 'CmsResponsivePicture',

    components: {
        ResponsivePicture,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(ResponsivePicture),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    marginTop: '',
                    url: '',
                    mobileUrl: '',
                    alt: '',
                    alignCenter: false,
                },
            },
        };
    },
};
</script>
