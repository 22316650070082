<template>
    <div class="base-message" :class="type">
        <SvgIcon v-if="hasIcon" width="24px" height="24px" class="icon">
            <component :is="iconType" />
        </SvgIcon>
        <div
            :class="{
                header: hasAdditionalTextSlot() || boldHeader,
            }"
        >
            <slot />
            <div v-if="hasAdditionalTextSlot()" class="additional-text">
                <slot :name="ADDITIONAL_TEXT_SLOT_NAME" />
            </div>
        </div>
        <div v-if="!!$slots.tooltip" class="tooltip">
            <slot name="tooltip" />
        </div>
    </div>
</template>

<script>
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import QuestionMarkTooltip from '@molecules/QuestionMarkTooltip/QuestionMarkTooltip';
import { checkIfExistsInValuesMap } from '@assets/props';
import { TYPES, TYPE_TO_ICON_MAP } from '@types/Message';

export default {
    name: 'BaseMessage',

    components: {
        SvgIcon,
        BaseTooltip,
        QuestionMarkTooltip,
    },

    props: {
        hasIcon: {
            type: Boolean,
            default: false,
        },

        type: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(TYPES, true),
        },

        boldHeader: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        iconType() {
            return TYPE_TO_ICON_MAP[this.type];
        },
    },

    created() {
        this.ADDITIONAL_TEXT_SLOT_NAME = 'additional-text';
    },

    methods: {
        hasAdditionalTextSlot() {
            return !!this.$slots[this.ADDITIONAL_TEXT_SLOT_NAME];
        },
    },
};
</script>

<style lang="scss" scoped>
.base-message {
    @apply flex px-2 py-10p w-full text-left text-s leading-s rounded-4;

    &.positive {
        @apply bg-green6;
    }

    &.negative {
        @apply bg-red3;
    }

    &.neutral,
    &.return,
    &.lock {
        @apply bg-gray7;
    }

    &.warning {
        @apply bg-yellow2;
    }

    .icon {
        @apply w-4 h-4 mr-2 -my-2p shrink-0;
    }

    .header {
        @apply font-bold text-text2;
    }

    .additional-text {
        @apply mt-10p font-book text-text;
    }

    .tooltip {
        @apply ml-auto -my-2p pl-2;
    }
}
</style>
