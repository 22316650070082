import Flame from '@static/icons/24px/flame.svg?inline';

export const ICON_FLAME = 'Flame';

export const ICON_TYPES = {
    ICON_FLAME,
};

export const ICON_MAP = {
    [ICON_FLAME]: Flame,
};
