<template>
    <div class="help-center-subpage-heading">
        <SvgIcon width="48px" height="48px" class="icon">
            <component :is="iconType" />
        </SvgIcon>
        <BaseHeading theme="h4" class="heading">{{ heading }}</BaseHeading>
    </div>
</template>

<script>
import { ICON_MAP, ICON_TYPES } from '@configs/cms-help-center';
import { checkIfExistsInValuesMap } from '@assets/props';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

export default {
    name: 'HelpCenterSubpageHeading',
    components: {
        SvgIcon,
        BaseHeading,
    },

    props: {
        icon: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(ICON_TYPES),
        },

        heading: {
            type: String,
            required: true,
        },
    },

    computed: {
        iconType() {
            return ICON_MAP[this.icon];
        },
    },
};
</script>

<style lang="scss" scoped>
.help-center-subpage-heading {
    @apply flex items-center;

    .heading {
        @apply ml-3;
    }

    .icon {
        @apply shrink-0 text-primary;
    }
}
</style>
