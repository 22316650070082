<template>
    <div
        v-if="shouldBannerBeVisible"
        :style="bannerStyles"
        :class="theme"
        class="banner-with-code-and-clock"
    >
        <div class="details">
            <div class="heading-wrapper">
                <BaseHeading :color="theme" tag="h2" theme="h2" class="heading">
                    {{ heading }}
                </BaseHeading>
                <div v-if="isCodeEnabled" class="code-wrapper">
                    <span
                        v-if="!shouldHideDesktopComponents"
                        class="code-label"
                    >
                        {{ $t('with the code:') }}
                    </span>
                    <div class="code">
                        <span class="code-light">
                            {{ `${$t('Code')} ` }}
                        </span>
                        <span>{{ code }}</span>
                    </div>
                    <CopyToClipboardButton
                        :value-to-copy="code"
                        :before-copy-label="$t('Copy')"
                        :after-copy-label="$t('Copied')"
                        :theme="BUTTON_THEME_PURE"
                        :icon-position="ICON_POSITIONS.ICON_POSITION_LEFT"
                        class="copy-button"
                    >
                        <template
                            v-if="!shouldHideDesktopComponents"
                            #icon="{ isCopied }"
                        >
                            <SvgIcon width="20px" height="20px" class="icon">
                                <component :is="isCopied ? 'Check' : 'Copy'" />
                            </SvgIcon>
                        </template>
                    </CopyToClipboardButton>
                </div>
            </div>
            <div v-if="isClockEnabled" class="clock-wrapper">
                <span class="clock-description">{{ $t('Ends in') }}</span>
                <div class="clock-number-wrapper">
                    <template
                        v-for="{
                            label,
                            labelMobile,
                            value,
                            separator,
                        } in countdownParts"
                    >
                        <div
                            v-if="!shouldHideDesktopComponents"
                            :key="`value-${label}`"
                            class="clock-part clock-part-desktop"
                        >
                            <div class="clock-part-number">{{ value }}</div>
                            <span class="clock-part-label">
                                {{ label }}
                            </span>
                        </div>
                        <span
                            v-if="!shouldHideDesktopComponents"
                            :key="`separator-${label}`"
                            class="clock-part-desktop"
                        >
                            {{ separator }}
                        </span>
                        <span
                            :key="`value-mobile-${label}`"
                            class="clock-part-number clock-part-mobile"
                        >
                            {{ value }}
                        </span>
                        <span :key="`label-${label}`" class="clock-part-mobile">
                            {{ labelMobile || label }}
                        </span>
                    </template>
                </div>
            </div>
        </div>

        <div class="categories-wrapper">
            <slot name="categories" />
        </div>

        <div class="terms-wrapper">
            <slot />
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { checkIfExistsInValuesMap } from '@assets/props';
import { getTimeParts } from '@assets/countdown-clock';
import { isHexColor } from '@assets/color';

import { ICON_POSITIONS, THEMES as BUTTON_THEMES } from '@types/Button';
import { THEMES } from '@types/BannerWithCodeAndClock';
import { MILLISECONDS_IN_SECOND } from '@types/Datetime';

import Copy from '@static/icons/20px/copy.svg?inline';
import Check from '@static/icons/20px/check.svg?inline';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import WithScroll from '@atoms/WithScroll/WithScroll';

import CopyToClipboardButton from '@molecules/CopyToClipboardButton/CopyToClipboardButton';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const localesWithDisabledClock = ['hu_HU'];

export default {
    name: 'BannerWithCodeAndClock',

    components: {
        BaseHeading,
        BaseLink,
        BaseParagraph,
        CopyToClipboardButton,
        SvgIcon,
        Copy,
        Check,
        WithScroll,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        color: {
            type: String,
            default: '',
            validator: value => value === '' || isHexColor(value),
        },

        theme: {
            type: String,
            default: THEMES.THEME_LIGHT,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        code: {
            type: String,
            default: '',
        },

        timestampFrom: {
            type: String,
            default: '',
        },

        timestampTo: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            timestampNow: new Date().getTime() / MILLISECONDS_IN_SECOND,
            timeInterval: null,
        };
    },

    computed: {
        ...mapState(['isTabletOrDesktop', 'isTabletOrDesktopEvaluated']),
        ...mapConfigGetters(['locale']),

        shouldHideDesktopComponents() {
            return !this.isTabletOrDesktop && this.isTabletOrDesktopEvaluated;
        },

        isClockEnabled() {
            return (
                !!this.timestampTo.length &&
                !localesWithDisabledClock.includes(this.locale)
            );
        },

        isCodeEnabled() {
            return !!this.code.length;
        },

        timeDiff() {
            return Math.floor(Number(this.timestampTo) - this.timestampNow);
        },

        bannerStyles() {
            return this.color ? { backgroundColor: this.color } : {};
        },

        shouldBannerBeVisible() {
            if (this.timestampFrom.length) {
                return (
                    this.timestampNow >= this.timestampFrom &&
                    !this.hasCountdownFinished
                );
            }

            return !this.hasCountdownFinished;
        },

        hasCountdownFinished() {
            if (this.timeDiff <= 0 && this.timeInterval) {
                clearInterval(this.timeInterval);

                return true;
            }

            return !!(this.timeDiff <= 0 && this.isClockEnabled);
        },

        timeParts() {
            return getTimeParts(this.timeDiff);
        },

        countdownParts() {
            return [
                {
                    label: this.$t('days.'),
                    value: String(this.timeParts.days).padStart(2, '0'),
                    separator: ':',
                },
                {
                    label: this.$t('hour.'),
                    labelMobile: this.$t('h'),
                    value: String(this.timeParts.hours).padStart(2, '0'),
                    separator: ':',
                },
                {
                    label: this.$t('min.'),
                    value: String(this.timeParts.minutes).padStart(2, '0'),
                    separator: ':',
                },
                {
                    label: this.$t('sec.'),
                    value: String(this.timeParts.seconds).padStart(2, '0'),
                    separator: '',
                },
            ];
        },
    },

    mounted() {
        if (this.isClockEnabled) {
            this.startTimer();
        }
    },

    destroyed() {
        if (this.timeInterval) {
            clearInterval(this.timeInterval);
        }
    },

    beforeCreate() {
        this.BUTTON_THEME_PURE = BUTTON_THEMES.THEME_PURE;
        this.ICON_POSITIONS = ICON_POSITIONS;
    },

    methods: {
        startTimer() {
            this.timeInterval = setInterval(() => {
                this.timestampNow =
                    new Date().getTime() / MILLISECONDS_IN_SECOND;
            }, MILLISECONDS_IN_SECOND);
        },
    },
};
</script>

<style scoped lang="scss">
.banner-with-code-and-clock {
    @apply py-22p px-3 flex flex-col items-center;

    &.light {
        @apply text-light;
        .code-wrapper {
            @apply text-light;

            .code {
                @apply border-light whitespace-nowrap;
                background-color: rgba(255, 255, 255, 0.5);
            }

            .copy-button {
                @apply text-light;
            }
        }

        .categories-wrapper {
            &:deep() {
                .text-link {
                    @apply bg-light text-dark;
                }
            }
        }

        .terms-wrapper {
            &:deep() {
                .text-link {
                    @apply text-light;
                }
            }
        }
    }

    &.dark {
        @apply text-dark;

        .code-wrapper {
            @apply text-dark;

            .code {
                @apply border-dark;
                background-color: rgba(0, 0, 0, 0.5);
            }
        }

        .clock-wrapper {
            .clock-part {
                &-number {
                    background-color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .categories-wrapper {
            &:deep() {
                .text-link {
                    @apply bg-dark text-light;
                }
            }
        }

        .terms-wrapper {
            &:deep() {
                .text-link {
                    @apply text-dark;
                }
            }
        }
    }

    .details {
        @apply flex flex-col items-center justify-between;

        .heading-wrapper {
            .heading {
                @apply text-center;
            }
        }
    }

    .code-wrapper {
        @apply flex items-center justify-center pt-2;

        .code-label {
            @apply hidden;
        }

        .code {
            @apply border-1 border-dashed font-bold py-1 px-20p rounded-l-4 text-r leading-r;
            height: 34px;
        }

        .copy-button {
            @apply border-1 border-dashed py-1 px-3 rounded-r-4 text-r leading-r;
            min-height: auto;
            height: 34px;

            &:hover {
                background-color: inherit;
            }
        }

        .icon {
            @apply hidden;
            color: currentColor;
        }

        .code-light {
            @apply font-book uppercase;
        }
    }

    .categories-wrapper {
        @apply py-1 mt-2;

        &:deep() {
            & > div {
                @apply flex flex-wrap justify-center;
            }

            .text-link {
                @apply rounded-100 py-6p px-12p font-bold text-s leading-s mr-2 mb-2;
            }
        }
    }

    .clock-wrapper {
        @apply flex flex-col items-center whitespace-nowrap py-1 text-s leading-s;

        .clock-part {
            @apply flex flex-col items-center;

            &-number {
                @apply font-bold;
            }

            &-label {
                @apply text-s leading-s;
            }

            &-desktop {
                @apply hidden;
            }
        }
    }

    .terms-wrapper {
        & > div {
            @apply text-center;
        }

        &:deep() {
            .cms-text {
                @apply text-s leading-s;
            }

            .text-link {
                @apply text-s leading-s font-bold;
            }
        }
    }
}

@screen md {
    .banner-with-code-and-clock {
        @apply block;

        &.light {
            .clock-wrapper {
                .clock-part {
                    &-number {
                        background-color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
        }

        &.dark {
            .clock-wrapper {
                .clock-part {
                    &-number {
                        background-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }

        .details {
            @apply flex-row;

            .heading-wrapper {
                .heading {
                    @apply text-left;
                }
            }
        }

        .code-wrapper {
            @apply justify-start;

            .code-label {
                @apply block;
            }

            .code {
                @apply px-3 ml-1 mr-3 rounded-4;

                .code-light {
                    @apply hidden;
                }
            }

            .copy-button {
                @apply border-none py-0 px-0;
            }

            .icon {
                @apply block;
            }
        }

        .clock-wrapper {
            @apply flex-row items-start;

            .clock-number-wrapper {
                @apply flex;
            }

            .clock-part {
                &-number {
                    @apply rounded-4 mx-2 font-book text-m leading-m w-5 h-5 flex;
                    @apply items-center justify-center flex-col;
                }

                &-mobile {
                    @apply hidden;
                }

                &-desktop {
                    @apply flex;
                }
            }
        }

        .categories-wrapper {
            &:deep() {
                & > div {
                    @apply justify-start;
                }
            }
        }

        .terms-wrapper {
            & > div {
                @apply text-left;
            }
        }
    }
}
</style>
