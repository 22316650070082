<template>
    <BaseLink
        class="side-menu-item"
        :path="path"
        :label="name"
        :show-icon-with-label="showIconWithLabel"
    >
        <template v-if="hasValidLogo" #icon>
            <SvgIcon
                :width="logo.width"
                :height="logo.height"
                stroke-color="none"
            >
                <component :is="logo.svg" />
            </SvgIcon>
        </template>
        <div v-if="showBadgeNew" class="label-with-badge">
            {{ name }}

            <Badge v-if="showBadgeNew" :variant="BADGE_NEW">
                {{ $t('New') }}
            </Badge>
        </div>
    </BaseLink>
</template>

<script>
import { isObjectType } from '@assets/types';

import BaseLink from '@atoms/BaseLink/BaseLink';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import { Badge, BADGE_VARIANTS } from '@eobuwie-ui/components/Badge/v1';

export default {
    name: 'SideMenuItem',

    components: {
        BaseLink,
        SvgIcon,
        Badge,
    },

    props: {
        path: {
            type: String,
            required: true,
        },

        name: {
            type: String,
            required: true,
        },

        logo: {
            type: Object,
            default: null,
        },

        showIconWithLabel: {
            type: Boolean,
            default: true,
        },

        showBadgeNew: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        hasValidLogo() {
            const { logo } = this;

            const requiredKeys = ['svg', 'width', 'height'];

            return (
                isObjectType(logo, Object) &&
                requiredKeys.every(key => Object.keys(logo).includes(key))
            );
        },
    },

    beforeCreate() {
        this.BADGE_NEW = BADGE_VARIANTS.NEW;
    },
};
</script>

<style lang="scss" scoped>
.side-menu-item {
    .label-with-badge {
        @apply flex gap-2 items-center flex-wrap;
    }

    @screen lg {
        @apply block w-full py-12p px-3 rounded-4;

        &:hover,
        &:focus {
            &.text-link {
                @apply no-underline;
            }
        }

        &:hover {
            @apply bg-green5;
        }
    }
}
</style>
