export const MEDIA_SIZE = {
    desktop: {
        width: 1440,
        height: 405,
    },
    mobile: {
        width: 640,
        height: 640,
    },
};
