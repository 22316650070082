<template>
    <PageBuilderInterpreter
        :tag="GridColumns"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import GridColumns from '@molecules/GridColumns/GridColumns';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import { COLUMNS_COUNT } from '@types/GridColumns';

const columnsOptions = COLUMNS_COUNT.map(columnsCount => ({
    label: `${columnsCount} kolumny`,
    value: columnsCount,
}));

export default {
    name: 'CmsGridColumns',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(GridColumns),
    },

    data() {
        return {
            GridColumns,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    columnsTablet: 1,
                    columnsDesktop: 1,
                    columnsDesktopLarge: 1,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                columnsTablet: columnsOptions,
                columnsDesktop: columnsOptions,
                columnsDesktopLarge: columnsOptions,
            },
        };
    },
};
</script>
