<script>
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapCustomerActions,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

export default {
    name: 'AddToWishlistWrapper',

    props: {
        product: {
            type: Object,
            required: true,
        },

        chosenSize: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isLoading: false,
        };
    },

    computed: {
        ...mapCustomerGetters(['isOnWishlist']),
    },

    methods: {
        ...mapCustomerActions(['addToWishlist', 'removeFromWishlist']),

        async toggle() {
            const {
                addToWishlist,
                removeFromWishlist,
                isOnWishlist,
                product,
                chosenSize,
            } = this;

            const productWithChosenSize = {
                ...product,
                chosenSize,
                pageType: this.$route.name,
                actionField: this.$route.path,
            };

            this.isLoading = true;

            if (isOnWishlist(product.sku)) {
                await removeFromWishlist(productWithChosenSize);
            } else {
                await addToWishlist(productWithChosenSize);
            }

            this.isLoading = false;
        },
    },

    render() {
        return this.$scopedSlots.default({
            isLoading: this.isLoading,
            isAdded: this.isOnWishlist(this.product.sku),
            toggle: this.toggle,
        });
    },
};
</script>
