<template>
    <MarketingDisplayWithIframe v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingDisplayWithIframe from '@organisms/MarketingDisplayWithIframe/MarketingDisplayWithIframe';
import { THEMES } from '@types/MarketingBanner';

const propsConfigToOverride = {
    iframeSrc: {
        type: String,
        required: false,
        default: '',
    },

    imageUrl450x450: {
        type: String,
        required: false,
        default: '',
    },

    imageUrl767x450: {
        type: String,
        required: false,
        default: '',
    },

    imageUrl1532x1149: {
        type: String,
        required: false,
        default: '',
    },
};

export default {
    name: 'CmsMarketingDisplayWithIframe',

    components: {
        MarketingDisplayWithIframe,
    },

    extends: CmsBaseComponent,

    props: getProps(MarketingDisplayWithIframe, propsConfigToOverride),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    theme: THEMES.THEME_LIGHT,
                    iframeSrc: '',
                    imageUrl450x450: '',
                    imageUrl767x450: '',
                    imageUrl1532x1149: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
            },

            // eslint-disable-next-line max-len
            // eslint-disable-next-line vue/no-unused-properties
            propsCustomFormConfig: {
                iframeSrc: {
                    required: true,
                },

                imageUrl450x450: {
                    required: true,
                },

                imageUrl767x450: {
                    required: true,
                },

                imageUrl1532x1149: {
                    required: true,
                },
            },
        };
    },
};
</script>
