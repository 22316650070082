<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { CMS_TEXT_ALLOWED_HTML_TAGS } from '@modules/page-builder/page-builder.config';
import { clearTags } from '@modules/page-builder/helpers/html';

export default {
    name: 'CmsText',

    extends: CmsBaseComponent,
    props: {
        text: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    text: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
            },
        };
    },

    computed: {
        textClearTags() {
            return clearTags(this.text, CMS_TEXT_ALLOWED_HTML_TAGS);
        },
    },

    render(createElement) {
        return createElement('span', {
            attrs: { class: 'cms-text' },
            domProps: { innerHTML: this.textClearTags },
        });
    },
};
</script>

<style lang="scss" scoped>
.cms-text {
    &:deep() {
        a {
            @apply text-primary;
        }

        p {
            @apply text-text;

            &.cms-bold {
                @apply mt-7 mx-0 mb-4 font-bold;
            }
        }

        li {
            @apply text-s leading-s text-gray1;
        }

        .cms-inner-menu {
            @apply my-4 mx-0;
        }

        .cms-content {
            @apply mt-8;
        }

        ul,
        ol {
            &.cms-inner-list {
                @apply pl-6;
            }

            &.cms-list-section {
                @apply my-4 mx-0;
            }

            &.cms-list-disc {
                list-style-type: disc;
            }

            &.cms-list-circle {
                list-style-type: circle;
            }

            &.cms-list-decimal {
                list-style-type: decimal;
            }

            &.cms-list-upper-roman {
                list-style-type: upper-roman;
            }

            &.cms-list-lower-latin {
                list-style-type: lower-latin;
            }

            &.cms-main-list {
                > li {
                    @apply mt-7;
                }

                &.cms-small-margin {
                    > li {
                        @apply mt-4;
                    }
                }
            }

            li {
                @apply text-text leading-s text-s;
            }
        }
    }

    @screen lg {
        &:deep() {
            .cms-section {
                @apply flex flex-row flex-nowrap border-t-1 border-solid border-border mt-8 pt-8;

                &:first-of-type {
                    @apply mt-0 pt-7 border-t-0;
                }

                .cms-column {
                    @apply w-1/2;

                    &:first-child {
                        @apply pr-3;
                    }

                    &:last-child {
                        @apply pl-3;
                    }

                    &.cms-lower {
                        @apply mt-8;

                        .cms-subtitle-small {
                            @apply pb-0;
                        }
                    }
                }
            }
        }
    }
}
</style>
