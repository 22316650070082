<template>
    <PageBuilderInterpreter :components-json="slotDefault" />
</template>

<script>
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsAppEobMainBannerPromoListing',

    components: {
        PageBuilderInterpreter,
    },

    /* eslint-disable vue/no-unused-properties */
    props: {
        priority: {
            type: String,
            required: true,
        },

        image: {
            type: String,
            required: true,
        },

        tag: {
            type: String,
            required: true,
        },

        listingName: {
            type: String,
            required: true,
        },

        women: {
            type: Boolean,
            default: false,
        },

        men: {
            type: Boolean,
            default: false,
        },

        child: {
            type: Boolean,
            default: false,
        },

        firstText: {
            type: String,
            default: '',
        },

        secondText: {
            type: String,
            default: '',
        },

        badgeText: {
            type: String,
            default: '',
        },

        badgeTextColor: {
            type: String,
            default: '',
        },

        badgeColor: {
            type: String,
            default: '',
        },

        regulationsText: {
            type: String,
            default: '',
        },

        regulationsPdf: {
            type: String,
            default: '',
        },

        regulationsLink: {
            type: String,
            default: '',
        },

        deepLink: {
            type: String,
            default: '',
        },

        deepLinkSku: {
            type: String,
            default: '',
        },

        defaultValue: {
            type: String,
            default: '',
        },

        idSynerise: {
            type: String,
            default: '',
        },

        discountCode: {
            type: String,
            default: '',
        },

        buttonColor: {
            type: String,
            default: '',
        },

        buttonText: {
            type: String,
            default: '',
        },

        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },
    },

    data() {
        return {
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    priority: '',
                    image: '',
                    tag: '',
                    listingName: '',
                    women: false,
                    men: false,
                    child: false,
                    firstText: '',
                    secondText: '',
                    badgeText: '',
                    badgeTextColor: '',
                    badgeColor: '',
                    regulationsText: '',
                    regulationsPdf: '',
                    regulationsLink: '',
                    deepLink: '',
                    deepLinkSku: '',
                    defaultValue: '',
                    idSynerise: '',
                    discountCode: '',
                    buttonColor: '',
                    buttonText: '',
                },
            },

            propsCustomFormConfig: {
                tag: {
                    max: 24,
                },

                listingName: {
                    max: 24,
                },

                firstText: {
                    max: 48,
                },

                secondText: {
                    max: 48,
                },

                regulationsText: {
                    max: 1000,
                },

                regulationsLink: {
                    max: 1000,
                },

                discountCode: {
                    max: 48,
                },

                buttonColor: {
                    max: 100,
                },

                buttonText: {
                    max: 48,
                },
            },
        };
    },
};
</script>
