<template>
    <PageBuilderInterpreter
        :tag="MarketingSliderSection"
        :components-json="slotDefault"
        :class="cssClass"
        :tag-props="propsToBind"
    />
</template>

<script>
import { THEMES } from '@types/MarketingSection';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import MarketingSliderSection from '@organisms/MarketingSliderSection/MarketingSliderSection';

export default {
    name: 'CmsMarketingSliderSection',

    components: {
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingSliderSection),
    },

    data() {
        return {
            MarketingSliderSection,
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    theme: THEMES.LIGHT,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                theme: Object.values(THEMES),
            },
        };
    },
};
</script>
