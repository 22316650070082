<template>
    <div class="help-center-card-list">
        <div class="wrapper">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HelpCenterCardList',
};
</script>

<style lang="scss" scoped>
.help-center-card-list {
    @apply overflow-hidden;

    .wrapper {
        @apply flex flex-wrap -mx-2;

        &::after {
            @apply block flex-auto border-t-1;
            content: '';
        }
    }

    &:deep() .help-center-card {
        @apply w-1/2 py-5 px-2 border-t-1;
    }

    @screen lg {
        &:deep() .help-center-card {
            @apply w-1/3 py-7;
        }
    }
}
</style>
