<template>
    <MarketingBlog v-bind="propsToBind" :class="cssClass" />
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingBlog from '@organisms/MarketingBlog/MarketingBlog';

export default {
    name: 'CmsMarketingBlog',

    components: { MarketingBlog },

    extends: CmsBaseComponent,

    props: getProps(MarketingBlog),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    badge: '',
                    date: '',
                    title: '',
                    text: '',
                    link: '',
                    customCampaignId: '',
                    productSkus: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
