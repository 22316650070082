<template>
    <ContainerContentWithSidebar
        class="help-center-container"
        :is-sidebar-visible-on-mobile="true"
        :are-columns-narrow="true"
    >
        <template #sidebar>
            <div class="message-wrapper message-mobile">
                <slot name="mobileMessage" />
            </div>
            <div class="sidebar">
                <slot name="sidebar" />
            </div>
        </template>
        <template #default>
            <div class="wrapper">
                <div class="message-wrapper message-desktop">
                    <slot name="message" />
                </div>
                <div class="content">
                    <slot />
                </div>
                <CustomerServiceChatDataProvider #default="{ links, cmsBlock }">
                    <CustomerServiceChat
                        class="customer-service"
                        :links="links"
                        :cms-block="cmsBlock"
                    />
                </CustomerServiceChatDataProvider>
            </div>
        </template>
    </ContainerContentWithSidebar>
</template>

<script>
import CustomerServiceChatDataProvider from '@molecules/CustomerServiceChatDataProvider/CustomerServiceChatDataProvider';
import ContainerContentWithSidebar from '@molecules/ContainerContentWithSidebar/ContainerContentWithSidebar';
import CustomerServiceChat from '@organisms/CustomerServiceChat/CustomerServiceChat';

export default {
    name: 'HelpCenterContainer',
    components: {
        CustomerServiceChatDataProvider,
        ContainerContentWithSidebar,
        CustomerServiceChat,
    },
};
</script>

<style lang="scss" scoped>
.help-center-container {
    .message-wrapper {
        margin-left: calc(-1 * theme('spacing.3'));
        margin-right: calc(-1 * theme('spacing.3'));

        &:deep() {
            .base-message {
                @apply rounded-0;
            }
        }
    }

    .message-desktop {
        @apply hidden;
    }

    .sidebar {
        @apply mt-3;
    }

    .content,
    .customer-service {
        @apply mb-7;
    }

    @screen lg {
        .message-wrapper {
            @apply ml-0 mr-0;
        }

        .message-desktop {
            @apply block mt-3;
        }

        .message-mobile {
            @apply hidden;
        }

        .sidebar {
            @apply mb-0;
        }

        .content,
        .customer-service {
            @apply mb-8;
        }

        .wrapper {
            @apply pl-3;
        }
    }

    @screen largeDesktop {
        .wrapper {
            @apply pl-5;
        }
    }

    @screen xl {
        .sidebar {
            @apply pr-3;
        }

        .wrapper {
            @apply pl-56p;
        }
    }
}
</style>
