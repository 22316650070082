<template>
    <div class="cms-block-import">
        <div v-if="!isLoading && blockContent" v-html="blockContent" />
        <SvgIcon
            v-else-if="isLoading && displayBlockLoader"
            class="icon"
            :class="loaderAdditionalClass"
            :width="loader.size"
            :height="loader.size"
        >
            <component :is="loader.icon" />
        </SvgIcon>
    </div>
</template>

<script>
import { BLOCK_LOADER_ICON_MAP } from '@configs/cms-block-import';

import {
    BLOCK_LOADER_SIZES,
    BLOCK_LOADER_SIZE_SMALL,
    BLOCK_LOADER_THEMES,
    BLOCK_LOADER_THEME_LIGHT,
    BLOCK_LOADER_THEME_DARK,
} from '@types/CmsBlockImport';

import { ASYNC_CMS_BLOCKS_SERVICE_CONFIG } from '@async-services/cmsBlocks/meta';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import fetchAvailableBlocks from '@modules/page-builder/helpers/fetchAvailableBlocks';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

const { MODULE_NAME, METHODS } = ASYNC_CMS_BLOCKS_SERVICE_CONFIG;

export default {
    name: 'CmsBlockImport',

    components: {
        SvgIcon,
    },

    extends: CmsBaseComponent,

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        blockId: {
            type: String,
            default: '',
        },

        loaderSize: {
            type: String,
            default: BLOCK_LOADER_SIZE_SMALL,
            validator: value => BLOCK_LOADER_SIZES.includes(value),
        },

        loaderTheme: {
            type: String,
            default: BLOCK_LOADER_THEME_LIGHT,
            validator: value => BLOCK_LOADER_THEMES.includes(value),
        },

        displayBlockLoader: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            blockContent: '',
            isLoading: true,

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                blockId: fetchAvailableBlocks,
                marginTop: false,
                loaderSize: BLOCK_LOADER_SIZES,
                loaderTheme: BLOCK_LOADER_THEMES,
            },
        };
    },

    async fetch() {
        await this.fetchBlock(this.blockId);
    },

    computed: {
        loader() {
            return BLOCK_LOADER_ICON_MAP[this.loaderSize];
        },

        loaderAdditionalClass() {
            return this.loaderTheme === BLOCK_LOADER_THEME_DARK
                ? ['dark']
                : null;
        },
    },

    watch: {
        blockId: {
            async handler(blockIdValue) {
                await this.fetchBlock(blockIdValue);
            },
        },
    },

    methods: {
        // eslint-disable-next-line vue/no-unused-properties
        async fetchBlock(blockId) {
            this.isLoading = true;

            try {
                const block = await this.$asyncServices.use(
                    MODULE_NAME,
                    METHODS.GET_BLOCKS,
                    { ids: [blockId] }
                );

                this.blockContent = block[0]?.content;
            } catch (err) {
                console.log(err);
            }

            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.cms-block-import {
    .icon {
        @apply m-auto text-light;

        &.dark {
            @apply text-dark;
        }
    }
}
</style>
