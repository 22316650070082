<template>
    <MarketingPromoCategories v-bind="propsToBind">
        <template #container1>
            <PageBuilderInterpreter :components-json="slotContainer1" />
        </template>
        <template #container2>
            <PageBuilderInterpreter :components-json="slotContainer2" />
        </template>
        <template #container3>
            <PageBuilderInterpreter :components-json="slotContainer3" />
        </template>
    </MarketingPromoCategories>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import MarketingPromoCategories from '@organisms/MarketingPromoCategories/MarketingPromoCategories';
// eslint-disable-next-line max-len
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsMarketingPromoCategories',

    components: {
        MarketingPromoCategories,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotContainer1: {
            type: String,
            default: JSON.stringify(''),
        },

        slotContainer2: {
            type: String,
            default: JSON.stringify(''),
        },

        slotContainer3: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingPromoCategories),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    tabTitle1: '',
                    tabTitle2: '',
                    tabTitle3: '',
                },

                slots: {
                    container1: '',
                    container2: '',
                    container3: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                staticClass: false,
            },
        };
    },
};
</script>
