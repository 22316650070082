<template>
    <MarketingPromoSlider v-bind="propsToBind" :class="cssClass">
        <PageBuilderInterpreter :components-json="slotDefault" />
    </MarketingPromoSlider>
</template>

<script>
import { THEMES } from '@types/MarketingSection';
import { ICON_TYPES } from '@types/MarketingPromoSlider';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import MarketingPromoSlider from '@organisms/MarketingPromoSlider/MarketingPromoSlider';

export default {
    name: 'CmsMarketingPromoSlider',

    components: {
        MarketingPromoSlider,
        PageBuilderInterpreter,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(MarketingPromoSlider),
    },

    data() {
        return {
            /* eslint-disable vue/no-unused-properties */
            defaultConfig: {
                slots: {
                    default: '',
                },
            },

            options: {
                theme: Object.values(THEMES),
                icon: Object.values(ICON_TYPES),
            },
        };
    },
};
</script>
