<template>
    <div />
</template>

<script>
export default {
    name: 'CmsHelpCenterConfig',

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        contactFormLink: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    contactFormLink: '',
                },
            },
        };
    },
};
</script>
