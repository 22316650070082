import ShoppingCart from '@static/icons/48px/shopping-cart.svg?inline';
import Transport from '@static/icons/48px/transport.svg?inline';
import Payment from '@static/icons/48px/payment-1.svg?inline';
import Return from '@static/icons/48px/return-1.svg?inline';
import Guarantee from '@static/icons/48px/guarantee.svg?inline';
import User from '@static/icons/48px/user.svg?inline';
import Size from '@static/icons/48px/size.svg?inline';
import StationaryShop from '@static/icons/48px/stationary-shop.svg?inline';
import Shoe from '@static/icons/48px/shoe-2.svg?inline';
import Discounts from '@static/icons/48px/cool-price.svg?inline';
import Terms from '@static/icons/48px/terms.svg?inline';

export const ICON_TYPE_SHOPPING_CART = 'shoppingCart';
export const ICON_TYPE_SHIPPING = 'shipping';
export const ICON_TYPE_PAYMENT = 'payment';
export const ICON_TYPE_RETURN = 'return';
export const ICON_TYPE_GUARANTEE = 'guarantee';
export const ICON_TYPE_ACCOUNT = 'account';
export const ICON_TYPE_SIZE = 'size';
export const ICON_TYPE_STATIONARY_SHOP = 'stationaryShop';
export const ICON_TYPE_SHOE = 'shoe';
export const ICON_TYPE_DISCOUNTS = 'discounts';
export const ICON_TYPE_TERMS = 'terms';

export const ICON_TYPES = {
    ICON_TYPE_SHOPPING_CART,
    ICON_TYPE_SHIPPING,
    ICON_TYPE_PAYMENT,
    ICON_TYPE_RETURN,
    ICON_TYPE_GUARANTEE,
    ICON_TYPE_ACCOUNT,
    ICON_TYPE_SIZE,
    ICON_TYPE_STATIONARY_SHOP,
    ICON_TYPE_SHOE,
    ICON_TYPE_DISCOUNTS,
    ICON_TYPE_TERMS,
};

export const ICON_MAP = {
    [ICON_TYPE_SHOPPING_CART]: ShoppingCart,
    [ICON_TYPE_SHIPPING]: Transport,
    [ICON_TYPE_PAYMENT]: Payment,
    [ICON_TYPE_RETURN]: Return,
    [ICON_TYPE_GUARANTEE]: Guarantee,
    [ICON_TYPE_ACCOUNT]: User,
    [ICON_TYPE_SIZE]: Size,
    [ICON_TYPE_STATIONARY_SHOP]: StationaryShop,
    [ICON_TYPE_SHOE]: Shoe,
    [ICON_TYPE_DISCOUNTS]: Discounts,
    [ICON_TYPE_TERMS]: Terms,
};
