// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/icons/20px/up.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../static/icons/20px/down.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-0e743519]  .accordion-section{border-bottom-width:1px;border-style:solid;border-color:#e0e0e0}[data-v-0e743519]  .accordion-section.open .accordion-button:after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat}[data-v-0e743519]  .accordion-section.open .accordion-content{height:auto}[data-v-0e743519]  .accordion-section:last-of-type{border-bottom-width:0}[data-v-0e743519]  .accordion-button{position:relative;cursor:pointer;padding-top:16px;padding-bottom:16px;padding-right:24px;font-weight:700;color:#222}[data-v-0e743519]  .accordion-button:after{position:absolute;right:0;top:20px;height:16px;width:16px;content:\"\";background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat}[data-v-0e743519]  .accordion-content{overflow:hidden;font-size:1.4rem;line-height:2.0rem;color:#2f2f2f;height:0}[data-v-0e743519]  .accordion-content p{margin-bottom:24px;color:#222}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
