<template>
    <div
        v-if="!disabled"
        :style="bgColor"
        :class="theme"
        class="marketing-banner-h-one"
    >
        <ContainerContent>
            <div class="content-wrapper">
                <div class="text-wrapper">
                    <BaseHeading class="heading">{{ heading }}</BaseHeading>
                    <BaseHeading class="sub-heading">
                        {{ subHeading }}
                    </BaseHeading>
                    <div v-if="discountCode" class="discount-code">
                        {{ $t('with the code:') }}
                        <div class="copy-code">
                            <span class="code">{{ discountCode }}</span>
                            <CopyToClipboardButton
                                class="copy-button"
                                :theme="THEME_INITIAL"
                                :value-to-copy="discountCode"
                                :before-copy-label="$t('Copy')"
                                :after-copy-label="$t('Copied')"
                                :icon-position="
                                    ICON_POSITIONS.ICON_POSITION_LEFT
                                "
                                :reset-to-default-state-after="5000"
                            >
                                <template #icon="{ isCopied }">
                                    <SvgIcon class="copy-icon">
                                        <component
                                            :is="isCopied ? 'Check' : 'Copy'"
                                        />
                                    </SvgIcon>
                                </template>
                            </CopyToClipboardButton>
                        </div>
                    </div>
                    <MarketingBannerHOneImageLarge
                        v-if="imageUrlFirst"
                        :target-url="targetUrl"
                        :image-large-url="imageUrlFirst"
                        class="image-mobile"
                    />
                    <ButtonLarge
                        :href="targetUrl"
                        :variant="buttonVariant"
                        class="button-text"
                    >
                        {{ buttonText }}
                    </ButtonLarge>
                    <BaseParagraph class="description">
                        {{ description }}
                    </BaseParagraph>
                </div>
                <div class="image-wrapper">
                    <MarketingBannerHOneImageLarge
                        v-if="imageUrlFirst"
                        :target-url="targetUrl"
                        :image-large-url="imageUrlFirst"
                        class="image-left"
                    />
                    <component
                        :is="imageSecondLinkComponent"
                        v-if="imageUrlSecond"
                        :path="targetUrl"
                        class="image-right"
                    >
                        <BasePicture
                            :src="imageSecond.url"
                            :alt="imageSecond.alt"
                            :width="imageSecond.width"
                            :height="imageSecond.height"
                            :sources="imageSecond.sources"
                            :is-lazy="true"
                        />
                    </component>
                </div>
            </div>

            <div v-show="hasSlider" class="banner-slider">
                <slot />
            </div>
        </ContainerContent>
    </div>
</template>

<script>
import { IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h } from '@types/Image';
import { THEMES as BUTTON_THEME, ICON_POSITIONS } from '@types/Button';
import { THEMES } from '@types/MarketingBannerHOne';

import { checkIfExistsInValuesMap } from '@assets/props';
import { isHttpLink } from '@assets/link';

import Copy from '@static/icons/20px/copy.svg?inline';
import Check from '@static/icons/20px/check.svg?inline';

import { light } from '@theme/resources/variables/colors.json';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BasePicture from '@atoms/BasePicture/BasePicture';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import CopyToClipboardButton from '@molecules/CopyToClipboardButton/CopyToClipboardButton';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketingBannerHOneImageLarge from '@organisms/MarketingBannerHOne/MarketingBannerHOneImageLarge';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingBannerHOne',

    components: {
        Copy,
        Check,
        BaseHeading,
        BaseParagraph,
        BaseLink,
        BasePicture,
        SvgIcon,
        MarketingBannerHOneImageLarge,
        CopyToClipboardButton,
        ContainerContent,
        ButtonLarge,
    },

    props: {
        targetUrl: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        buttonText: {
            type: String,
            required: true,
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES, true),
        },

        subHeading: {
            type: String,
            default: '',
        },

        discountCode: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        imageUrlFirst: {
            type: String,
            default: '',
        },

        imageUrlSecond: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: light,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            hasSlider: false,
        };
    },

    computed: {
        bgColor() {
            return `background-color: ${this.backgroundColor}`;
        },

        buttonVariant() {
            return this.theme === THEMES.THEME_DARK
                ? BUTTON_LARGE_VARIANTS.PRIMARY
                : BUTTON_LARGE_VARIANTS.PRIMARY_ALTERNATIVE;
        },

        imageSecond() {
            return this.$imaginator.getImage(
                this.imageUrlSecond,
                'marketing-banner-h-one-right',
                this.heading,
                IMAGE_TYPE_MARKETING_BANNER_HONE_600w_400h
            );
        },

        imageSecondLinkComponent() {
            return isHttpLink(this.targetUrl) ? BaseLinkStatic : BaseLink;
        },
    },

    mounted() {
        this.hasSlider = !!this.$slots.default?.[0]?.elm?.children || false;
    },

    beforeCreate() {
        this.THEME_INITIAL = BUTTON_THEME.THEME_INITIAL;
        this.ICON_POSITIONS = ICON_POSITIONS;
    },
};
</script>

<style lang="scss" scoped>
$sub-heading-font-size: 32px;
$sub-heading-line-height: 42px;
$sub-heading-font-size-desktop: 42px;
$sub-heading-line-height-desktop: 56px;
$sub-heading-letter-spacing: -0.04em;

$slider-item-width-medium: 229px;
$slider-item-widtg-large: 256px;

$desktop-slider-width-hone: 690px;

.marketing-banner-h-one {
    &:deep() {
        &.container-content {
            @apply px-0 py-3;
        }
    }

    &.light {
        .heading,
        .sub-heading,
        .discount-code,
        .copy-icon,
        .description {
            @apply text-light;
        }

        .code {
            @apply border-light;
        }
    }

    .content-wrapper {
        @apply w-full px-3;
        max-width: theme('customVariables.bannerHOne.contentWrapperMaxWidth');
    }

    .text-wrapper {
        @apply flex flex-col;
    }

    .heading {
        @apply text-ui-24 leading-ui-30 tracking-wide mb-2;
    }

    .sub-heading {
        @apply mb-3 line-clamp-3;

        font-size: $sub-heading-font-size;
        line-height: $sub-heading-line-height;
        letter-spacing: $sub-heading-letter-spacing;
    }

    .discount-code {
        @apply flex flex-col justify-start text-r leading-r;
    }

    .copy-code {
        @apply flex mt-1 mb-3 items-center;
    }

    .code {
        @apply mr-2 px-3 font-bold border-1 border-dashed border-text rounded-4 bg-fog-half;
    }

    .copy-button {
        @apply min-h-l;
    }

    .copy-icon {
        @apply w-20p h-20p;
    }

    .image-mobile {
        @apply mb-3;

        &:deep(.base-picture) {
            .image {
                @apply rounded-4;
            }
        }
    }

    .button-text {
        @apply mb-3;
    }

    .description {
        @apply text-r leading-r;
    }

    .image-wrapper {
        @apply hidden;
    }

    .banner-slider {
        @apply pl-3 pt-3 w-full;

        &:deep(.product) {
            &:first-child {
                @apply ml-0;
            }
        }
    }

    @screen md {
        .content-wrapper {
            @apply flex justify-between;
        }

        .text-wrapper {
            @apply justify-center pr-5;
        }

        .image-mobile {
            @apply hidden;
        }

        .heading {
            @apply mb-12p;
        }

        .sub-heading,
        .discount-code {
            @apply mb-20p;
        }

        .copy-code {
            @apply mb-0;
        }

        .button-text {
            @apply self-start mb-4;
        }

        .image-wrapper {
            @apply block w-3/5;
        }

        .image-left {
            @apply w-full;
            max-width: theme('customVariables.bannerHOne.maxWidth400');

            &:deep(.base-picture) {
                .image {
                    @apply rounded-4;
                }
            }
        }

        .image-right {
            @apply hidden;
        }

        .banner-slider {
            &:deep() {
                .product {
                    max-width: $slider-item-width-medium;
                }

                .simple-slider {
                    &:not(.vertical-navigation) {
                        .navigation-button-next {
                            @apply right-16;
                        }
                    }
                }
            }
        }
    }

    @screen lg {
        &:deep() {
            &.container-content {
                @apply pt-0;
            }
        }

        .content-wrapper {
            @apply px-5 pb-3;
        }

        .text-wrapper {
            @apply mt-5 w-3/8;
        }

        .image-wrapper {
            @apply flex flex-row w-5/8;
        }

        .image-left {
            @apply pt-8 w-2/3 z-1;
        }

        .image-right {
            @apply block w-full -ml-5;
            max-width: theme('customVariables.bannerHOne.maxWidth600');

            &:deep(.base-picture) {
                .image {
                    @apply rounded-b-4;
                }
            }
        }

        .banner-slider {
            @apply pl-5;

            &:deep() {
                .product {
                    max-width: $slider-item-widtg-large;
                }

                .simple-slider {
                    &:not(.vertical-navigation) {
                        .navigation-button-next {
                            @apply right-32;
                        }
                    }
                }
            }
        }
        .heading {
            @apply text-xxl leading-xxl;
        }

        .sub-heading {
            font-size: $sub-heading-font-size-desktop;
            line-height: $sub-heading-line-height-desktop;
        }
    }

    @screen xl {
        .content-wrapper {
            @apply px-0;
        }

        .text-wrapper {
            @apply mt-0 w-auto;
        }

        .image-wrapper {
            @apply w-auto;
        }

        .image-left {
            @apply pt-5 w-full;
            width: theme('customVariables.bannerHOne.maxWidth400');
        }

        .image-right {
            width: theme('customVariables.bannerHOne.maxWidth600');
        }

        .banner-slider {
            @apply pl-0;
        }
    }
}
</style>
