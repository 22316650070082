<template>
    <div />
</template>

<script>
export default {
    name: 'CmsAppEobCategory',

    /* eslint-disable vue/no-unused-properties */
    props: {
        categoryName: {
            type: String,
            required: true,
        },

        deepLinkSku: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            defaultConfig: {
                props: {
                    categoryName: '',
                    deepLinkSku: '',
                },
            },

            propsCustomFormConfig: {
                categoryName: {
                    max: 48,
                },
            },
        };
    },
};
</script>
