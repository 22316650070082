<template>
    <MarketingBarLink v-bind="propsToBind" />
</template>

<script>
import { getProps } from '@modules/page-builder/helpers/component';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';

import MarketingBarLink from '@marketing-bar-components/molecules/MarketingBarLink/MarketingBarLink';

export default {
    name: 'CmsMarketingBarLink',

    components: {
        MarketingBarLink,
    },

    extends: CmsBaseComponent,

    props: {
        ...getProps(MarketingBarLink),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    path: '',
                    label: '',
                    hasIcon: false,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                marginTop: false,
                staticClass: false,
            },
        };
    },
};
</script>
