<template>
    <MarketingBar v-bind="baseProps" class="marketing-bar-discount-code">
        <template #after-text>
            <div class="button-container">
                <button
                    :disabled="isCopied"
                    class="copy-code"
                    type="button"
                    @click="copyCode()"
                >
                    {{ code }}
                    <SvgIcon width="16px" height="16px" class="icon">
                        <component :is="copyIcon" />
                    </SvgIcon>
                </button>
            </div>
        </template>
    </MarketingBar>
</template>

<script>
import { copyToClipboard } from '@assets/clipboard';

import Copy from '@marketing-bar-static/icons/regular/copy.svg?inline';

import Check from '@static/icons/20px/check.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

import MarketingBar from '@marketing-bar-components/molecules/MarketingBar/MarketingBar';

export default {
    name: 'MarketingBarDiscountCode',

    components: {
        MarketingBar,
        SvgIcon,
        Copy,
        Check,
    },

    extends: MarketingBar,

    props: {
        code: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            isCopied: false,
            copiedTimer: false,
        };
    },

    computed: {
        copyIcon() {
            return this.isCopied ? 'Check' : 'Copy';
        },
    },

    beforeDestroy() {
        clearTimeout(this.copiedTimer);
    },

    methods: {
        copyCode() {
            this.isCopied = true;

            copyToClipboard(this.code);

            this.copiedTimer = setTimeout(() => {
                this.isCopied = false;
            }, 1500);
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-bar-discount-code {
    @apply flex flex-wrap gap-x-2;

    .button-container {
        @apply flex justify-center items-center;
    }

    .copy-code {
        @apply px-2 py-0 h-5 flex items-center justify-center gap-2 font-book;
        @apply relative;

        &:hover {
            &:after {
                @apply opacity-100;
            }
        }

        &:after {
            @apply content-[''] block w-full h-full absolute left-0 top-0;
            @apply border-1 border-dashed opacity-20 border-current;

            transition: opacity 250ms ease-out;
        }
    }

    .icon {
        @apply text-inherit;
    }

    @screen mobile-and-tablet-only {
        .button-container {
            @apply w-full;
        }
    }
}
</style>
