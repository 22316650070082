<template>
    <BaseParagraph v-bind="propsToBind" :class="cssClass">
        <PageBuilderInterpreter :components-json="slotDefault" tag="span" />
    </BaseParagraph>
</template>

<script>
import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';
import { SIZES } from '@types/Paragraph';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

export default {
    name: 'CmsBaseParagraph',

    components: {
        PageBuilderInterpreter,
        BaseParagraph,
    },

    extends: CmsBaseComponent,

    props: {
        slotDefault: {
            type: String,
            default: JSON.stringify(''),
        },

        ...getProps(BaseParagraph),
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                slots: {
                    default: '',
                },

                props: {
                    marginTop: 'mt-3',
                    size: SIZES.NORMAL,
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                size: Object.values(SIZES),
            },
        };
    },
};
</script>
